import React from 'react';
import { Image, TouchableOpacity, StyleSheet } from 'react-native';
import ConfirmImageSource from '../assets/verified.png';
import ConfirmImageSourceB from '../assets/verified_b.png';

const ConfirmImage = ({ isActive, onPress, noDisabled=true }) => {
  const imageStyle = isActive ? styles.active : styles.inactive;

  return (
    <TouchableOpacity 
      onPress={onPress}
      disabled = {(isActive && noDisabled) ? true : false}
    >
      <Image
        source={isActive ? ConfirmImageSourceB : ConfirmImageSource}
        style={[styles.image, imageStyle, {marginRight:5}]}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  image: {
    width: 25, // 또는 원하는 크기로 조정
    height: 25, // 또는 원하는 크기로 조정
  },
  active: {
    opacity: 1,
  },
  inactive: {
    opacity: 0.2, // 흐릿하게 보이도록 투명도 조정
  },
});

export default ConfirmImage;