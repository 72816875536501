import React, { useState, useEffect } from 'react';
import { View, Text, Animated, Button } from 'react-native';

const BlinkingText = ({ children, style, textStyle, shouldBlink=true }) => {
  const [fadeAnim] = useState(new Animated.Value(1)); // 초기 투명도 값은 1 (불투명)

  useEffect(() => {
    let intervalId;

    if (shouldBlink) {
        // 깜박이는 효과 시작
        intervalId = setInterval(() => {
          fadeAnim.setValue(fadeAnim._value === 1 ? 0 : 1);
        }, 1000);
    } else if (!shouldBlink && fadeAnim._value !== 1) {
        // 깜박임이 중지되면 글자를 다시 불투명하게 만듭니다.
        fadeAnim.setValue(1);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId); // 컴포넌트 정리 시 이전 인터벌 제거
      }
    };
  }, [shouldBlink, fadeAnim]); // shouldBlink 또는 fadeAnim 상태가 변경될 때 useEffect 다시 실행

  // 깜박임을 중지하는 함수
  const stopBlinking = () => {
    setShouldBlink(false);
  };

  return (
    <View style={[{ flex: 1, alignItems: 'center', justifyContent: 'center' }, style]}>
        <Animated.Text style={[{ 
            fontSize: 16, 
            fontWeight: 'bold', 
            opacity: fadeAnim 
        }, textStyle]}>
            {children}
        </Animated.Text>
    </View>
  );
};

export default BlinkingText;