import React, {useState, useEffect} from 'react';
import { Image, Text, View, StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native';
import ConfirmImage from '../ConfirmImage';
import GSS from '../GlobalStyleSheet2';
import { ProgressButtonNoWidth } from '../ProgressButton';
import CloseHeader from '../CloseHeader'
import { useSystemInfo } from '../AppContext';
import { useUserItem } from '../UserItemContext';
import { DeleteUserItemToDB, GetAccountInfoFromDB, GetAccountofDB } from '../api';
import { Banks } from '../bankContext.js'
import { useModal } from '../ModalContext';

function SettingHostItem ({navigation, route}) {
    const { user_item, item, userAccounts } = route.params;
    const { systemInfo } = useSystemInfo();
    const { ReadUserItem } = useUserItem();
    const [backAccount, setBankAccount] = useState();
    const bankUpdated = route.params?.bankUpdated;
    const { showModalMessage, confirmModal} = useModal();   
    const [isLoading, setIsLoading] = useState(false);
    const [ accountInfo, setAccountInfo] = useState();

    useEffect(() => {
        let isMounted = true; // 컴포넌트가 마운트 상태인지 추적합니다.
        (async () => {
          try {
            const ret = await GetAccountInfoFromDB(user_item.user_id);
            if(ret.success) {
                if( ret.data.bank_account) {
                    const bankInfos = ret.data.bank_account.split('|||');
                    let bankId = +bankInfos[0];
                    
                    const _bankInfo = {
                        name : Banks[bankId].name,
                        bankNumber : bankInfos[1],
                        logoImage : Banks[bankId].logoImage
                    };
                    setBankAccount(_bankInfo);
                    // console.log('id', bankId);
                    // console.log(Banks[bankId].id);
                }
            }
            const ret2 = await GetAccountofDB(user_item.user_item_id);
            if( ret2.success ) {
                setAccountInfo(ret2.data);
            }
          } catch (e) {
            console.error(e);
          }
        })();
        // 컴포넌트 언마운트 시 isMounted를 false로 설정합니다.
        return () => {
          isMounted = false;
        };
    }, [bankUpdated]);     

    const CloseButton = () => {
        navigation.goBack();
    };    

    const _DeleteUserItem = async () => {
        setIsLoading(true);
        const ret = await DeleteUserItemToDB(user_item.user_item_id);
        //console.log(ret.data);
        if( ret.success ) {
            //Alert.alert('파티해산 성공', `${ret.data}일 분이 정산 됩니다`);
            const _message = {
                title : '파티해산 성공',
                body : user_item.free_charge ? '파티가 해산되었습니다' : `${ret.data}일 분이 정산 됩니다`
            }
            showModalMessage(_message);
            await ReadUserItem(user_item.user_id);
            navigation.reset({
                index: 0,
                routes: [{ name: 'Home' }],
            });               
        }
        setIsLoading(false);
        //navigation.navigate('MatchedProc2', { matchedItem, item });       
    }

    const DeleteUserItem = async () => {
        const _confirm = {
            body : '파티해산을 진행 하시겠습니까?',
            afterOK : _DeleteUserItem
        } 
        confirmModal(_confirm);        
    };    

    const formatDate = () => {
        const date = new Date(user_item.create_at);
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // getMonth()는 0부터 시작
        const day = date.getDate();
        return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;        
    };        

    const payDate = () => {
        const date = new Date(user_item.create_at);
        return date.getDate();
    };        

    const payCost = () => {
        if( !accountInfo || ! accountInfo.pay_price ) {
            return null;
        }
        return (accountInfo.pay_price - systemInfo.trade_manager_charge).toLocaleString();
        //const item.monthly_price + systemInfo.trade_charge;
    };

    const goOutDate = () => {
        const date = new Date(user_item.create_at);
        const year = date.getFullYear();
        const month = date.getMonth() + 2;
        const day = date.getDate();
        return `${year}년 ${month.toString().padStart(2, '0')}월 ${day.toString().padStart(2, '0')}일`;        
    };    

    const changeBank = () => {
        const targetAfterRegister = 'SettingHostItem';
        navigation.navigate('ChangeBankAccount', {targetAfterRegister, user_item, item});
    }

    const changeIdPwd = () => {
        navigation.navigate('ChangeOTTAccount', { user_item, item });
    }

    return (
        <View style={GSS.mainContainer}>
            <CloseHeader
                closeAction={CloseButton}
            />
            <View style={[GSS.mainViewBoxNoBorder, {flex:1}]}>
                <Text style={[GSS.mainDefaultTextB, {fontWeight: 'bold', fontSize: 20,}]}>
                    {item.display_name} 파티설정
                </Text>
            </View>
            <View style={[GSS.mainViewBox , {flex:5}]}>
                <View style={SS.confirmView}>
                    <Text style={GSS.mainDefaultText}>파티생성 날짜</Text>
                    <Text style={GSS.mainDefaultTextB}>{formatDate()}</Text>
                </View>
                { user_item.free_charge === 0 && (
                    <React.Fragment>
                        <View style={SS.confirmView}>
                            <Text style={GSS.mainDefaultText}>정산 일자</Text>
                            <Text style={GSS.mainDefaultTextB}>매 월 {payDate()}일</Text>
                        </View>
                        <View style={SS.confirmView}>
                            <Text style={GSS.mainDefaultText}>정산 금액</Text>
                            <Text style={GSS.mainDefaultTextB}>매 월 {payCost()}원</Text>
                        </View>
                    </React.Fragment>
                )}
                <View style={SS.confirmView}>
                    <Text style={GSS.mainDefaultText}>정산 계좌</Text>
                    <Text style={GSS.mainDefaultTextB}>{backAccount?.name} {backAccount?.bankNumber}</Text>
                </View>                
            </View>
            <View style={[{flex:1, alignItems:'center', justifyContent: 'center', backgroundColor:'#291B44', borderRadius:5, marginTop:10, marginBottom:10 }]}>
                <TouchableOpacity onPress={changeIdPwd}>
                    <Text style={{color:'#F7F7F7', fontSize:16}}>{item.display_name} ID/PWD 변경</Text>
                </TouchableOpacity>
            </View>
            <View style={[{flex:1, alignItems:'center', justifyContent: 'center', backgroundColor:'#291B44', borderRadius:5, marginTop:10, marginBottom:10 }]}>
                <TouchableOpacity onPress={changeBank}>
                    <Text style={{color:'#F7F7F7', fontSize:16}}>정산계좌 변경</Text>
                </TouchableOpacity>
            </View>  
            {isLoading && (
                <View style={GSS.activityIndicatorContainer}>
                    <ActivityIndicator size='large' color="#0000ff" />
                </View>
            )}                                    
            <View style={[{flex:1, alignItems:'center', justifyContent: 'center', backgroundColor:'#291B44', borderRadius:5, marginTop:10, marginBottom:10 }]}>
                <TouchableOpacity 
                    disabled={isLoading ? true : false}
                    onPress={DeleteUserItem}
                >
                    <Text style={{color:'#F7F7F7', fontSize:16}}>파티해산</Text>
                </TouchableOpacity>
            </View>                 
        </View>      
    );    
};

const SS = StyleSheet.create({
    container: {
      // 컨테이너를 flex 컨테이너로 설정합니다.
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-end', // 자식 요소들을 컨테이너의 오른쪽으로 정렬합니다.
      alignItems: 'center', // 자식 요소들을 컨테이너의 중앙으로 정렬합니다(수직 방향).
    },

    confirmView: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems : 'center'
    }    
});

export default SettingHostItem;