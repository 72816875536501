import React, {useState, useEffect } from 'react';
import { Image, Text, View, Platform , TextInput, TouchableOpacity, Dimensions } from 'react-native';
import { OTTs} from '../AppContext';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import GSS from '../GlobalStyleSheet2';
import ProgressButton from '../ProgressButton';
import CloseHeader from '../CloseHeader'
import { ChangeOTTAccountToDB } from '../api';
import { useUserItem } from '../UserItemContext';
import * as WebBrowser from 'expo-web-browser';
import LinkImage from '../../assets/share.png';
import { GetOTTByName } from '../AppContext';
import { useModal } from '../ModalContext';
import * as Clipboard from 'expo-clipboard';
import { generateRandomPassword } from '../EmailAccount.js';

function ChangeOTTAccount ({navigation, route}) {
    const { user_item, item, userAccounts } = route.params;
    const [userid, setUserid] = useState('');
    const [password, setPassword] = useState('');
    const [newPwd, setNewPwd] = useState('');    
    // 입력 필드가 채워지면 버튼을 활성화합니다.
    const isButtonDisabled = (userid && password);
    const { ReadUserItem } = useUserItem(); 
    const OTT = GetOTTByName(item.item_name);
    const { confirmModal, showMessage} = useModal();
    
    async function _NextStep()
    {
      try {
        if( userid === user_item.account_userid && newPwd === user_item.account_pwd) {
          showMessage('이전 아이디/비번과 동일합니다');
          return;
        }
        const ret = await ChangeOTTAccountToDB(user_item.user_item_id, userid, newPwd );
        const new_user_item = {...user_item};
        new_user_item.account_userid = userid;
        new_user_item.account_pwd = newPwd;
        await ReadUserItem(new_user_item.user_id);
        navigation.navigate('DisplayUserItem', { user_item : new_user_item, item, userAccounts });
      } catch(e) {
        console.log(e);
      }
    }

    const NextStep= () => {
      const _confirem = {
        afterOK : _NextStep,
        body: `아이디[${userid}] , 비번[${newPwd}]으로 변경하겠습니까?`
      }
      confirmModal(_confirem);      
    }

    const CloseButton = () => {
      navigation.goBack();
    };        

    useEffect(() => {
        const initialize = async () => {
            try {
              setUserid(user_item.account_userid);
              setPassword(user_item.account_pwd);

              setNewPwd(generateRandomPassword());
            } catch (error) {
              console.error(error);
              // 오류 발생 시 사용자에게 알리기 (예: 알림, 토스트 메시지 등)
              alert('오류가 발생했습니다. 다시 시도해주세요.'); // 여기서 'alert'은 간단한 예시입니다. 실제 환경에선 사용자에게 더 나은 피드백을 제공해야 합니다.
            }
        };
        initialize();
    }, []); 

    const NextButton = () => {
        return (
          <ProgressButton
            title={'완료'}
            onPress={ isButtonDisabled ? NextStep : null}
            disabled={isButtonDisabled ? false : true}

          />
        );
    };

    const handleChangeID = (input) => {
      // 금지된 문자들
      const restrictedChars = [' ', '!', '#', '$', '%', '^', '&', '*', '(', ')', '+', '=', '{', '}', '[', ']', '|', '\\', ':', ';', '\'', '"', '<', '>', ',', '?', '/'];
      // 입력된 문자열에 금지된 문자가 있는지 확인
      const isRestricted = restrictedChars.some(char => input.includes(char));    
      // 한글이 포함되어 있는지 확인 (한글 유니코드 범위: ㄱ~ㅎ가-힣)
      const hasKorean = /[ㄱ-ㅎ가-힣]/.test(input);
      // 금지된 문자와 한글이 없을 경우만 상태를 업데이트
      if (!isRestricted && !hasKorean) {
        setUserid(input);
      }
      // // 공백과 특수문자를 제외한 문자만 허용하는 정규식
      // const validRegex = /^[a-zA-Z0-9]*$/;
      // // 입력값이 정규식에 맞는 경우에만 상태를 업데이트
      // if (validRegex.test(input)) {
      //   setUserid(input);
      // }     
    };

    const handleChangePWD = (input) => {
      if (input.includes(' ')) {
        return;
      }
      setNewPwd(input);
    }; 

    const mainViewBoxNoBorder = {
        ...GSS.mainViewBox,
        borderWidth: undefined, // 'borderWidth'를 제외합니다.
        backgroundColor: undefined
    }; 
    
    async function goUrl() {
      if(Platform.OS !== 'web' && user_item.using_myaccount === 1) {
        navigation.navigate('ChangeMyshottPassword', { user_item, item, OTT });
      } else {
        await WebBrowser.openBrowserAsync(OTT.changePwdUrl);
      }
    }  
    
    const copyToClipboard = async (text) => {
      await Clipboard.setStringAsync(text);
      if(Platform.OS === 'web') {
        showMessage(`[${text}]가 클립보드에 복사되었습니다`);
      }
    };  

    const windowWidth = Dimensions.get('window').width;
    return (
        <KeyboardAwareScrollView
            style={{ flex: 1 }}
            resetScrollToCoords={{ x: 0, y: 0 }}  // 필요한 경우 스크롤 위치 조정
            contentContainerStyle={{ flexGrow: 1 }}  // 컨텐츠 스타일 조정
            scrollEnabled={true}  // 필요에 따라 스크롤 비활성화
      >   
        <View style={GSS.mainContainer}>
            <CloseHeader
                closeAction={CloseButton}
            />            
            <View style={[mainViewBoxNoBorder, {flex:1}]}>
                <Text style={[GSS.mainDefaultTextB, {fontWeight: 'bold', fontSize: 18,}]}>{item.display_name} 아이디/비번 변경</Text>
            </View>
            <View style={[GSS.mainViewBox , {flex:1}]}>
                <View>
                  <Text style={[GSS.textEmphasis, {fontSize:16}]}>{item.display_name}에서 비밀번호을 먼저 변경하시고, 변경한 아이디/비번을 저장하세요</Text>   
                </View>
                <View>
                  <View style={{flexDirection:'row'}}>
                      <Text style={GSS.mainInfoText}>{item.display_name} 계정 비밀번호 변경하러 가기</Text>
                      <TouchableOpacity
                          onPress={goUrl}
                      >
                        <Image
                            source={LinkImage}
                            style={{ marginLeft:10, width: 20, height: 20 }}
                        />
                      </TouchableOpacity>                        
                    </View>
                </View>
                { user_item.using_myaccount === 1 && (
                  <View>
                    <Text style={GSS.mainSmallText}>{item.display_name} 비밀번호를 쉽게 변경할 수 있습니다</Text>
                  </View>
                )}
            </View>
            <View style={[GSS.mainViewBox , {flex:1}]}>
                <View style={GSS.textInputContainer}>
                    <Text style={[GSS.textInputTile, {width: windowWidth*0.15,}]}>ID</Text>
                    <TextInput
                        style={GSS.textInputText}
                        placeholder={`${item.display_name} 아이디를 입력`}
                        value={userid}
                        maxLength={60}
                        onChangeText={handleChangeID}
                        editable={user_item.using_myaccount === 1 ? false : true}
                    />
                    <TouchableOpacity onPress={() => copyToClipboard(userid)}>
                      <Text style={[GSS.mainDefaultText, {width: 30, marginRight:5}]}>복사</Text>
                    </TouchableOpacity>
                    
               </View>
               <View style={GSS.textInputContainer}>
                    <Text style={[GSS.textInputTile, {width: windowWidth*0.175}]}>기존 PW</Text>
                    <Text style={[GSS.mainInfoText, {flex:1}]}>   {password}</Text>
                    <TouchableOpacity onPress={() => copyToClipboard(password)}>
                      <Text style={[GSS.mainDefaultText, {width: 30,marginRight:5}]}>복사</Text>
                    </TouchableOpacity>
                </View>               
                <View style={GSS.textInputContainer}>
                    <Text style={[GSS.textInputTile, {width: windowWidth*0.15}]}>새 PW</Text>
                    <TextInput
                        style={GSS.textInputText}
                        placeholder={`${item.display_name} 비밀번호를 입력`}
                        value={newPwd}
                        maxLength={32}
                        onChangeText={handleChangePWD}
                        //secureTextEntry // 비밀번호 필드로 설정합니다.
                    />
                    <TouchableOpacity onPress={() => copyToClipboard(newPwd)}>
                      <Text style={[GSS.mainDefaultText, {width: 30,marginRight:5}]}>복사</Text>
                    </TouchableOpacity>
                </View>                   
            </View>
            <View style={[GSS.mainViewBox, {flex:1, margin: 5, alignItems:'center', justifyContent: 'center'}]}>
              { user_item.item_id !== 1 && (
                <Text style={[GSS.mainDefaultText, {marginBottom:15}]}>변경된 아이디/비번은 자동으로 파티원에게 전달됩니다</Text>
              )}
              <NextButton/>
            </View>   
        </View>      
      </KeyboardAwareScrollView>     
    );
};

export default ChangeOTTAccount;