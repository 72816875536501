import { Platform } from 'react-native';
import { useSystemInfo } from './AppContext';
import Axios from "axios"
import { LoadFromSystem, routerServer } from './Global';

export const useInitProc = () => {
    const { setSystemInfo } = useSystemInfo();

    const initProc = async () => {
        try {
            const newENV = {};
            const sysData = await LoadFromSystem();
            //console.log(' useInitProc : LoadFromSystem() : ', sysData);
            if(sysData && sysData.user_id ) {
                newENV.user_id = sysData.user_id;
                newENV.login_type = sysData.login_type;
                newENV.last_login_type =sysData.last_login_type;
                // newENV.kakao_id = sysData.kakao_id;
                // newENV.naver_id = sysData.naver_id;
                newENV.message_ymd = sysData.message_ymd;
                newENV.t_item = sysData.t_item;
                newENV.item_check_time = sysData.item_check_time;
            }
            //console.log('useInitProc : LoadFromSystem() : ', sysData);
            //console.log(newENV);

            let newSystemInfo;
            const response = await Axios.get(`${routerServer}/system_info`, {
                headers: {
                    "content-type": "application/json",
                },
            });
    
            if (response.status === 200) {
                setSystemInfo(response.data);
                setSystemInfo(prevState => ({ ...prevState, ...response.data }));
                newSystemInfo = response.data;
            } else {
                throw new Error('useInitProc: Fetching system_info failed');
            }
    
            //console.log('newSystemInfo : ', newSystemInfo);
            if( sysData && sysData.message_ymd)
            {
                //  console.log(sysData.message_ymd);
                //  console.log(newSystemInfo.message_ymd);
                if( new Date(sysData.message_ymd) < new Date(newSystemInfo.message_ymd)) {
                    newENV.check_system_message = true;
                }
            } else {
                newENV.check_system_message = true;
            }
            //console.log('newSystemInfo.item_last_update : ', newSystemInfo.item_last_update);
            let checking = false;
            if( sysData && sysData.item_check_time ) {
                //console.log('newSystemInfo.item_last_update : ', newSystemInfo.item_last_update);
                if(newSystemInfo.item_last_update) {
                    const currentLastDate = new Date(sysData.item_check_time);
                    const item_last_update = new Date(newSystemInfo.item_last_update);
                    if( item_last_update > currentLastDate)
                    {
                        checking = true;
                    }
                } 
                // else {
                //     console.log(ENV.item_check_time);
                // }
            } else {
                checking = true;
            }
            //if (checking) {
            if (true) {
                let items;
                const response = await Axios.get(`${routerServer}/get_items`, {
                    headers: {
                        "content-type": "application/json",
                    },
                });
                if (response.status === 200) {
                    items =  response.data;
                } else {
                    throw new Error('Fetching get_items failed');
                }
                if( items) {
                    //console.log(items);
                    newENV.t_item = [...items];
                    newENV.item_check_time = newSystemInfo.item_last_update;
                } else {
                    console.warn('initProc.fetchAllItems error : ');
                }
            }
            return newENV;
          } catch(e) {
            console.log('initProc error', e);
          }        
    }

    return { initProc };
};