import logoIBK from '../assets/bank/icon-ibk.png'
import logoKB from '../assets/bank/icon-kb.png'
import logoKDB from '../assets/bank/icon-kdb.png'
import logoKeb from '../assets/bank/icon-keb.png'
import logoNH from '../assets/bank/icon-nh.png'
import logoSC from '../assets/bank/icon-sc.png'
import logoBNK from '../assets/bank/icon-bnk.png'
import logoGJ from '../assets/bank/icon-g-j.png'
import logoDG from '../assets/bank/icon-daegu.png'
import logSangHo from '../assets/bank/icon-sangho.png'
import logoSamaeul from '../assets/bank/icon-samaeul.png'
import logoSuhyup from '../assets/bank/icon-suhyup.png'
import logoShinhan from '../assets/bank/icon-shinhan.png'
import logoShinhyup from '../assets/bank/icon-shinhyup.png'
import logoCITI from '../assets/bank/icon-citi.png'
import logoWori from '../assets/bank/icon-woori.png'
import logoPOST from '../assets/bank/icon-post.png'
import logoKakao from '../assets/bank/icon-kakao.png'
import logoKbank from '../assets/bank/icon-k.png'
import logoToss from '../assets/bank/icon-toss.png'

// 은행 목록
export const Banks = [
    {
        id : 0,
        name : 'IBK기업',
        logoImage : logoIBK,
        code : '003'
    },
    {
        id : 1,
        name : 'KB국민',
        logoImage : logoKB,
        code : '004'
    },
    {
        id : 2,
        name : 'KDB산업',
        logoImage : logoKDB,
        code : '002'
    },
    {
        id : 3,
        name : 'KEB하나',
        logoImage : logoKeb,
        code : '081'
    },
    {
        id : 4,
        name : 'NH농협',
        logoImage : logoNH,
        code : '011'
    },
    {
        id : 5,
        name : 'SC제일',
        logoImage : logoSC,
        code : '023'
    },
    {
        id : 6,
        name : '경남',
        logoImage : logoBNK,
        code : '039'
    },
    {
        id : 7,
        name : '광주',
        logoImage : logoGJ,
        code : '034'
    },
    {
        id : 8,
        name : '대구',
        logoImage : logoDG,
        code : '031'
    },
    {
        id : 9,
        name : '부산',
        logoImage : logoBNK,
        code : '032'
    },
    {
        id : 10,
        name : '상호저축',
        logoImage : logSangHo,
        code : '050'
    },
    {
        id : 11,
        name : '새마을',
        logoImage : logoSamaeul,
        code : '045'
    },
    {
        id : 12,
        name : '수협',
        logoImage : logoSuhyup,
        code : '007'
    },
    {
        id : 13,
        name : '신한',
        logoImage : logoShinhan,
        code : '088'
    },
    {
        id : 14,
        name : '신협',
        logoImage : logoShinhyup,
        code : '048'
    },
    {
        id : 15,
        name : '씨티',
        logoImage : logoCITI,
        code : '027'
    },
    {
        id : 16,
        name : '우리',
        logoImage : logoWori,
        code : '020'
    },
    {
        id : 17,
        name : '우체국',
        logoImage : logoPOST,
        code : '071'
    },
    {
        id : 18,
        name : '전북',
        logoImage : logoGJ,
        code : '037'
    },
    {
        id : 19,
        name : '제주',
        logoImage : logoShinhan,
        code : '035'
    },
    {
        id : 20,
        name : '카카오뱅크',
        logoImage : logoKakao,
        code : '090'
    },
    {
        id : 21,
        name : '케이뱅크',
        logoImage : logoKbank,
        code : '089'
    },
    {
        id : 22,
        name : '토스뱅크',
        logoImage : logoToss,
        code : '092'
    }
];
