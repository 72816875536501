import React, {useState, useEffect} from 'react';
import { Text, View, ActivityIndicator } from 'react-native';
import { useAuth, useEnv, OTTs} from '../AppContext';
import { useItemContext } from '../ItemContext';
import GSS from '../GlobalStyleSheet2';
import { useUserItem} from '../UserItemContext'
import { SaveWaitingToDB } from '../api';
import { useFocusEffect } from '@react-navigation/native';
import { useModal } from '../ModalContext';

function Share2 ({navigation, route}) {
    const { isLoggedIn  } = useAuth();
    const { ENV } = useEnv();
    const {Item, SaveWaiting} = useItemContext();
    const {ReadUserItem} = useUserItem();
    const { showModalMessage } = useModal();
    let cardName = route.params?.cardName;
    let cardNum = route.params?.cardNum;    

    async function NextStep()
    {
        //console.log('Save waiting item to DB');
        const newItem = {};
        newItem.user_id = Item.user_id;
        newItem.item_id = Item.item_id;
        let isNewNetflix = false;
        if(Item.item_id === 1 && Item.check_new_using === true) {
            newItem.using_type = 1;
            isNewNetflix = true;
        }
        //console.log(newItem);
        //return;
        const ret = await SaveWaitingToDB(newItem);
        if( ret.success) {
            //console.log(ret.data);
            const ret2 = await ReadUserItem(newItem.user_id);
          if( ret.data === true ) {   // 매칭까지 성공한 경우
                navigation.reset({
                    index: 0,
                    routes: [{ name: 'ShareEnd', params: { match_completed: true, isNewNetflix : isNewNetflix }  }],
                  });  
            } else {
                navigation.reset({
                    index: 0,
                    routes: [{ name: 'ShareEnd' }],
                });  
            }
        } 
        else {
            //console.log(ret.error);
            const _message = {
                body : `매칭 대기에 오류가 발생하였습니다. 관리자에게 문의해 주세요[${ret.error}]`
            }
            showModalMessage(_message);
            navigation.reset({
                index: 0,
                routes: [{ name: 'ShareHome' }],
            });              
        }
    }

    useFocusEffect (
        React.useCallback(() => {
          // 포커스될 때 실행할 코드
          //console.log('스크린이 포커싱되었습니다.');
          if(cardName && cardNum) {
            //console.log(user_id, last_login_type, ENV.target_after_login);
            (async () => {
                  try {
                    await NextStep();
                  } catch (e) {
                      console.error(e);
                  }
              })();          
          } else {
            const _message = {
                body : '먼저 결제 정보를 등록해 주세요'
            };
            showModalMessage(_message);
            navigation.navigate('Share1');
          }       
          return () => {
            // 스크린이 포커스를 잃을 때 실행할 정리(clean-up) 코드
            //console.log('스크린이 포커스를 잃었습니다.');
            if(cardName) {
                cardName = null;
                cardNum = null;
            }
          };
        }, [])
    );   

    // useEffect(() => {
    //     console.log('Share2 : ', Item);
    // }, []);

    return (
        <View style={GSS.mainContainer}>
            <View style={{alignItems: 'center', justifyContent:'center'}}>
                <Text style={GSS.mainDefaultText}>매칭 중입니다...</Text>
                <View style={[GSS.activityIndicatorContainer, {marginTop:30}]}>
                    <ActivityIndicator size='large' color="#0000ff" />
                </View>                    
            </View> 
        </View>
    );
};

export default Share2;