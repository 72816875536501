import React, { useEffect } from 'react';
import { SafeAreaView, Platform} from "react-native";
import { WebView } from 'react-native-webview';
import Axios from 'axios';
import { showToast } from './Global';
import { useEnv } from './AppContext';
import { routerServer } from './Global';
import GSS from './GlobalStyleSheet2.js'
import { useLoginProc } from './LoginProc2';
import { getTargetAfterLogin } from './Global';

// const runFirst = `window.ReactNativeWebView.postMessage("this is message from web");`;

export default function LoginKakao({navigation, route}) {
    const {ENV} = useEnv();
    const { loginProc } = useLoginProc();

    const newENV = {
        user_id : null,
        last_login_type : null
    }

    const rcvLast = (access_token) => {
        //console.log('Called rcvLast');
        //console.log(access_token);
        Axios.get(`${routerServer}/kakao/kakao_profile/${access_token}`, { params: {} })
          .then(({ data }) => {
            if ( data.user_id) {
                newENV.user_id = data.user_id;
                newENV.last_login_type = data.last_login_type;               
            } else {
                showToast('마이샷 정보를 확인 할 수 없습니다');
                return Promise.resolve();
            }
            navigateAfterLogin();
          })
          .catch(handleError);
    };
    
    onMessage = (event) => {
        const uri = event.nativeEvent.url;
        //console.log(uri);
        if(uri.startsWith(routerServer))
        {
            // 서버로부터의 응답 데이터 처리
            const access_token = event.nativeEvent.data;
            //console.log(access_token);
            rcvLast(access_token);  
        }        
    }
    
    const navigateAfterLogin = async () => {
        //console.log( newENV.user_id);
        if( newENV.user_id) {
            await loginProc(newENV);
        } 
        navigation.navigate(getTargetAfterLogin(), {login_before : true});   
    };
    
    const handleError = (error) => {
        console.log(error);
        showToast('오류가 발생하였습니다');
        navigateAfterLogin();
    };
    
    return (
        <SafeAreaView style={GSS.droidSafeArea}>
          <WebView 
            originWhitelist={['*']}
            scalesPageToFit={false}
            source={{
              //uri : 'https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=90d60a0115ad5aa6b005532d9f7ea2ae&redirect_uri=http://myshott.chyh.kr:7777/kakao_redirect'
              uri: `${routerServer}/kakao/kakao_login`, webSecurityEnabled: false 
            }}
            // injectedJavaScript={runFirst}
            javaScriptEnabled={true}
            onNavigationStateChange={this.onNavigationStateChange} // 네비게이션 상태 변경 이벤트 핸들러 설정
            onMessage={this.onMessage}
            // onMessage={event => {
            //     const uri = event.nativeEvent.url;
            //     //console.log(uri);
            //     if(uri.startsWith(routerServer))
            //     {
            //       rcvLast(uri);  
            //     }
            //   }
            // } 
          />
        </SafeAreaView>
    );    
}