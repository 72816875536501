import React, { useState, useEffect } from 'react';
import { Alert } from 'react-native';
import { ReadItemFromDB, SaveItemToDB, SaveWaitingToDB } from './api';
import { showToast } from './Global';
import { useEnv } from './AppContext';
const ItemContext = React.createContext();

export const ItemContextProvider = ({ children }) => {
    const [Item, setItem] = useState({
        item_name : null,
        display_name : null,
        item_id : null,
        user_id : null,
        maximum_usage : null,
        remaining_usage: null,
        maximum_usage2 : null,
        remaining_usage2: null,        
        nick_name : null,
        user_accounts : null,
        check_manager : false,
        monthly_price : null,
        monthly_price2 : null,
        payment_price : null,   // 수수료가 포함된 결제 금액
        payment_success : null,
        ott_id : null,          // OTTs 의 인덱스 값
        account_userid : null,
        account_pwd : null,
        check_new_using : null,
        free_charge : false,
    });

    //상태가 변경될 때마다 실행되는 useEffect
    // useEffect(() => {
    //     console.log(Item); // 상태 업데이트 후의 최신 상태를 출력
    // }, [Item]); // 의존성 배열에 Item을 명시하여, Item이 변경될 때만 이 효과를 실행    

    // const InitItem = async (new_item_name) => {
    //     try {
    //         const result = await ReadItemFromDB(new_item_name);
    //         if (result.success) {
    //             const newData = { ...Item }; // 먼저 Item을 펼칩니다.
    //             // 그런 다음 명시적으로 값을 할당합니다.
    //             newData.item_id = result.data.item_id;
    //             newData.monthly_price = result.data.monthly_price;
    //             setItem(newData);
    //             return result.data; // 새로운 데이터를 반환합니다.
    //         } else {
    //             Alert.alert("Error", `Error reading item: ${result.error}`);
    //             throw new Error(`Error reading item: ${result.error}`);
    //         }
    //     } catch (error) {
    //         console.error('아이템 정보 초기화 오류 : ', error);
    //         throw error; // 에러를 다시 던져서 호출하는 쪽에서 잡을 수 있게 합니다.
    //      }
    // };

    const { getItemByName } = useEnv();
    const InitItem = async (item_name) => {
        try {
            const itemInfo = getItemByName(item_name);
            //console.log(itemInfo);
            if( itemInfo) {
                const newData = { ...Item };
                newData.item_id = itemInfo.item_id;
                newData.monthly_price = itemInfo.monthly_price;
                newData.monthly_price2 = itemInfo.monthly_price2;
                setItem(newData);
                return newData // 새로운 데이터를 반환합니다.
            } else {
                const result = await ReadItemFromDB(item_name);
                if (result.success) {
                    const newData = { ...Item }; // 먼저 Item을 펼칩니다.
                    // 그런 다음 명시적으로 값을 할당합니다.
                    newData.item_id = result.data.item_id;
                    newData.monthly_price = result.data.monthly_price;
                    newData.monthly_price2 = result.data.monthly_price2;
                    setItem(newData);
                    return result.data; // 새로운 데이터를 반환합니다.
                } else {
                    Alert.alert("Error", `Error reading item: ENV not defined`);
                    throw new Error(`Error reading item: ENV not defined`);
                }
            }
        } catch (error) {
            console.error('아이템 정보 초기화 오류 : ', error);
            throw error; // 에러를 다시 던져서 호출하는 쪽에서 잡을 수 있게 합니다.
        }
    };    

    const SaveItem = async (newItem) => {
        if(newItem.item_id == null) {
            showToast('SaveItem.저장할 아이템이 지정되지 않았습니다');
            return null; // 비동기 함수이므로 false를 반환하면, 이는 Promise로 감싸져서 반환됩니다.
        }
        setItem(newItem);
        try {
            const result = await SaveItemToDB(newItem); // await를 사용하여 비동기적으로 데이터를 저장합니다.
            //console.log(result);
            if(result.success) {
                return result;
            } else {
                Alert.alert("SaveItem.저장오류", `관리자에게 문의 해주세요.(${result.error})`);
            }
        } catch (error) {
            // 여기에서 에러 로깅을 할 수 있습니다.
            console.error('SaveItem 에러:', error);
        }
        return null;
    }

    const SaveWaiting = async (newItem) => {
        if(newItem.item_id == null) {
            showToast('SaveWaiting.저장할 아이템이 지정되지 않았습니다');
            return null; // 비동기 함수이므로 false를 반환하면, 이는 Promise로 감싸져서 반환됩니다.
        }
        try {
            const result = await SaveWaitingToDB(newItem); // await를 사용하여 비동기적으로 데이터를 저장합니다.
            //console.log(result);
            if(result.success) {
            
                ret = true; // 저장 성공
                return result.data;
            } else {
                Alert.alert("SaveWaiting.저장오류", `관리자에게 문의 해주세요.(${result.error})`);
            }
        } catch (error) {
            // 여기에서 에러 로깅을 할 수 있습니다.
            console.error('SaveWaiting 에러:', error);
        }
        return null;
    }    

    const ResetItem = () => {
        // 새로운 객체를 생성하여 초기 상태를 설정합니다.
        const newData = {
            item_name : null,
            display_name : null,
            item_id : null,
            user_id : null,
            maximum_usage : 0,
            remaining_usage: 0,
            maximum_usage2: 0,
            remaining_usage2: 0,            
            nick_name : null,
            user_accounts : null,
            check_manager : false,
            monthly_price : null,
            monthly_price2 : null,
            payment_price : null,
            payment_success : null,
            ott_id : null,
            account_userid : null,
            account_pwd : null,
            check_new_using : null,
            free_charge : false,
        };
        setItem(newData); 
    };

    return (
        <ItemContext.Provider value={{ Item, setItem, InitItem, SaveItem, ResetItem, SaveWaiting }}>
         {children}
        </ItemContext.Provider>
    );
};

export function useItemContext() {
    return React.useContext(ItemContext);
}