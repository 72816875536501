import React, {useState} from 'react';
import { Image, Text, View, StyleSheet, Dimensions } from 'react-native';
import ConfirmImage from '../ConfirmImage';
import GSS from '../GlobalStyleSheet2';
import { ProgressNext } from '../ProgressButton';
import CloseHeader from '../CloseHeader'

function MatchedProc1 ({navigation, route}) {
    const { matchedItem, item } = route.params;
    // 각 문자열의 확인 상태를 추적하는 state
    const [confirmStatus, setConfirmStatus] = useState([false, false, false]);
    // 모든 문자열이 확인되었는지 확인
    const allConfirmed = confirmStatus.every(status => status === true);   
  // 문자열을 확인하는 함수
    const confirmText = (index) => {
        const newConfirmStatus = [...confirmStatus];
        newConfirmStatus[index] = true;
        setConfirmStatus(newConfirmStatus);
    };

    const CloseButton = () => {
        navigation.goBack();
    };    

    const NextStep = () => {
        //console.log(matchedItem.user_nick_names);
        navigation.navigate('MatchedProc2', { matchedItem, item });
    };    

    const NextButton = () => {
        return (
          <ProgressNext
            title={'다음'}
            onPress={ allConfirmed ? NextStep : null}
            disabled={!allConfirmed}
          />
        );
    };    

    const windowWidth = Dimensions.get('window').width * 0.8;
    return (
        <View style={GSS.mainContainer}>
            <CloseHeader
                closeAction={CloseButton}
            />
            <View style={[GSS.mainViewBoxNoBorder, {flex:1}]}>
                <Text style={[GSS.mainDefaultTextB, {fontWeight: 'bold', fontSize: 20,}]}>
                    {item.display_name} 파티를 이용하기 전 다음 사항을 확인해 주세요
                </Text>
            </View>
            <View style={[GSS.mainViewBox , {flex:4}]}>
                <View style={SS.confirmView}>
                   <Text style={[GSS.mainInfoText, {width:windowWidth}]}>
                            {item.display_name} 계정에 성인인증이 필요시, 파티장에게 <Text style={GSS.textEmphasis}>성인인증을 요청</Text>해 주세요.
                            본인의 휴대폰번호로 성인인증하면 계정의 개인정보가 변경됩니다.
                        </Text>
                    <ConfirmImage
                        isActive={confirmStatus[0]}
                        onPress={() => confirmText(0)}
                    />                        
                </View>
                <View style={SS.confirmView}>
                    <Text style={[GSS.mainInfoText, {width:windowWidth}]}>
                        한명이 여러개의 기기에서 <Text style={GSS.textEmphasisAlert}>동시접속하시면 안됩니다.</Text>
                    </Text>
                    <ConfirmImage
                        isActive={confirmStatus[1]}
                        onPress={() => confirmText(1)}
                    />                        
                </View>
                <View style={SS.confirmView}>
                    <Text style={[GSS.mainInfoText, {width:windowWidth}]}>
                            남의 프로필에 접속하거나, 계정의 설정을 바꾸시면 안됩니다.
                        매너 있는 즐거운 파티생활을 부탁드립니다.
                        </Text>
                    <ConfirmImage
                        isActive={confirmStatus[2]}
                        onPress={() => confirmText(2)}
                    />                        
                </View>                                
            </View>
            <View style={[GSS.mainViewBox, {flex:1, margin: 5, alignItems:'center', justifyContent: 'center'}]}>
                <NextButton/>
            </View>   
        </View>      
    );    
};

const SS = StyleSheet.create({
    container: {
      // 컨테이너를 flex 컨테이너로 설정합니다.
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-end', // 자식 요소들을 컨테이너의 오른쪽으로 정렬합니다.
      alignItems: 'center', // 자식 요소들을 컨테이너의 중앙으로 정렬합니다(수직 방향).
    },

    confirmView: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems : 'center'
    }    
});

export default MatchedProc1;