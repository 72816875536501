import { View, Image, StyleSheet, Pressable, Text  } from 'react-native';
import closeImage from '../assets/close.png'

const TopHeader = ({ children, closeAction, style }) => {
    return (
        <View style={[SS.container, style]}>
            {/* 왼쪽 공간 */}
            { children && (
                <View style={{ flex: 1 }}>
                    <View style={SS.title}>{children}</View>
                </View>
            )}
            {/* 닫기 버튼 */}
            <Pressable activeOpacity={0.5} onPress={closeAction} style={{ position: 'absolute', right: 10 }}>
                <Image
                    source={closeImage}
                    style={{ width: 20, height: 20 }}
                />
            </Pressable>
        </View>
    );
};

const SS = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center', // 중앙 정렬
        alignItems: 'center',
        padding : 10
    },
    title: {
        // 타이틀 스타일 (가운데 정렬을 위한 설정)
        justifyContent: 'center',
        alignItems: 'center',
    },
    // 나머지 스타일...
});

export default TopHeader;
