import React, {useState, useEffect, useCallback } from 'react';
import { 
  Text, View, Image, TextInput, StyleSheet,ScrollView, Alert
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import GSS from '../GlobalStyleSheet2';
import { ProgressNext } from '../ProgressButton';
import CloseHeader from '../CloseHeader';
import { SendUserMessageToDB } from '../api';
import { useModal } from '../ModalContext';

function SendUserMessage ({navigation, route}) {
  const { used_item } = route.params;
  const [message, setMessage] = useState('');
  const { showModalMessage } = useModal();

  async function NextStep()
  {
    try {
      const ret = await SendUserMessageToDB(used_item, message);
      if( ret.success ) {
        //Alert.alert('쪽지발송 성공', '파티장에게 쪽지가 발송 되었습니다습니다');
        const _message = {
          title : '쪽지발송 성공',
          body : '파티장에게 쪽지가 발송 되었습니다.',
        };
        showModalMessage(_message);   
        navigation.goBack();
      } else {
        const _message = {
          title : '쪽지발송 실패',
          body : ret.error
        };
        showModalMessage(_message);   
      }
    } catch (e) {
      console.log('SendUserMessage.SendUserMessageToDB', e);
      const _message = {
        title : '쪽지발송 실패',
        body : e.message,
      };
      showModalMessage(_message);
    }
  }

  const NextButton = () => {
    return (
      <ProgressNext
        title={'쪽지 보내기'}
        onPress={ message.length>5 ? NextStep : null}
        disabled={message.length>5 ? false : true}
      />
    );
  }  

  const CloseButton = () => {
    navigation.goBack();
  };    

  return (
    <KeyboardAwareScrollView
      style={{ flex: 1 }}
      resetScrollToCoords={{ x: 0, y: 0 }}  // 필요한 경우 스크롤 위치 조정
      contentContainerStyle={{ flexGrow: 1 }}  // 컨텐츠 스타일 조정
      scrollEnabled={true}  // 필요에 따라 스크롤 비활성화
    >
        <View style={GSS.mainContainer}>
          <View>
            <CloseHeader
                closeAction={CloseButton}
              >
                <Text style={GSS.mainDefaultText}>마이샷 쪽지 발송</Text>
              </CloseHeader>
            <View style={[GSS.mainViewBox, {flex:1}]}>
              <Text style={GSS.mainInfoTextB}>파티장에게 보낼 쪽지를 작성해 주세요</Text>
            </View>
          </View>
          <View style={{flex:1}}>
            <View style={[GSS.mainViewBox, {flex:1}]}>
              <View style={SS.container}>
                <ScrollView style={SS.scrollView}>
                  <TextInput
                    style={SS.input}
                    multiline={true}
                    maxLength={500}
                    placeholder="메시지를 입력하세요..."
                    onChangeText={(text) => setMessage(text)}
                    value={message}
                  />
                </ScrollView>
              </View>        
            </View>
            <View style={[GSS.mainViewBox, {flex:1, margin: 5, alignItems:'center', justifyContent: 'center'}]}>
              <NextButton/>
            </View> 
          </View>
        </View>  
    </KeyboardAwareScrollView>
  );
};

const SS = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
        backgroundColor: '#fff',
      },
      scrollView: {
        flex: 1,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 4,
      },
      input: {
        padding: 10,
      },
  });

export default SendUserMessage;