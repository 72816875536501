import React, {useState, useEffect} from 'react';
import { View, Platform, BackHandler, ActivityIndicator } from 'react-native';
import { useIsFocused, useFocusEffect, useNavigationState } from '@react-navigation/native';
import { useAuth, useEnv } from '../AppContext';
import MyInfoHomeOfLogin from './MyInfoHomeOfLogin';
import GSS from '../GlobalStyleSheet2';
import LoginWeb from '../LoginWeb';
import Login from '../Login';
import { setTargetAfterLogin } from '../Global';

function MyInfoHome ({navigation, route}) {
  const { isLoggedIn, logout } = useAuth();
  const { ENV, setEnv } = useEnv();
  const isFocused = useIsFocused();
  const tabState = useNavigationState(state => state);

  if(Platform.OS !== 'web') {
    useFocusEffect(
      React.useCallback(() => {
          const onBackPress = () => {
              navigation.reset({
                  index: 0,
                  routes: [{ name: 'home' }],
              });            
              return true;
          };
          BackHandler.addEventListener('hardwareBackPress', onBackPress);
          return () => {
              BackHandler.removeEventListener('hardwareBackPress', onBackPress);
          };
      }, [])
    );       
  }

  // useFocusEffect(
  //   React.useCallback(() => {
  //     console.log('활성화 됨');
  //     return () => {
  //       // 탭이 포커스를 잃을 때 수행할 동작 (옵션)
  //     };
  //   }, [])
  // );  

  // useEffect(() => {
  //   const focusListener = navigation.addListener('focus', () => {
  //     console.log('활성화 됨 0');
  //   });

  //   return focusListener;
  // }, [navigation]);  

  if(Platform.OS !== 'web') {
    useFocusEffect(
      React.useCallback(() => {
      if(ENV.user_id === null) {
        LoginStep();
      }
      }, [ENV.user_id])
    );   
  }
      
  function LoginStep()
  {
      setTargetAfterLogin('MyInfoHome');
      navigation.navigate('Login');
  }  

  const DisplayContent = () => {
    if(isLoggedIn) {
      return (
       <MyInfoHomeOfLogin
        navigation = {navigation}
        route = {route}
       />
      );
    } else {
      return (
        <LoginWeb
          navigation = {navigation}
          route = {route}
          target_after_login2 = {'MyInfoHome'}
        />
      );
    }    
  }   

  if( Platform.OS === 'web' ) {
    return (
      <View style={{flex:1}}>
        <DisplayContent/>
      </View>    
    );
  } else {
    return (
      <View style={{flex: 1}}>
        {isLoggedIn && (
          <MyInfoHomeOfLogin navigation={navigation} route={route} />
        )}
      </View>
    ); 
  }
};

export default MyInfoHome;