import React, {useState, useEffect, useRef} from 'react';
import { Image, Text, View, StyleSheet, Dimensions, TouchableOpacity, ActivityIndicator, Button } from 'react-native';
import GSS from '../GlobalStyleSheet2';
import CloseHeader from '../CloseHeader';
import { useModal } from '../ModalContext';
import {  ChangeOTTAccountToDB} from '../api';
import { useUserItem } from '../UserItemContext';
import { generateRandomPassword } from '../EmailAccount.js';

import * as Clipboard from 'expo-clipboard';
import { WebView } from 'react-native-webview';

function ChangeMyshottPassword ({navigation, route}) {
    const { user_item, item, OTT, userAccounts } = route.params;
    const { confirmModal, showMessage, waitMessage, closeModal } = useModal();
    const [isLoading, setIsLoading] = useState(false);
    const [ pwd, setPwd] = useState();
    const [ curStep, SetCurStep] = useState(0);

    const [ source , setSource] = useState({ uri: OTT.loginUrl, webSecurityEnabled: false });
    const [currentUrl, setCurrentUrl] = useState(null); // 현재 URL 상태
    const webviewRef = useRef(null);
    const [canGoBack, setCanGoBack] = useState(false);
    const [canGoForward, setCanGoForward] = useState(false);
    const [isActive, setIsActive] = useState(true);   
    const [ stepButtonTitle, setStepButtonTitle] = useState('...');
    const { ReadUserItem } = useUserItem(); 
    const [ stepTitle, SetStepTitle] = useState();
    const [ loginCompleted, setLoginCompleted] = useState(false);

    const _STEP = [
        {   // 0
            step_title : `${OTT.name}에 로그인해 주세요`,
            step_url : OTT.loginUrl,
        },
        {   // 1
            step_title : '회원 정보 수정을 진행합니다',
            step_url : 'https://m.wavve.com/',
        },
        {   // 2
            step_title : '회원 정보 수정을 진행합니다',
            step_url : 'https://m.wavve.com/my'
        },
        {   // 3, 
            step_title : '회원 정보 수정을 진행합니다',
            step_url : 'https://m.wavve.com/lnbUserInfo?referer='
        },
        {   // 4. 
            step_title : '회원 정보 수정을 진행합니다',
            step_url : 'https://member.wavve.com/mobile/me?device=mobile&credential'            
        },
        {   // 5.
            step_title : '비밀번호 변경을 진행합니다',
            step_url : 'https://member.wavve.com/mobile/me/info?token='            
        },
        {   // 6. 
            step_title : '비밀번호 변경을 진행합니다',
            step_url : 'https://member.wavve.com/mobile/me/info/pwd?token='            
        },
        {   // 7. 
            step_title : '비밀번호 변경이 완료되었습니다',
            step_url : 'https://member.wavve.com/mobile/me/info?token='            
        },    
        {   // 8. 
            step_title : '비밀번호 변경이 완료되었습니다',
            step_url : 'https://member.wavve.com/mobile/me/info?token='            
        },      
    ];

    const _STEP_TVING = [
        {   // 0
            step_title : `${OTT.name}에 로그인해 주세요`,
            step_url : 'https://www.tving.com/onboarding',
        },
        {   // 1
            step_title : `${OTT.name}에 로그인해 주세요`,
            step_url : 'https://user.tving.com/user/otherLogin.tving?loginType=20&from=mweb&rtUrl=https%3A%2F%2Fwww.tving.com%2Fonboarding&csite=mOauth&isAuto=false',
        },
        {   // 2
            step_title : '비밀번호 변경을 진행합니다',
            step_url : 'https://user.tving.com/user/login/complete.tving'
        },
        {   // 3, 
            step_title : '비밀번호 변경을 진행합니다',
            step_url : 'https://user.tving.com/pc/user/info/confirm/password.tving?returnUrl=https%3A%2F%2Fuser.tving.com%2Fpc%2Fuser%2Fmodify%2Fpassword.tving'
        },
        {   // 4. 
            step_title : '비밀번호 변경을 진행합니다',
            step_url : 'https://user.tving.com/pc/user/modify/password.tving'            
        },
        {   // 5.
            step_title : '비밀번호 변경을 진행합니다',
            step_url : ''            
        },
        {   // 6.
            step_title : '비밀번호 변경이 완료되었습니다',
            step_url : 'https://user.tving.com/pc/user/modify/info.tving'            
        },        
    ];    

    const _STEP_WHATCHA = [
        {   // 0
            step_title : `${OTT.name}에 로그인해 주세요`,
            step_url : 'https://watcha.com/',
        },
        {   // 1
            step_title : `${OTT.name}에 로그인해 주세요`,
            step_url : 'https://watcha.com/sign_in?redirect_uri=%2F',
        },
        {   // 2
            step_title : '비밀번호 변경을 진행합니다',
            step_url : 'https://watcha.com/settings/password'
        },
        {   // 3, 
            step_title : '비밀번호 변경을 진행합니다',
            step_url : ''
        },
        {   // 4. 
            step_title : '비밀번호 변경이 완료되었습니다',
            step_url : ''            
        },
    ];    

    const onNavigationStateChange = async (navState) => {
        try {
            setCanGoBack(navState.canGoBack);
            setCanGoForward(navState.canGoForward);
            if (currentUrl !== navState.url) {
                // 새로운 URL로 변경됨
                console.log(`URL 변경됨(${curStep}):[${navState.url}]`);
                setCurrentUrl(navState.url); // 현재 URL 상태 업데이트

                if(item.item_id===4) {  // 웨이브
                    if( navState.url === _STEP[0].step_url) {
                        SetStepTitle(_STEP[0].step_title)
                        SetCurStep(1);
                        if(user_item.account_userid ) {
                            await copyToClipboard(user_item.account_userid);
                            showMessage([
                                `${OTT.name} 계정 [${user_item.account_userid}]을 복사하였습니다.`,
                                '이메일 주소란에 붙여넣기 하시고 비밀번호도 복사하시여 붙여 넣으세요',
                                `${_STEP[0].step_title}`,
                            ]);
                        } else {
                            showMessage([
                                `계정 정보를 입력하시고${OTT.name} 에 로그인 해 주세요`
                            ]);                        
                        }
                    } else if( navState.url === _STEP[1].step_url) {
                        SetStepTitle(_STEP[1].step_title)
                        SetCurStep(2);
                        showMessage(`오른쪽 하단의 'my' 메뉴을 클릭하세요`);
                        //setSource({ uri: _STEP[2].checkClickUrl, webSecurityEnabled: false });
                    } else if( navState.url === _STEP[2].step_url) {
                        SetStepTitle(_STEP[2].step_title)
                        SetCurStep(3);                    
                        showMessage(`왼쪽 상단의 '프로필 이미지' 메뉴을 클릭하세요`);
                    } else if( navState.url.includes(_STEP[3].step_url)) {
                        SetStepTitle(_STEP[3].step_title)
                        SetCurStep(4);
                        showMessage(`'회원정보 수정' 메뉴을 클릭하세요`);
                    } else if( navState.url.includes(_STEP[4].step_url)) {
                        SetStepTitle(_STEP[4].step_title)
                        SetCurStep(5);
                        await copyToClipboard(user_item.account_pwd);
                        showMessage([
                            `[${user_item.account_pwd}]을 복사하였습니다`,
                            `비밀번호 란에 [${user_item.account_pwd}]를 붙여넣기 하세요`,
                            `입력이 완료 되었으면 '확인' 버튼을 클릭하여 진행해 주세요`,
                        ]);                
                    } else if( curStep === 5 && navState.url.includes(_STEP[5].step_url)) {
                        SetStepTitle(_STEP[5].step_title)
                        SetCurStep(6);
                        setPwd(generateRandomPassword());            
                        showMessage([
                            `'비밀번호 변경하기'를 클릭하세요`,
                        ]);
                    } else if( navState.url.includes(_STEP[6].step_url)) {
                        SetStepTitle(_STEP[6].step_title)
                        SetCurStep(7);
                        await copyToClipboard(pwd);
                        showMessage([
                            `${OTT.name} 새로운 비밀번호을 복사하였습니다.`,
                            `새 비밀번호 란에 [${pwd}]를 붙여넣기 하세요`,
                            `입력이 완료 되었으면 '확인' 버튼을 클릭하여 진행해 주세요`,
                        ]);   
                    } else if( curStep === 7 && navState.url.includes(_STEP[7].step_url)) {
                        SetStepTitle(_STEP[7].step_title)
                        SetCurStep(8);
                        const ret = await ChangeOTTAccountToDB(user_item.user_item_id, user_item.account_userid, pwd );
                        if( ret.success) {
                            await ReadUserItem(user_item.user_id);
                            setIsActive(true);
                            setStepButtonTitle('완료');
                            showMessage([
                                '감사합니다. 성공적으로 비밀번호가 변경되었습니다',
                                `'완료' 버튼을 클릭하세요`
                            ]);
                        } else {
                            setIsActive(true);
                            setStepButtonTitle('취소');
                            showMessage([
                                '죄송합니다',
                                `${OTT.name} 계정의 비밀번호[${pwd}]를 저장하지 못했습니다.`,
                                '비밀번호를 기록해 두시고 관리자에게 문의해 주세요.'
                            ]);
                        }
                    }
                } else if(item.item_id === 3) {   // 티빙
                    if( navState.url === _STEP_TVING[0].step_url) {
                        SetStepTitle(_STEP_TVING[0].step_title)
                        SetCurStep(1);
                        setSource({ uri: _STEP_TVING[1].step_url, webSecurityEnabled: false });
                    } else if( navState.url === _STEP_TVING[1].step_url) {
                        SetStepTitle(_STEP_TVING[1].step_title)
                        SetCurStep(2);
                        if(user_item.account_userid ) {
                            await copyToClipboard(user_item.account_userid);
                            showMessage([
                                `${OTT.name} 계정 [${user_item.account_userid}]을 복사하였습니다.`,
                                '아이디란에 붙여넣기 하시고 비밀번호도 복사하시여 붙여 넣으세요',
                                `'로그인하기' 버튼을 클릭하세요`,
                            ]);
                        } else {
                            showMessage([
                                `계정 정보를 입력하시고${OTT.name} 에 로그인 해 주세요`
                            ]);                        
                        }
                    } else if( navState.url === _STEP_TVING[2].step_url) {
                        SetStepTitle(_STEP_TVING[2].step_title)
                        SetCurStep(3);     
                        setSource({ uri: 'https://user.tving.com/pc/user/modify/password.tving', webSecurityEnabled: false });               
                    } else if( navState.url === _STEP_TVING[3].step_url) {
                        SetStepTitle(_STEP_TVING[3].step_title)
                        SetCurStep(4);
                        if(user_item.account_pwd ) {
                            await copyToClipboard(user_item.account_pwd);
                            showMessage([
                                `${OTT.name} 계정의 비밀번호 [${user_item.account_pwd}]을 복사하였습니다.`,
                                `비밀번호란에 붙여넣기 하시고 '확인' 버튼을 클릭하세요`,
                            ]);
                        } else {
                            showMessage([
                                `계정 비밀번호를 입력하시고 '확인' 버튼을 클락하세요`
                            ]);                        
                        }
                    } else if( navState.url === _STEP_TVING[4].step_url) {
                        SetStepTitle(_STEP_TVING[4].step_title)
                        const newPwd = generateRandomPassword();
                        setPwd(newPwd);
                        SetCurStep(6);     
                        await copyToClipboard(newPwd);
                        showMessage([
                            `${OTT.name} 새로운 비밀번호[${newPwd}]를 복사하였습니다.`,
                            `비밀번호란에 붙여넣기 하시고 '수정' 버튼을 클릭하세요`,
                        ]);
                    } else if( navState.url === _STEP_TVING[6].step_url) {
                        SetStepTitle(_STEP_TVING[6].step_title)
                        SetCurStep(8);
                        const ret = await ChangeOTTAccountToDB(user_item.user_item_id, user_item.account_userid, pwd );
                        if( ret.success) {
                            await ReadUserItem(user_item.user_id);
                            setIsActive(true);
                            setStepButtonTitle('완료');
                            showMessage([
                                '감사합니다. 성공적으로 비밀번호가 변경되었습니다',
                                `'완료' 버튼을 클릭하세요`
                            ]);
                        } else {
                            setIsActive(true);
                            setStepButtonTitle('취소');
                            showMessage([
                                '죄송합니다',
                                `${OTT.name} 계정의 비밀번호[${pwd}]를 저장하지 못했습니다.`,
                                '비밀번호를 기록해 두시고 관리자에게 문의해 주세요.'
                            ]);
                        }                        
                    }
                } else if(item.item_id === 5) {   // 왓챠
                    if( curStep===0 && navState.url === _STEP_WHATCHA[0].step_url) {
                        SetStepTitle(_STEP_WHATCHA[0].step_title)
                        SetCurStep(1);
                        setSource({ uri: _STEP_WHATCHA[1].step_url, webSecurityEnabled: false });
                    } else if( navState.url === _STEP_WHATCHA[1].step_url) {
                        SetStepTitle(_STEP_WHATCHA[1].step_title)
                        SetCurStep(2);
                        _STEP_WHATCHA[0].step_url = '';
                        if(user_item.account_userid ) {
                            await copyToClipboard(user_item.account_userid);
                            showMessage([
                                `${OTT.name} 계정 [${user_item.account_userid}]을 복사하였습니다.`,
                                '아이디란에 붙여넣기 하시고 비밀번호도 복사하시여 붙여 넣으세요',
                                `'로그인' 버튼을 클릭하세요`,
                            ]);
                        } else {
                            showMessage([
                                `계정 정보를 입력하시고${OTT.name} 에 로그인 해 주세요`
                            ]);                        
                        }
                    } else if( curStep===2 && navState.url === _STEP_WHATCHA[0].step_url) {
                        SetStepTitle(_STEP_WHATCHA[2].step_title)
                        SetCurStep(3);     
                        setSource({ uri: _STEP_WHATCHA[2].step_url, webSecurityEnabled: false });               
                    } else if( navState.url === _STEP_WHATCHA[2].step_url) {
                        const newPwd = generateRandomPassword();
                        setPwd(newPwd);
                        setLoginCompleted(true);
                        SetCurStep(4);     
                        await copyToClipboard(user_item.account_pwd);
                        showMessage([
                            `${OTT.name} 계정 비밀번호[${user_item.account_userid}]를 복사하였습니다.`,
                            `기존 비밀번호란에 붙여넣기 하시고, 새 비밀번호도 복사하시여 붙여 넣으세요`,
                            `붙여 넣기 하신다음 '저장' 버튼을 클릭하세요`,
                        ]);
                    } else if( curStep===4 && navState.url === _STEP_WHATCHA[0].step_url) {
                        SetStepTitle(_STEP_WHATCHA[4].step_title)
                        SetCurStep(8);
                        const ret = await ChangeOTTAccountToDB(user_item.user_item_id, user_item.account_userid, pwd );
                        if( ret.success) {
                            await ReadUserItem(user_item.user_id);
                            setIsActive(true);
                            setStepButtonTitle('완료');
                            showMessage([
                                '감사합니다. 성공적으로 비밀번호가 변경되었습니다',
                                `'완료' 버튼을 클릭하세요`
                            ]);
                        } else {
                            setIsActive(true);
                            setStepButtonTitle('취소');
                            showMessage([
                                '죄송합니다',
                                `${OTT.name} 계정의 비밀번호[${pwd}]를 저장하지 못했습니다.`,
                                '비밀번호를 기록해 두시고 관리자에게 문의해 주세요.'
                            ]);
                        }                        
                    }
                }
            }
        } catch(error) {
            console.log(error);
        }
    };

    // useEffect(() => {
    //     console.log(OTT.id, OTT.loginUrl);
    // }, []);   

    const goBack = () => {
        webviewRef.current.goBack();
    };

    const goForward = () => {
        webviewRef.current.goForward();
    };       

    const CloseButton = () => {
        navigation.goBack();
    };    

    const copyToClipboard = async (text) => {
        await Clipboard.setStringAsync(text);
        //showToast('클립보드에 복사되었습니다.');
    };    

    const completeProc = () => {
        if (curStep === 8) {
            const new_user_item = {...user_item};
            new_user_item.account_pwd = pwd;           
            navigation.navigate('DisplayUserItem', {user_item : new_user_item, item, userAccounts });
        }        
    };

    const windowWidth = Dimensions.get('window').width * 0.8;
    return (
        <View style={GSS.mainContainer}>
            <CloseHeader
                closeAction={CloseButton}
            >
                <Text style={GSS.mainDefaultTextB}>{OTT.name} 비밀번호 변경</Text>
            </CloseHeader>
            <View style={[GSS.mainViewBoxNoBorder, {marginTop:5}]}>
                <Text style={[GSS.mainDefaultTextB]}>
                    {stepTitle}
                </Text>
            </View>
            <View style={{flexDirection:'row'}}>
                <View style={{flex:1, borderWidth:0.25, alignItems:'center', justifyContent:'center'}}>
                    <TouchableOpacity onPress={goBack} disabled={!canGoBack}>
                        <Text style={[GSS.mainDefaultText, {color: canGoBack ? '#291B44' : '#c4c4c4'}]}>{'<< 뒤로'}</Text>
                    </TouchableOpacity>
                </View>
                <View style={{flex:1, borderWidth:0.25, alignItems:'center', justifyContent:'center'}}>
                    <TouchableOpacity onPress={goForward} disabled={!canGoForward}>
                        <Text style={[GSS.mainDefaultText, {color: canGoForward ? '#291B44' : '#c4c4c4'}]}>{'앞으로 >>'}</Text>
                    </TouchableOpacity>
                </View>
            </View>
            <WebView 
                ref={webviewRef}
                onNavigationStateChange={onNavigationStateChange}
                style={{flex:1}}
                //scalesPageToFit={false}
                source={source}
                originWhitelist={['*']}
            />
            <View style={{flexDirection:'row', marginTop:5}}>
                <View style={{flex:1}}>
                    <View style={[SS.textContainer]}>
                        { (curStep > -1 && curStep < 6) && loginCompleted===false && (
                            <React.Fragment>
                                <View style={{flexDirection:'row'}}>
                                    <View style={{width:45}}>
                                        <Text style={GSS.mainDefaultText}>계정</Text>
                                    </View>
                                    <View style={{flex:1, }}>
                                        <Text style={GSS.mainInfoTextB}>{user_item.account_userid}</Text>
                                    </View>
                                    <TouchableOpacity style={{flex:0.2}}  onPress={() => copyToClipboard(user_item.account_userid)}>
                                        <Text style={GSS.mainInfoText}>복사</Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={{flexDirection:'row', marginBottom:5}}>
                                    <View style={{width:45}}>
                                        <Text style={GSS.mainDefaultText}>비번</Text>
                                    </View>
                                    <View style={{flex:1, }}>
                                        <Text style={GSS.mainInfoTextB}>{user_item.account_pwd}</Text>
                                    </View>
                                    <TouchableOpacity style={{flex:0.2}}   onPress={() => copyToClipboard(user_item.account_pwd)}>
                                        <Text style={GSS.mainInfoText}>복사</Text>
                                    </TouchableOpacity>
                                </View>               
                            </React.Fragment>
                        )}
                        { curStep > 5 && (
                            <React.Fragment>
                                <View style={{flexDirection:'row'}}>
                                    <View style={{width:80}}>
                                        <Text style={GSS.mainDefaultText}>새 비밀번호</Text>
                                    </View>
                                    <View style={{flex:1, }}>
                                        <Text style={GSS.mainInfoTextB}>{pwd}</Text>
                                    </View>
                                    <TouchableOpacity style={{flex:0.2}} onPress={() => copyToClipboard(pwd)}>
                                        <Text style={GSS.mainInfoText}>복사</Text>
                                    </TouchableOpacity>
                                </View>
                            </React.Fragment>
                        )}
                        { item.item_id===5 && curStep > 3 && (
                            <React.Fragment>
                                <View style={{flexDirection:'row'}}>
                                    <View style={{width:80}}>
                                        <Text style={GSS.mainDefaultText}>기존 비밀번호</Text>
                                    </View>
                                    <View style={{flex:1, }}>
                                        <Text style={GSS.mainInfoTextB}>{user_item.account_pwd}</Text>
                                    </View>
                                    <TouchableOpacity style={{flex:0.2}} onPress={() => copyToClipboard(user_item.account_pwd)}>
                                        <Text style={GSS.mainInfoText}>복사</Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <View style={{width:80}}>
                                        <Text style={GSS.mainDefaultText}>새 비밀번호</Text>
                                    </View>
                                    <View style={{flex:1, }}>
                                        <Text style={GSS.mainInfoTextB}>{pwd}</Text>
                                    </View>
                                    <TouchableOpacity style={{flex:0.2}} onPress={() => copyToClipboard(pwd)}>
                                        <Text style={GSS.mainInfoText}>복사</Text>
                                    </TouchableOpacity>
                                </View>
                            </React.Fragment>
                        )}                        
                    </View>
                </View>
                {isLoading && (
                <View style={GSS.activityIndicatorContainer}>
                 <ActivityIndicator size='large' color="#0000ff" />
                </View>
                )}                    
                <View style={{marginLeft:5, alignItems:'center', justifyContent:'center', flex:0.2, borderWidth:0.5, borderRadius:10,
                    backgroundColor: (!isActive || stepButtonTitle==='...') ? '#eFeCe3' : '#017cf7'}}>
                    <TouchableOpacity 
                        disabled={!isActive || stepButtonTitle === '...'} 
                        onPress={completeProc}
                    >
                        <Text style={[GSS.mainBigText, {color:'#ffffff'}]}>{isActive ? stepButtonTitle : `${countdown}`}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>   
    );
};

const SS = StyleSheet.create({
    container: {
      // 컨테이너를 flex 컨테이너로 설정합니다.
      flex: 1,
      justifyContent: 'flex-end', // 자식 요소들을 컨테이너의 오른쪽으로 정렬합니다.
      alignItems: 'center', // 자식 요소들을 컨테이너의 중앙으로 정렬합니다(수직 방향).
      borderWidth:1,
      borderColor : '#8F8C83',
      margin:5,
      borderRadius:10,
      marginBottom : 15
    },
    confirmView: {
        //flex: 
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems : 'center',
        marginBottom : 10
    },
    textContainer: {
        alignItems:'center',
        padding:10, 
        borderWidth:1,
        borderRadius:5,
        borderColor: '#8F8C83'
    }
});

export default ChangeMyshottPassword;