import React, {useState, useEffect, useCallback } from 'react';
import { 
  Text, View, Image, TextInput, Platform,
} from 'react-native';
import infoImage from '../../assets/info_right.png';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import GSS from '../GlobalStyleSheet2';
import { ProgressNext } from '../ProgressButton';
import CloseHeader from '../CloseHeader';
import { GetOTTByName } from '../AppContext';
import { SaveNickNameToDB, GetPatryNicknams } from '../api';
import { showToast } from '../Global';
import { useUserItem } from '../UserItemContext';
import { useModal } from '../ModalContext';

function MatchedProc2 ({navigation, route}) {
  const { matchedItem, item } = route.params;
  const [checkCompleted, setCheckCompleted] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const { ReadUserItem } = useUserItem();
  const [prevNicks, setPrevNicks] = useState([]);
  const [ usedNicks, setUsedNicks] = useState('');
  const { showMessage} = useModal();

  useEffect(() => {
    try {
      (async () => {
        if( matchedItem.using_status) {
          setInputValue(matchedItem.nick_name);
        }
        const ret = await GetPatryNicknams(matchedItem.user_item_id);
        if( ret.success) {
          //console.log(ret.data.length);
          setPrevNicks(ret.data);
          let _nicks = '';
          for( const nick of ret.data) {
            //console.log(nick, typeof nick);
            if( nick) _nicks += ` [${nick}]`;
          }
          setUsedNicks(_nicks);
        }
      })(); 
    } catch (error) {
        console.error(error.message);
    }
  }, []); 

  async function NextStep()
  {
    try {
      const index = prevNicks.indexOf(inputValue);
      if( index !== -1) {
        showMessage(`[${inputValue}]은 사용중인 닉네임입니다`);
        return;
      }

      const ret = await SaveNickNameToDB(matchedItem.used_item_id, inputValue);
      if (ret.success) {
        await ReadUserItem(matchedItem.user_id);
        if( matchedItem.using_status) {
          const used_item = {...matchedItem};
          used_item.nick_name = inputValue;
          navigation.navigate('DisplayUsedItem', { used_item, item });
        } else {
          navigation.reset({
            index: 0,
            routes: [{ name: 'Home' }],
          });  
        }
      } else {
        showMessage('프로필 닉네임을 저장 할 수 없습니다. 관리자에게 문의 하세요');
      }
    } catch (e) {
      console.log('MatchedProc2.SaveNickNameToDB', e);
    }
  }


  const NextButton = () => {
    return (
      <ProgressNext
        title={'다음'}
        onPress={ checkCompleted ? NextStep : null}
        disabled={checkCompleted ? false : true}
      />
    );
  }  

  const CloseButton = () => {
    if( matchedItem.using_status)
    {
      navigation.goBack();
    } else {
      navigation.reset({
        index: 0,
        routes: [{ name: 'Home' }],
      });   
    }
  };    

  const InputField = React.memo(({ initialValue, onBlur, index }) => {
    const [localValue, setLocalValue] = useState(initialValue);
  
    // useEffect(() => {
    //   setLocalValue(initialValue);  // 외부에서 전달된 값으로 로컬 상태를 초기화합니다.
    // }, [initialValue]);
  
    const handleTextChange = (input) => {
      // 공백이 포함되어 있으면 입력을 업데이트하지 않음
      if (input.includes(' ')) {
        return;
      }      
      setLocalValue(input);  // 사용자 입력으로 로컬 상태를 업데이트합니다.
    };
  
    const handleBlurEvent = () => {
      onBlur(localValue);  // 포커스가 사라질 때 변경 사항을 외부로 전달합니다.
    };
  
    return (
      <View style={GSS.textInputContainer}>
        <TextInput
          style={GSS.textInputText}
          maxLength={6}
          placeholder={`프로필의 닉네임을 입력하세요`}
          value={localValue}
          onChangeText={handleTextChange}
          onBlur={handleBlurEvent}  // 사용자가 이 필드에서 벗어났을 때 handleBlurEvent 호출
        />
      </View>
    );
  });

  const handleBlur = useCallback((text) => {
    if(text.length == 0) {
        setCheckCompleted(false);
      } else {
        setCheckCompleted(true);
      }
      setInputValue(text);  // 이렇게 직접 업데이트합니다.
  }, []);

  const renderInputField = () => {
    return (
        <InputField 
            initialValue = {inputValue}
            onBlur={handleBlur}  // onBlur 이벤트 핸들러를 props로 전달합니다.
      />
    );
  };

  const renderProfile = () => {
    const OTT = GetOTTByName(item.item_name);
    return (
        <View style={{alignItems: 'center', margin:15}}>
            <Image source={OTT.profileImage} style={{ width: 50, height: 50, marginBottom: 5}}></Image>
            <Text>{inputValue}</Text>
        </View>
    );
  };  

  return (
    <KeyboardAwareScrollView
      style={{ flex: 1 }}
      resetScrollToCoords={{ x: 0, y: 0 }}  // 필요한 경우 스크롤 위치 조정
      contentContainerStyle={{ flexGrow: 1 }}  // 컨텐츠 스타일 조정
      scrollEnabled={true}  // 필요에 따라 스크롤 비활성화
    >
        <View style={GSS.mainContainer}>
          <CloseHeader
              closeAction={CloseButton}
          />
          <View style={[GSS.mainViewBox, {flex:0.8, flexDirection : 'row', justifyContent:'center', alignItems:'center'}]}>
            <View><Image source={infoImage} style={{ width: 25, height: 25, marginLeft:5 }}/></View>
            <View style={{padding: 5}}>
              <Text style={GSS.mainDefaultText}>• {item.display_name}에서도 <Text style={GSS.textEmphasis}>동일한 닉네임</Text>으로 설정해주세요</Text>
              <Text style={GSS.mainDefaultText}>• 닉네임이 다르면 파티원이 내 프로필을 <Text style={GSS.textEmphasisAlert}>이용•삭제</Text>할 수도 있습니다</Text>
              <Text style={GSS.mainDefaultText}>• 프로필의 닉네임은 최대 6자리까지 가능합니다.</Text>
            </View>
          </View>
          { usedNicks !== '' && (
            <View style={[GSS.mainViewBox, {flex:0.8, flexDirection : 'row', justifyContent:'center', alignItems:'center'}]}>
            <View style={{padding: 5}}>
              <Text style={GSS.mainDefaultText}>• 현재 사용중인 닉네임은 <Text style={GSS.textEmphasis}>{usedNicks}</Text> 입니다</Text>
              <Text style={GSS.mainDefaultText}>• 사용중인 닉네임은<Text style={GSS.textEmphasisAlert}>사용</Text>할 수 없습니다</Text>
            </View>
          </View>  
          )}
          <View style={[GSS.mainViewBox, {flex:1, justifyContent: 'center', alignItems: 'center'}]}>
            <View>
                <Text style={GSS.mainDefaultText}>사용하고자 하는 프로필</Text>
            </View>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              {renderProfile()}
            </View>
          </View>
          <View style={[GSS.mainViewBox, {flex:1}]}>
            {renderInputField()}
          </View>
          <View style={[GSS.mainViewBox, {flex:1, margin: 5, alignItems:'center', justifyContent: 'center'}]}>
            <NextButton/>
          </View> 
        </View>  
    </KeyboardAwareScrollView>
  );
};

export default MatchedProc2;