import React, {useState, useEffect, useRef} from 'react';
import { Image, Text, View, StyleSheet, Dimensions, TouchableOpacity, ActivityIndicator } from 'react-native';
import ConfirmImage from '../ConfirmImage';
import GSS from '../GlobalStyleSheet2';
import { ProgressNext } from '../ProgressButton';
import CloseHeader from '../CloseHeader';
import { showToast } from '../Global';
import { ScrollView } from 'react-native-gesture-handler';
import * as WebBrowser from 'expo-web-browser';
import { useModal } from '../ModalContext';
import { SetSyncEmailID } from '../api';
import { useUserItem } from '../UserItemContext';
import * as Clipboard from 'expo-clipboard';
import LinkImage from '../../assets/share.png';

function NetflixMatchedProc ({navigation, route}) {
    const { user_item, item, userAccounts } = route.params;
    const { confirmModal, showMessage, showModalMessage } = useModal();
    const [isLoading, setIsLoading] = useState(false);
    const [ email, setEmail] = useState();

    const [ accountName, setAccountName] = useState();
    const [ curStep, SetCurStep] = useState(0);
    const [ stepTitle, SetStepTitle] = useState('');
    // 다음 버튼 처리에 대한 셋팅딩
    const [countdown, setCountdown] = useState(0);
    const [isActive, setIsActive] = useState(true);
    const [intervalId, setIntervalId] = useState(null);
    const [ stepButtonTitle, setStepButtonTitle] = useState('...');
    /////
    const [ source , setSource] = useState({ uri: `https://www.netflix.com/kr/login`, webSecurityEnabled: false });
    const [currentUrl, setCurrentUrl] = useState(null); // 현재 URL 상태
    const _STEP = [
        {   // 0
            step_title : '넷플릭스에 로그인해 주세요',
            step_url : 'https://www.netflix.com/kr/login'
        },
        {   // 1
            step_title : '넷플릭스에 로그인해 주세요',
            step_url : 'https://www.netflix.com/browse'
        },
        {   // 2
            step_title : '추가회원 자리를 구매 해 주세요',
            step_url : 'https://www.netflix.com/YourAccount'
        },
        {   // 3, 추가 회원이 등록 되었다.
            stepTitle : '추가회원을 초대해주세요',
            step_url : 'https://www.netflix.com/accountowner/extramemberadded'
        },
        {   // 4. 추가회원 등록 화면
            stepTitle : '추가회원을 초대해주세요',
            step_url : 'https://www.netflix.com/accountowner/setup'            
        },
        {   // 5. 추가회원 등록 화면
            stepTitle : '추가회원을 초대해주세요',
            step_url : 'https://www.netflix.com/accountowner/newprofile'            
        },
        {   // 6. 완료
            stepTitle : '추가회원 초대가 완료되었습니다',
            step_url : 'https://www.netflix.com/accountowner/success'            
        }        
    ];

    const onNavigationStateChange = async (navState) => {
        try {
            setCanGoBack(navState.canGoBack);
            setCanGoForward(navState.canGoForward);
            if (currentUrl !== navState.url) {
                // 새로운 URL로 변경됨
                //console.log("URL 변경됨:", navState.url);
                setCurrentUrl(navState.url); // 현재 URL 상태 업데이트
                // 여기에 추가 로직을 구현할 수 있습니다.
                if( navState.url === _STEP[0].step_url) {
                    if(user_item.account_userid ) {
    
                        
                        await copyToClipboard(user_item.account_userid);
                        showMessage([
                            `넷플릭스 계정 [${user_item.account_userid}]을 복사하였습니다.`,
                            '이메일 주소란에 붙여넣기 하시고 비밀번호도 복사하시여 붙여 넣으세요',
                            '넷플릭스를 로그인 해 주세요'
                        ]);
                    } else {
                        showMessage([
                            '계정 정보를 입력하시고 넷플릭스를 로그인 해 주세요'
                        ]);                        
                    }
                } else if( navState.url === _STEP[1].step_url) {
                    SetCurStep(1);
                    setSource({ uri: _STEP[2].step_url, webSecurityEnabled: false });
                } else if( navState.url === _STEP[2].step_url) {
                    SetCurStep(2);
                    setStepButtonTitle('다음');
                    showMessage([
                        `추가회원 자리를 구매 해 주세요.`,
                        '이미 추가회원 자리가 있으면 다음을 클릭하세요',
                    ]);                
                } else if( navState.url === _STEP[4].step_url) {
                    SetCurStep(4);
                    setStepButtonTitle('...');
                    await copyToClipboard(email);
                    showMessage([
                        `추가회원 계정 [${email}]을 복사하였습니다(지정된 이메일을 사용치 않으면 공유가 불가능합니다)`,
                        `이름란에 아래 값을 입력하시고, 이메일주소 입력란에는 바로 붙여넣기를 하세요`,
                        `입력이 완료 되었으면 '다음' 버튼을 클릭하여 진행해 주세요`,
                    ]);                
                } else if( navState.url === _STEP[5].step_url) {
                    SetCurStep(5);
                    showMessage([
                        `추가회원은 프로필은 '새 프로필' 그대로 사용하세요`,
                        `'다음' 버튼을 클릭하여 진행해 주세요`,
                    ]);                
                } else if( navState.url === _STEP[6].step_url) {
                    SetCurStep(6);
                    setStepButtonTitle('완료');
                    showMessage([
                        `초가회원 초대가 완료되었습니다`,
                        `자동매칭을 통한 편한 공유가 진행됩니다.`,
                        `'완료' 버튼을 클릭하여 종료하세요`,
                    ]);                
                }
            }
        } catch(error) {
            console.log(error);
        }
     };


    useEffect(() => {
         //console.log(user_item);
        // console.log(userAccounts);
        if(userAccounts ) {
            if(userAccounts[0].account_id === user_item.account_email_id ) {
                setAccountName('1');
            } else {
                setAccountName('2');
            }
            setEmail(userAccounts[0].account_email);
        }
    }, []);    

    const CloseButton = () => {
        navigation.goBack();
    };    

    const copyToClipboard = async (text) => {
        await Clipboard.setStringAsync(text);
        //showToast('클립보드에 복사되었습니다.');
    };    
    
    const _completeProc = async () => {
        navigation.goBack();
    }

    const completeProc = () => {
        const _confirem = {
            afterOK : _completeProc,
            body: `초대장이 발송되었나요?`,
            noTitle : '아니오',
            yesTitle : '예'
        }
        confirmModal(_confirem);
    }    

    function _goUrl() {
        window.open('https://www.netflix.com/kr/', 'myshott_netflix');
    }
    async function goUrl() {
        await copyToClipboard(user_item.account_userid);
        const _message = {
            title : '넷플릭스에 로그인하세요',
            body : [
                `넷플릭스 계정 [${user_item.account_userid}]을 복사하였습니다.`,
                '이메일 주소란에 붙여넣기 하시고 비밀번호도 복사하시여 붙여 넣으세요',
                '넷플릭스를 로그인 해 주세요'
            ],
            afterOK : _goUrl
        }
        showModalMessage(_message);
    }       

    function goUrl2() {
        window.open('https://www.netflix.com/ManageExtraMembers', 'myshott_netflix');
    }
    
    async function _goUrl3() {
        try {
            //await SetSyncEmailID(userAccounts[0].account_id);
            window.open('https://www.netflix.com/accountowner/setup', 'myshott_netflix');
    
            setIsActive(true);
            setStepButtonTitle('추가회원 초대 완료');
        } catch(e) {
            console.log(e);
        }
    }

    async function goUrl3() {
        await copyToClipboard(email);
        const _message = {
            title : '추가회원에 초대해 주세요',
            body : [
                `추가회원 계정 [${email}]을 복사하였습니다(지정된 이메일을 사용치 않으면 공유가 불가능합니다)`,
                `이름란에 아래 숫자값을 입력하시고, 이메일주소 입력란에는 바로 붙여넣기를 하세요`,
                `입력이 완료 되었으면 '다음' 버튼을 클릭하여 진행해 주세요`,            ],
            afterOK : _goUrl3
        }
        showModalMessage(_message);
    }    

    const windowWidth = Dimensions.get('window').width * 0.8;
    return (
        <View style={GSS.mainContainer}>
            <CloseHeader
                closeAction={CloseButton}
            >
                <Text style={GSS.mainInfoTextB}>넷플릭스 추가회원 초대하기</Text>
            </CloseHeader>
            <View style={[GSS.mainViewBoxNoBorder, { margin:10}]}>
                <Text style={[GSS.mainInfoTextB]}>
                    {'1번 → 2번 → 3번 순으로 진행해 주세요 (바로가기 버튼을 이용하세요)'}
                </Text>
            </View>            
            <View style={GSS.mainViewBox}>
                <Text style={GSS.mainDefaultText}>넷플릭스 계정 정보</Text>
                <View style={{borderWidth:1, borderRadius:5, margin:5}}>
                    <View style={{flexDirection:'row'}}>
                        <View style={{width:45}}>
                            <Text style={GSS.mainDefaultText}>계정</Text>
                        </View>
                        <View style={{flex:1, }}>
                            <Text style={GSS.mainInfoTextB}>{user_item.account_userid}</Text>
                        </View>
                        <TouchableOpacity style={{flex:0.1}}  onPress={() => copyToClipboard(user_item.account_userid)}>
                            <Text style={GSS.mainInfoText}>복사</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{flexDirection:'row', marginBottom:5}}>
                        <View style={{width:45}}>
                            <Text style={GSS.mainDefaultText}>비번</Text>
                        </View>
                        <View style={{flex:1, }}>
                            <Text style={GSS.mainInfoTextB}>{user_item.account_pwd}</Text>
                        </View>
                        <TouchableOpacity style={{flex:0.1}}   onPress={() => copyToClipboard(user_item.account_pwd)}>
                            <Text style={GSS.mainInfoText}>복사</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
            <View style={GSS.mainViewBox}>
                <View>
                    <TouchableOpacity
                        onPress={goUrl}
                        style={{flexDirection:'row'}}
                    >
                        <Text style={GSS.mainInfoText}>1. 넷플릭스 로그인</Text>
                        <Image
                            source={LinkImage}
                            style={{ marginLeft:10, width: 20, height: 20 }}
                        />
                    </TouchableOpacity>
                </View>                
            </View>
            <View style={GSS.mainViewBox}>
                <View style={{marginTop:5}}>
                    <TouchableOpacity
                        onPress={goUrl2}
                        style={{flexDirection:'row'}}
                    >
                        <Text style={GSS.mainInfoText}>2. 넷플릭스 추가회원 자리 구매하기</Text>
                        <Image
                            source={LinkImage}
                            style={{ marginLeft:10, width: 20, height: 20 }}
                        />
                    </TouchableOpacity>
                    <Text style={GSS.mainSmallText}>★ 추가회원 자리가 이미 있는경우 3번으로 넘어가세요 </Text>
                </View>
            </View>            
            <View style={GSS.mainViewBox}>
                <Text style={GSS.mainDefaultText}>추가회원 계정 정보</Text>
                <View style={{borderWidth:1, borderRadius:5, margin:5}}>
                    <View style={{flexDirection:'row', marginBottom:5}}>
                        <View style={{width:45}}>
                            <Text style={GSS.mainDefaultText}>이름</Text>
                        </View>
                        <View style={{flex:1, }}>
                            <Text style={GSS.mainInfoTextB}>{accountName}</Text>
                        </View>
                        <TouchableOpacity style={{flex:0.1}} onPress={() => copyToClipboard(accountName)}>
                            <Text style={GSS.mainInfoText}>복사</Text>
                        </TouchableOpacity>
                    </View>               
                    <View style={{flexDirection:'row'}}>
                        <View style={{width:45}}>
                            <Text style={GSS.mainDefaultText}>이메일</Text>
                        </View>
                        <View style={{flex:1, }}>
                            <Text style={GSS.mainInfoTextB}>{email}</Text>
                        </View>
                        <TouchableOpacity style={{flex:0.1}} onPress={() => copyToClipboard(email)}>
                            <Text style={GSS.mainInfoText}>복사</Text>
                        </TouchableOpacity>
                    </View>                    
                </View>
            </View>
            <View style={GSS.mainViewBox}>
                <View style={{marginTop:5}}>
                    <TouchableOpacity
                        onPress={goUrl3}
                        style={{flexDirection:'row'}}
                    >
                        <Text style={GSS.mainInfoText}>3. 넷플릭스 추가회원 초대하기</Text>
                        <Image
                            source={LinkImage}
                            style={{ marginLeft:10, width: 20, height: 20 }}
                        />
                    </TouchableOpacity>
                    <Text style={GSS.mainSmallText}>★ 반드시 위에 지정되어 있는 마이샷 이메일계정을 초대해주세요</Text>
                    <Text style={GSS.mainSmallText}>★ 추가회원은 프로필은 '새 프로필' 그대로 사용하세요</Text>
                </View>
            </View>            
            <View style={{marginLeft:5, alignItems:'center', justifyContent:'center',borderWidth:0.5, borderRadius:5, marginTop:25, height:40,
                backgroundColor: (!isActive || stepButtonTitle==='...') ? '#eFeCe3' : '#017cf7'}}>
                <TouchableOpacity 
                    disabled={!isActive || stepButtonTitle === '...'} 
                    onPress={completeProc}
                >
                    <Text style={[GSS.mainBigText, {color:'#ffffff'}]}>{isActive ? stepButtonTitle : `...`}</Text>
                </TouchableOpacity>
            </View>

        </View>   
    );
};

const SS = StyleSheet.create({
    container: {
      // 컨테이너를 flex 컨테이너로 설정합니다.
      flex: 1,
      justifyContent: 'flex-end', // 자식 요소들을 컨테이너의 오른쪽으로 정렬합니다.
      alignItems: 'center', // 자식 요소들을 컨테이너의 중앙으로 정렬합니다(수직 방향).
      borderWidth:1,
      borderColor : '#8F8C83',
      margin:5,
      borderRadius:10,
      marginBottom : 15
    },
    confirmView: {
        //flex: 
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems : 'center',
        marginBottom : 10
    },
    textContainer: {
        alignItems:'center',
        padding:10, 
        borderWidth:1,
        borderRadius:5,
        borderColor: '#8F8C83'
    }
});

export default NetflixMatchedProc;