import React, {useState, useEffect } from 'react';
import { Text, View, Image, TouchableOpacity, StyleSheet, ActivityIndicator} from 'react-native';
import GSS from '../GlobalStyleSheet2';
import { useEnv } from '../AppContext';
import TopHeader from '../TopHeader';
import nextImage from '../../assets/next.png';
import { GetPayInfoOfDB } from '../api';
import { LinearGradient } from 'expo-linear-gradient';
import { useModal } from '../ModalContext';
import { DeletePayInfoOfDB } from '../api';

function AdminPay ({navigation, route}) {
    const { ENV, setEnv } = useEnv();
    const payUpdated = route.params?.payUpdated;
    const [cardName, setCardName] = useState();
    const [cardNum, setCardNum] =useState();
    const { showModalMessage, confirmModal } = useModal();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let isMounted = true; // 컴포넌트가 마운트 상태인지 추적합니다.
        (async () => {
          try {
            const ret = await GetPayInfoOfDB(ENV.user_id);
            if(ret.success) {
                if( ret.data.pay_cardname) {
                    setCardName(ret.data.pay_cardname);
                    setCardNum(ret.data.pay_cardnum);
                }
            }
          } catch (e) {
            console.error(e);
          }
        })();
        // 컴포넌트 언마운트 시 isMounted를 false로 설정합니다.
        return () => {
          isMounted = false;
        };
    }, [payUpdated]);   
   
    const RegisterPay= () => {
        //navigation.navigate('RegisterCard');
        // const _message = {
        //     body : '결제수단이 정상적으로 등록되면 체크/신용카드의 유효성이 확인되는 과정 중에',
        // };
        // showModalMessage(_message); 
        navigation.navigate('RegisterCardOfPayple');
    }

    const _deletePay = async () => {
        //console.log('등록카드 삭제...');
        setIsLoading(true);
        const ret = await DeletePayInfoOfDB(ENV.user_id);
        const _message = {
            body : ret.data
        }
        setIsLoading(false);
        if( ret.success) {
            _message.body = '결제수단이 정상적으로 해지 되었습니다.'
            showModalMessage(_message);
            navigation.navigate('MyInfoHome');
        } else {
            _message.body = ret.data;    
            showModalMessage(_message);
        }
    }

    const DeletePay = () => {
        const _message = {
            body : '결제수단이 삭제되면 더 이상 자동결제는 되지 않습니다. 진행하시겠습니까?',
            afterOK : _deletePay
        }
        confirmModal(_message);
    }

    return (
        <View style={GSS.mainContainer}>
            <View style={{height:50}}>
                <TopHeader 
                    navigation={navigation}
                >결제수단 변경</TopHeader>
            </View>
            { cardName ? (
                <View style={[GSS.mainViewBox, {flex:1}]}>
                    <View>
                        <Text style={[GSS.mainInfoTextB, {margin:10}]}>현재 결제 정보</Text>
                    </View>
                    <View style={{flex:1}}>
                        <View style={{alignItems:'center',justifyContent:'center', marginTop:15, marginBottom:30}}>
                            <LinearGradient
                                // 그라데이션 색상을 지정합니다. 시작 색상에서 종료 색상으로 변경됩니다.
                                //colors={['#ecc7a1', '#f1d8bd', '#f7e7d8']}
                                colors={['#C9AA00', '#EDC800', '#FFD700']}
                                // 그라데이션의 시작점과 끝점을 정의 (x축 방향으로 그라데이션)
                                start={{ x: 0, y: 0 }}
                                end={{ x: 1, y: 0 }}  // x축 방향으로 이동하면서 색상 변경            
                                style={SS.cardContainer} // 변경된 스타일 참조
                            >                            
                                <Text style={SS.topRightText}>체크/신용카드</Text>
                                <View style={SS.bottomLeftContainer}>
                                    <Text style={{color:'#F7F7F7'}}>{cardName}</Text>
                                    <Text style={{color:'#F7F7F7'}}>{cardNum}</Text>
                                </View>
                            </LinearGradient>
                        </View> 
                        <View style={{padding:10,}}>
                            <TouchableOpacity 
                                onPress={RegisterPay}
                                style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                            >
                                <Text style={[GSS.mainInfoText]}>결제수단 변경</Text>
                                <Image
                                    source={nextImage}
                                    style={{width:25, height:25}}
                                />            
                            </TouchableOpacity>
                        </View>
                        <View style={[GSS.mainViewBox, {}]}>
                            <TouchableOpacity onPress={DeletePay} >
                                <Text style={GSS.textEmphasisAlert}>결제수단 삭제하기</Text>
                            </TouchableOpacity>
                            {isLoading && (
                                <View style={GSS.activityIndicatorContainer}>
                                    <ActivityIndicator size='large' color="#0000ff" />
                                </View>
                            )}                            
                        </View>                      
                    </View>                 
                </View>
            ) : (
                <View style={[GSS.mainViewBox, {flex:1}]}>
                    <Text style={GSS.mainSmallText}>파티 이용을 위한 결제 방법을 등록해 주세요</Text>
                    <TouchableOpacity 
                        onPress={() => RegisterPay()}
                        style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                        <Text style={[GSS.mainInfoTextB]}>결제수단 등록</Text>
                        <Image
                            source={nextImage}
                            style={{width:25, height:25}}
                        />            
                </TouchableOpacity>                 
                </View>
            )}
        </View>
    );
};

const SS = StyleSheet.create({
    cardContainer: {
      height: 125,
      width: 200,
    //   borderWidth: 1,
      borderRadius: 8,
      position: 'relative', // 자식 컴포넌트를 절대 위치로 정렬하기 위해 필요
    },
    topRightText: {
      position: 'absolute',
      top: 8,
      right: 8,
      color:'#8F8C83',
    },
    bottomLeftContainer: {
      position: 'absolute',
      bottom: 8,
      left: 8
    }
  });

export default AdminPay;