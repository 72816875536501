import React, {useState, useEffect, useRef } from 'react';
import { Text, View, Image, TouchableOpacity, TextInput, ActivityIndicator } from 'react-native';
import { useEnv } from '../AppContext';
import CloseHeader from '../CloseHeader';
import GSS from '../GlobalStyleSheet2';
import nextImage from '../../assets/next.png';
import { useModal } from '../ModalContext';
import ProgressButton from '../ProgressButton';
import { GetAccountInfoFromDB, SaveAccountToDB } from '../api';
import { DeletePhoneNumberOfDB } from '../api';
import { DeleteIDOfDB } from '../api';
import { SaveToSystem } from '../Global';
import { useUserItem } from '../UserItemContext';

function AdminAccount ({navigation, route}) {
    const { ENV, setEnv } = useEnv();
    const { openModal, closeModal, showModalMessage, confirmModal} = useModal();    
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { setUserItem } = useUserItem();

    useEffect(() => {
        let isMounted = true; // 컴포넌트가 마운트 상태인지 추적합니다.
        (async () => {
          try {
            const ret = await GetAccountInfoFromDB(ENV.user_id);
            if(ret.success) {
                if( ret.data.phone_number) {
                    setPhoneNumber(ret.data.phone_number);
                }
            }
          } catch (e) {
            console.error(e);
          }
        })();
        // 컴포넌트 언마운트 시 isMounted를 false로 설정합니다.
        return () => {
          isMounted = false;
        };
    }, [phoneNumber]);     

    async function _deleteID () {
        try {
            setIsLoading(true);
            const ret = await DeleteIDOfDB(ENV.user_id);
            if(ret.success) {
                //console.log(ret.data);
                setIsLoading(false);
                if( ret.data === true) {
                    const newENV = { ...ENV };
                    newENV.user_id = null;
                    newENV.last_login_type = null;
                    await SaveToSystem(newENV);
                    setEnv(newENV);  
                    setUserItem({
                      t_user_item : [],
                      t_used_item : [],
                      t_waiting_list : [],
                      t_user_message : [],
                      t_sys_message : []
                    });
                    navigation.reset({
                      index: 0,
                      routes: [{ name: 'home' }],
                    }); 
                }
            }
        } catch(e) {
            console.log(e);
        }
    }

    const DeleteID = async () => {
        const _confirm = {
            body : '마이샷 포인트등 모든 정보가 삭제됩니다. 그래도 회원탈퇴를 진행 하시겠습니까?',
            afterOK : _deleteID
        }
        confirmModal(_confirm);        
    };

    const CloseButton = () => {
        navigation.goBack();
    };  

    const SavePhoneNumber = (number) => {
        setPhoneNumber(number);
    }
    
    const InputPhoneNumber = ({phone, modalID}) => {
        const [phoneNumber, setPhoneNumber] = useState(phone);
        const textInputRef = useRef(null);
        const { closeModal, showModalMessage} = useModal();    

        // 포커스 함수
        const focusOnTextInput = () => {
            textInputRef.current && textInputRef.current.focus();
        };

        useEffect(() => {
            focusOnTextInput();
        }, []);         

        const CloseButton = () => {
            console.log(modalID);
            closeModal(modalID);
        };  
        
        const formatPhoneNumber = (input) => {
            // 먼저 모든 비숫자 문자 제거
            const numbersOnly = input.replace(/\D/g, '');
        
            // 문자열을 세 부분으로 나누어 포매팅
            let formatted = '';
            if (numbersOnly.length <= 3) {
              formatted = numbersOnly;
            } else if (numbersOnly.length <= 7) {
              formatted = `${numbersOnly.slice(0, 3)}-${numbersOnly.slice(3)}`;
            } else {
              formatted = `${numbersOnly.slice(0, 3)}-${numbersOnly.slice(3, 7)}-${numbersOnly.slice(7, 11)}`;
            }
        
            // 최대 11자리까지만 허용 (한국 휴대폰 번호 길이)
            return formatted.slice(0, 13);
        };
        
        const handlePhoneNumberChange = (input) => {
            const formattedInput = formatPhoneNumber(input);
            setPhoneNumber(formattedInput);
        };

        const validatePhoneNumber = (number) => {
            // 한국 휴대폰 번호 형식 (010-XXXX-XXXX)
            const regex = /^010-\d{4}-\d{4}$/;
            return regex.test(number);
          };        
    
        const handleSubmit = async () => {
            const isValid = validatePhoneNumber(phoneNumber);
            if (isValid) {
                const ret = await SaveAccountToDB(ENV.user_id, phoneNumber)
                //console.log(ret);
                if(ret.success) {
                    const _msg = {
                        title : '휴대폰 번호',
                        body : '휴대폰 번호가 저장되었습니다.'
                    }
                    showModalMessage(_msg);
                    SavePhoneNumber(phoneNumber);
                }
            }
        };        
    
        return (
            <View>
                <View style={{height:35}}>
                    <CloseHeader
                        closeAction={CloseButton}
                    /> 
                </View>
                <View >
                    <View style={{marginLeft:20}}>
                        <Text style={GSS.mainDefaultText}>휴대폰 번호:</Text>
                    </View>
                    <View style={[GSS.textInputContainer, {borderWidth:1, height:50}]}>
                        <TextInput
                            ref={textInputRef} 
                            style={GSS.textInputText}
                            placeholder="휴대폰 번호를 입력하세요"
                            value={phoneNumber}
                            onChangeText={handlePhoneNumberChange}
                            keyboardType="phone-pad"
                        />
                    </View>
                </View>
                <View style={{alignItems: 'center', justifyContent: 'center', marginTop:50}}>
                    <ProgressButton
                        title = '확인'
                        onPress={handleSubmit} 
                        style={{alignItems:'center'}}
                    />
                </View>
            </View>   
        );
    }; 

    const RegisterPhoneNumber = () => {
        const id = Date.now().toString()
        openModal(
            <InputPhoneNumber
                phone = {phoneNumber}
                modalID={id}
            /> ,
            0.4,    // heightPercentage, 필요한 경우 다른 값으로 설정
            0.80,   // widthPercentage
            'slide', // animation, 필요한 경우 다른 값으로 설정
            {},      // style, 필요한 경우 스타일 객체로 설정
            true,    // centered, 모달을 중앙에 배치하려면 true로 설정    
            id        
        );          
    };

    const blindPhoneNumber = (phoneNumber) => {
        // 전화번호 형식 검증 및 포매팅 (예: 010-1234-5678)
        const regex = /^(\d{3})-(\d{4})-(\d{4})$/;
        const match = phoneNumber.match(regex);
    
        if (match) {
            // 첫 번째 그룹(지역번호)은 그대로 두고, 나머지 숫자를 *로 대체
            return `${match[1]}-${match[2].substring(0, 2)}**-${match[3].substring(0, 2)}**`;
        } else {
            // 형식에 맞지 않는 경우, 원본 번호 반환
            return phoneNumber;
        }
    }

    async function _deletePhoneNumber () {
        const ret = await DeletePhoneNumberOfDB(ENV.user_id);
        if(ret.success) {
            setPhoneNumber(null);
            // const _message = {
            //     body : '휴대폰 정보가 삭제되었습니다'
            // }
        }
    }

    const DeletePhoneNumber = async () => {
        const _confirm = {
            body : '매칭 정보등 마이샷에서 유용한 정보를 문자로 받을 수 없습니다. 그래도 휴대폰 번호를 삭제 하시겠습니까?',
            afterOK : _deletePhoneNumber
        }
        confirmModal(_confirm);
    }

    return (
        <View style={GSS.mainContainer}>
            <View style={{height:50}}>
                <CloseHeader
                    closeAction={CloseButton}
                />
            </View>
            <View style={[GSS.mainViewBox, {flex:1}]}>
                { phoneNumber ? (
                    <View>
                        <Text style={[GSS.mainInfoTextB, {marginBottom:15}]}>{blindPhoneNumber(phoneNumber)}</Text>  

                        <TouchableOpacity 
                            onPress={() => RegisterPhoneNumber(phoneNumber)}
                            style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                        >
                            <Text style={[GSS.mainInfoTextB]}>휴대폰 번호 변경</Text>
                            <Image
                                source={nextImage}
                                style={{width:25, height:25}}
                            />            
                        </TouchableOpacity> 
                        <View style={{height:10}}></View>
                        <TouchableOpacity 
                            onPress={() => DeletePhoneNumber()}
                            style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                        >
                            <Text style={[GSS.mainInfoTextB]}>휴대폰 번호 삭제</Text>
                            <Image
                                source={nextImage}
                                style={{width:25, height:25}}
                            />            
                        </TouchableOpacity>                                                                  
                    </View>
                ) : (
                    <View>
                        <Text style={[GSS.mainSmallText, {width:300}]}>매칭 정보등 중요한 정보를 휴대폰으로도 받을 수 있습니다</Text>
                        <TouchableOpacity 
                            onPress={() => RegisterPhoneNumber()}
                            style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                        >
                            <Text style={[GSS.mainInfoTextB]}>휴대폰 번호 등록</Text>
                            <Image
                                source={nextImage}
                                style={{width:25, height:25}}
                            />            
                        </TouchableOpacity>
                    </View>
                )}
            </View>
            <View style={[GSS.mainViewBox, {flex:1}]}>
                <TouchableOpacity onPress={() => DeleteID()}>
                    <Text style={GSS.textEmphasisAlert}>마이샷 회원탈퇴</Text>
                </TouchableOpacity>
                {isLoading && (
                    <View style={GSS.activityIndicatorContainer}>
                        <ActivityIndicator size='large' color="#0000ff" />
                    </View>
                )}                   
            </View>
        </View>
    );
};

export default AdminAccount;    