import React, {useState, useEffect} from 'react';
import { Text, View, TouchableOpacity, Image, ScrollView } from 'react-native';
import GSS from '../GlobalStyleSheet2';
import CloseHeader from '../CloseHeader';
import prevImage from '../../assets/prev.png';
import nextImage from '../../assets/next.png';

const MessageViewer = ({modalID, posts, closeModal}) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedPost, setSelectedPost] = useState(null);
  
    const postsPerPage = 5;
    const indexOfLastPost = (currentPage + 1) * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost)
    // 현재 페이지가 첫 페이지인지 확인
    const isFirstPage = currentPage === 0;
    // 현재 페이지가 마지막 페이지인지 확인
    const isLastPage = currentPage >= Math.ceil(posts.length / postsPerPage) - 1;


    // 이전 페이지로 이동
    const goToPreviousPage = () => {
      if (currentPage > 0) {
        setCurrentPage(currentPage - 1);
      }
    };
  
    // 다음 페이지로 이동
    const goToNextPage = () => {
      if (currentPage < Math.ceil(posts.length / postsPerPage) - 1) {
        setCurrentPage(currentPage + 1);
      }
    };  

    const CloseButton = () => {
        closeModal(modalID);
    };   

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
    
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };
       
    
    const renderPost = (post, index) => {
        // 적절한 고유한 키 값 생성
        const key = post.id || `post-${index}`;
        const formattedDate = formatDate(post.create_at);
        return (
          <View style={{borderWidth:1, margin:5, borderRadius:5, borderColor : '#8F8C83',}} key={key}>
            <Text style={[GSS.mainDefaultText, {marginLeft:5, marginBottom:5}]}>{formattedDate}</Text>
            { post.from_nick_name && (
                <Text style={[GSS.mainDefaultText, {marginLeft:5}]}>From : {post.from_nick_name}
                {post.is_host_message === 1 ? ' (파티장)' : ''}</Text>
            )}
            <Text style={[GSS.mainDefaultTextB, {margin:5}]}>{post.message}</Text>
          </View>
        );
    };    

    return (
        <ScrollView>
            <View style={GSS.mainContainer}>
                <View style={{height:30}}>
                    <CloseHeader
                        closeAction={CloseButton}
                    /> 
                </View>
                <View style={[GSS.emptyViewBox, {flexDirection:'row', paddingHorizontal: 20, justifyContent: 'space-between', alignItems: 'center'}]}>
                    <TouchableOpacity onPress={goToPreviousPage} disabled={isFirstPage}>
                        <View style={{flexDirection:'row'}}>
                            <Image
                                source={prevImage}
                                style={{height:20, width:20}}
                            />
                            <Text style={[GSS.mainDefaultText, isFirstPage && GSS.mainDisableText ]}>{' 이전'}</Text>
                    </View>                   
                    </TouchableOpacity>
                    <TouchableOpacity onPress={goToNextPage}  disabled={isLastPage}>
                        <View style={{flexDirection:'row'}}>
                            <Text style={[GSS.mainDefaultText, isLastPage &&  GSS.mainDisableText]}>{'다음 '}</Text>
                            <Image
                                source={nextImage}
                                style={{height:20, width:20}}
                            />                            
                    </View>                   
                    </TouchableOpacity>
                </View>               
                <View style={[GSS.mainViewBox, {flex:1}]}>
                    {currentPosts.map(renderPost)}
                </View>

            </View>
        </ScrollView>
    );
}

export default MessageViewer;