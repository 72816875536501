import React, {useState, useEffect } from 'react';
import { Text, View, Image, TouchableOpacity, StyleSheet, TextInput, Alert} from 'react-native';
import GSS from '../GlobalStyleSheet2';
import { useEnv } from '../AppContext';
import TopHeader from '../TopHeader';
import { ProgressNext } from '../ProgressButton';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { HorizontalLine } from '../Global';

function RegisterCard ({navigation, route}) {
    const { ENV, setEnv } = useEnv();
    const [checkCompleted, setCheckCompleted] = useState(false);
    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [ssn, setSSN] = useState('');
    const [password, setPassword] = useState('');

    // 모든 입력이 완료되었는지 여부
    const [allCompleted, setAllCompleted] = useState(false);

    useEffect(() => {
        // 모든 필드의 유효성 검사
        const isCardNumberValid = cardNumber.length === 19; // 0000-0000-0000-0000
        const isExpiryDateValid = expiryDate.length === 7;  // MM / YY
        const isSSNValid = ssn.length === 8;               // 123456-7
        const isPasswordValid = password.length === 2;     // 2자리 숫자

        setAllCompleted(isCardNumberValid && isExpiryDateValid && isSSNValid && isPasswordValid);
    }, [cardNumber, expiryDate, ssn, password]);    

    async function SaveCard()
    {
        try {
            // const ret = await SaveBankAccountToDB(ENV.user_id, backAccount.id, accountNumber);
            // if(ret.success) {
            //     const bankUpdated = `${backAccount.id}|||${accountNumber}`;
            //     navigation.navigate('MyAccount', {bankUpdated});
            // }
        } catch (e) {
            console.log('RegisterCard.SaveCard', e);
        }
    }

    const NextButton = () => {
        return (
          <ProgressNext
            title={'카드등록'}
            onPress={ allCompleted ? SaveCard : null}
            disabled={ allCompleted ? false : true }
          />
        );
    }  
    
    const handleCardNumberInput = (text) => {
        // 숫자만 입력받기
        const newText = text.replace(/\D/g, '');
    
        // 카드 번호 형식에 맞게 "-" 추가
        let formattedText = '';
        for(let i = 0; i < newText.length; i++) {
          if (i > 0 && i % 4 === 0 && i < 16) {
            formattedText += '-';
          }
          formattedText += newText[i];
        }
    
        setCardNumber(formattedText);
    };

    const validateDate = (month, year) => {
        const currentYear = new Date().getFullYear() % 100; // 현재 년도의 마지막 두 자리
        const currentMonth = new Date().getMonth() + 1; // 현재 월 (1 ~ 12)
        
        return month >= 1 && month <= 12 && (year > currentYear || (year === currentYear && month >= currentMonth));
      };
    
    const handleExpiryDateInput = (text) => {
        const newText = text.replace(/\D/g, '');

        if (newText.length <= 4) {
            let formattedText = newText;

            if (newText.length >= 3) {
            const month = parseInt(newText.substring(0, 2), 10);
            const year = parseInt(newText.substring(2, 4), 10);

            if (newText.length === 4 && !validateDate(month, year)) {
                Alert.alert('유효하지 않은 날짜', '올바른 유효기간을 입력해주세요.');
                return;
            }

            formattedText = newText.substring(0, 2) + ' / ' + newText.substring(2, 4);
            }

            setExpiryDate(formattedText);
        }
    };

    const handleSSNInput = (text) => {
        // 숫자만 입력받기
        const newText = text.replace(/\D/g, '');
    
        // 6번째 숫자 이후에 자동으로 '-' 추가
        let formattedText = newText;
        if (newText.length >= 7) {
          formattedText = newText.substring(0, 6) + '-' + newText[6];
        }
    
        setSSN(formattedText);
    };

    const handlePasswordInput = (text) => {
        // 숫자만 입력받기
        const newText = text.replace(/\D/g, '');
    
        // 최대 2자리까지만 입력받기
        if (newText.length <= 2) {
          setPassword(newText);
        }
    };

    return (
        <KeyboardAwareScrollView
            style={{ flex: 1 }}
            resetScrollToCoords={{ x: 0, y: 0 }}  // 필요한 경우 스크롤 위치 조정
            contentContainerStyle={{ flexGrow: 1 }}  // 컨텐츠 스타일 조정
            scrollEnabled={true}  // 필요에 따라 스크롤 비활성화
        >  
            <View style={GSS.mainContainer}>
                <View style={{height:50}}>
                    <TopHeader 
                        navigation={navigation}
                    >결제정보 변경</TopHeader>
                </View>
                <View style={GSS.emptyViewBox}>
                    <Text style={GSS.mainInfoTextB}>카드 정보를 입력해 주세요</Text>
                </View>
                <View style={[GSS.mainViewBox, {flex:1}]}>
                    <View style={{marginLeft:8}}>
                        <Text style={GSS.mainDefaultText}>카드번호</Text>
                    </View>
                    <View style={GSS.textInputContainer}>
                        <TextInput 
                            style={GSS.textInputText}
                            value={cardNumber}
                            onChangeText={handleCardNumberInput}
                            keyboardType="numeric"
                            placeholder="0000-0000-0000-0000"
                            maxLength={19} // 16개 숫자 + 3개 구분기호
                        />                       
                    </View>
                    <HorizontalLine
                        color='#CFCCC3'
                    />
                    <View style={{marginLeft:8}}>
                        <Text style={GSS.mainDefaultText}>유효기간</Text>
                    </View>
                    <View style={GSS.textInputContainer}>
                        <TextInput 
                            style={GSS.textInputText}
                            value={expiryDate}
                            onChangeText={handleExpiryDateInput}
                            keyboardType="numeric"
                            placeholder="MM / YY"
                            maxLength={7} // 4개 숫자 + 3개 구분기호
                        />                      
                    </View>
                    <HorizontalLine
                        color='#CFCCC3'
                    />
                    <View style={{marginLeft:8}}>
                        <Text style={GSS.mainDefaultText}>생년월일</Text>
                    </View>
                    <View style={GSS.textInputContainer}>
                        <TextInput 
                            style={GSS.textInputText}
                            value={ssn}
                            onChangeText={handleSSNInput}
                            keyboardType="numeric"
                            placeholder="123456-1••••••"
                            maxLength={8} // 13개 숫자 + 1개 구분기호
                        />                     
                    </View>
                    <HorizontalLine
                        color='#CFCCC3'
                    />
                    <View style={{marginLeft:8}}>
                        <Text style={GSS.mainDefaultText}>비밀번호(앞두자리)</Text>
                    </View>
                    <View style={GSS.textInputContainer}>
                        <TextInput 
                            style={GSS.textInputText}
                            value={password}
                            onChangeText={handlePasswordInput}
                            keyboardType="numeric" // 숫자 키보드를 열기 위함
                            placeholder="••"
                            secureTextEntry={true} // 비밀번호를 숨기기 위함
                            maxLength={2} // 최대 2자리
                        />                   
                    </View>
                </View>
                <View style={[GSS.mainViewBox, {margin: 10, alignItems:'center', justifyContent: 'center'}]}>
                    <NextButton/>
                </View>            
            </View>
        </KeyboardAwareScrollView>     
    );
};

export default RegisterCard;