import React, {useEffect, useState } from 'react';
import { Text, View, Image} from 'react-native';
import { useItemContext } from '../ItemContext';
import { useNavigation, CommonActions  } from '@react-navigation/native';
import GSS from '../GlobalStyleSheet2';
import ProgressButton from '../ProgressButton';

function ShareEnd ({navigation, route}) {
    const {Item, ResetItem} = useItemContext();
    const [itemName, setItemName] = useState();
    const match_completed = route.params?.match_completed;
    const isNewNetflix = route.params?.isNewNetflix;

    async function NextStep()
    {
      navigation.reset({
        index: 0,
        routes: [{ name: 'home' }],
      });  
    }   
    // const resetStack = () => {
    //     navigation.dispatch(
    //       CommonActions.reset({
    //         index: 1,
    //         routes: [
    //           { name: 'ShareHome' },  // 이 부분은 원하는 첫 번째 스크린으로 변경
    //           { name: 'ShareEnd' }, // 이 부분은 현재 스크린의 이름으로 변경
    //         ],
    //       })
    //     );
    // };    
    
    useEffect(() => {
        setItemName(Item.display_name);
        if( Item.item_id) {
            ResetItem();
        }
    }, []);     

    const mainViewBoxNoBorder = {
        ...GSS.mainViewBox,
        borderWidth: undefined, // 'borderWidth'를 제외합니다.
        backgroundColor: undefined
    };  
    
    const NextButton = () => {
        return (
          <ProgressButton
            title={'확인'}
            onPress={NextStep}
          />

        );
    }     

    const ShowStatus = () => {
      if(match_completed) {
        return (
          <View style={[mainViewBoxNoBorder]}>
          <Text style={{fontWeight: 'bold', fontSize: 22,margin: 10}}><Text style={GSS.textEmphasis}>{itemName}</Text> 파티 매칭이 완료되었습니다!</Text>
          { isNewNetflix === true ? (
            <Text style={[GSS.mainDefaultText, {margin: 10}]}>{itemName} 파티의 초대메일을 수락하시면 바로 이용하실 수 있습니다.</Text>
          ) : (
            <Text style={[GSS.mainDefaultText, {margin: 10}]}>{itemName} 파티의 아이디/비밀번호를 확인할 수 있습니다.</Text>
          )}
        </View>
        );
      } else {
        return (
          <View style={[mainViewBoxNoBorder]}>
            <Text style={{fontWeight: 'bold', fontSize: 22,margin: 10}}><Text style={GSS.textEmphasis}>{itemName}</Text> 파티 매칭이 시작되었습니다!</Text>
            <Text style={[GSS.mainDefaultText, {margin: 10}]}>자동매칭이 되면 카카오톡, 또는 문자로 자동연락이 갑니다.</Text>
        </View>
        );
      }
    }       

    return (
        <View style={GSS.mainContainer}>
            <ShowStatus/>
            <View style={[GSS.mainViewBox, {margin: 5, alignItems:'center', justifyContent:'center'}]}>
              <NextButton/>
            </View>   
        </View>    
    );
};

export default ShareEnd;