import { View, Image, StyleSheet, Pressable, Text  } from 'react-native';
import GSS from './GlobalStyleSheet2';
import prevImage from '../assets/prev2.png';

const TopHeader = ({navigation, children, style, titleImage=null, endImageLink=null}) => {
  return (
      <View style={[GSS.mainViewBox, { flexDirection:'row', padding:0, height:35, 
        backgroundColor : '#f8f8f8', justifyContent:'center', alignItems:'center'}, style]}>
        <Pressable
              activeOpacity={0.5}
              onPress={navigation.goBack}
              style={{justifyContent:'center', alignItems:'center'}}
        >
          <Image source={prevImage} 
            resizeMode='contain'
            style={{ width: 26, height: 26, alignItems: 'center'}}/>          
        </Pressable>
        <View style={{flexDirection:'row', flex:1, justifyContent:'center', alignItems: 'center',}}>
            {titleImage && (
              <Image
                source={titleImage} // 이미지 파일 경로
                style={{ width: 25, height: 25, marginRight: 5 }} // 이미지 스타일
                resizeMode="contain" // 또는 "stretch". 이미지가 할당된 공간 내에서 전체 높이를 채우도록 조정합니다.
                />                
            )}
          <Text style={{ fontWeight: 'bold', fontSize: 18, height:25, color:'#291B44' }}>{children}</Text>
        </View>
        { endImageLink !== null && (
          <Pressable
              activeOpacity={0.5}
              onPress={endImageLink.goLink}
              style={{flex:0.1, justifyContent:'center', alignItems:'center'}}
          >
            <Image
              source={endImageLink.image}
              style={{height:25, width:25}}
            />            
          </Pressable>       
        )}
    </View>  
  );
};

export default TopHeader;