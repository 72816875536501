import React, { useEffect, useState, useRef  } from 'react';
import { Text, View,  TouchableOpacity, StyleSheet } from 'react-native';
import { WebView } from 'react-native-webview';
import GSS from '../GlobalStyleSheet2.js'
import TopHeader from '../TopHeader';
import { GetEmail, SetEmailPassword } from '../api.js';
import * as Clipboard from 'expo-clipboard';
import { generateRandomPassword } from '../EmailAccount.js';
import { useModal } from '../ModalContext';

export default function CheckEmailContent({navigation, route}) {
    const { emailAccount } = route.params;
    const [email, setEmail] = useState();
    const webviewRef = useRef(null);
    const [canGoBack, setCanGoBack] = useState(false);
    const [canGoForward, setCanGoForward] = useState(false);    
    const [password, setPassword] = useState();
    const { confirmModal, showMessage } = useModal();

    const onNavigationStateChange = (navState) => {
        setCanGoBack(navState.canGoBack);
        setCanGoForward(navState.canGoForward);
    };

    const goBack = () => {
        webviewRef.current.goBack();
    };

    const goForward = () => {
        webviewRef.current.goForward();
    };    

    useEffect(() => {
        try {
            (async () => {
                const ret = await GetEmail(emailAccount.email_id);
                if( ret.success) {
                    //console.log(ret.data);
                    setEmail(ret.data);
                }
                refreshPassword();
            })();         
        } catch (error) {
            console.error(error.message);
        }
    }, []);        

    const copyToClipboard = async (text) => {
        await Clipboard.setStringAsync(text);
        //showToast('클립보드에 복사되었습니다.');
    };  

    const refreshPassword = () => {
        setPassword(generateRandomPassword());
    }

    const _completeProc = async () => {
        try {
            const ret = await SetEmailPassword(emailAccount.account_id, password);
            if(ret.success) {
                navigation.goBack();
            } else {
                showMessage('비밀번호 저장에 실패하였습니다')
            }
        } catch(error) {
            console.log(error);
        }
    }

    const completeProc = () => {
        const _confirem = {
            afterOK : _completeProc,
            body: `[${emailAccount.email}] 계정에 대한 비번으로 [${password}]를 설정하셨나요?`
        }
        confirmModal(_confirem);
    }

    return (
        <View style={GSS.mainContainer}>
            <TopHeader 
                navigation={navigation}
            >
                {emailAccount?.email}
            </TopHeader>             
            <View style={{flexDirection:'row'}}>
                <View style={{flex:1, borderWidth:0.25, alignItems:'center', justifyContent:'center'}}>
                    <TouchableOpacity onPress={goBack} disabled={!canGoBack}>
                        <Text style={[GSS.mainSmallText, {color: canGoBack ? '#291B44' : '#c4c4c4'}]}>{'<< 뒤로'}</Text>
                    </TouchableOpacity>
                </View>
                <View style={{flex:1, borderWidth:0.25, alignItems:'center', justifyContent:'center'}}>
                    <TouchableOpacity onPress={goForward} disabled={!canGoForward}>
                        <Text style={[GSS.mainSmallText, {color: canGoForward ? '#291B44' : '#c4c4c4'}]}>{'앞으로 >>'}</Text>
                    </TouchableOpacity>
                </View>
            </View>
            <WebView 
                ref={webviewRef}
                onNavigationStateChange={onNavigationStateChange}
                style={{flex:1}}
                //scalesPageToFit={false}
                source={{ html: email?.body }}
            />
            <View style={{flexDirection:'row', marginTop:5}}>
                <View style={[GSS.mainViewBox, {flex:1}]}>
                    <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                        <Text style={[GSS.mainDefaultText, {width:40}]}>계정</Text>
                        <Text style={[GSS.mainDefaultText, {flex:1}]}>{emailAccount?.email}</Text>
                        <TouchableOpacity onPress={() => copyToClipboard(emailAccount?.email)}>
                            <Text style={[GSS.mainDefaultText, {marginRight:10}]}>복사</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                        <Text style={[GSS.mainDefaultText, {width:40}]}>비번</Text>
                        <Text style={[GSS.mainInfoTextB, {flex:1}]}>{password}</Text>
                        <TouchableOpacity onPress={() => copyToClipboard(password)}>
                            <Text style={[GSS.mainDefaultText, {marginRight:10}]}>복사</Text>
                        </TouchableOpacity>
                    </View>                
                </View>
                <View style={{marginLeft:5, alignItems:'center', justifyContent:'center', flex:0.2, borderWidth:0.5, borderRadius:10}}>
                    <TouchableOpacity onPress={completeProc}>
                        <Text style={GSS.mainBigText}>완료</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
}    

const styles = StyleSheet.create({
    navigationContainer: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
  });