import React, { useEffect, useState, useRef  } from 'react';
import { Text, View,  TouchableOpacity, StyleSheet, Image, Platform } from 'react-native';
import { WebView } from 'react-native-webview';
import GSS from '../GlobalStyleSheet2.js'
import TopHeader from '../TopHeader';
import { GetNewEmail, SetSyncEmailID, GetEmailContentofDB } from '../api.js';
import { useModal } from '../ModalContext';
import { formatDate, truncateByWords, HorizontalLine, truncateString } from '../Global.js';
import nextImage from '../../assets/next.png';
import { ScrollView } from 'react-native-gesture-handler';
import refreshImage from '../../assets/refresh.png';

export default function MyshottEmail({navigation, route}) {
    const { used_item, emailAccount } = route.params;
    const [emailID, setEmailID] = useState();
    const [emails, setEmails] = useState([]);
    const webviewRef = useRef(null);
    const [canGoBack, setCanGoBack] = useState(false);
    const [canGoForward, setCanGoForward] = useState(false);    
    const { confirmModal, showMessage } = useModal();
    const [ source , setSource] = useState();
    const [selectedEmail, setSelectedEmail] = useState(null);

    ////////////////////////

    const fetchEmails = async () => {
        try {
            const ret = await GetEmailContentofDB(emailAccount.email, 3);
            if( ret.success) {
                //console.log(ret.data.length);
                setEmails(ret.data);
            } else {
                console.log('오류');
            }
    } catch (error) {
            console.error(error.message);
        }
    }

    useEffect(() => {
        fetchEmails();
    }, []);

    const emailList = (email, index) => {
        const formattedDate = formatDate(email.sent_at);
        const key = `${email.email_id}_${email.sent_at}`;
        //console.log(`key=${key}`);
        return (
            <React.Fragment key={key}>
                <View>
                    <TouchableOpacity 
                        onPress={() => emailContent(email)}
                        style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                        <Text style={selectedEmail===email.email_id ? GSS.mainDefaultTextB : GSS.mainDefaultText}>{truncateString(email.subject, 30)}</Text>
                        <Image
                            source={nextImage}
                            style={{width:25, height:25}}
                        />            
                    </TouchableOpacity>
                    <Text style={GSS.mainDefaultText}>{formattedDate}</Text>
                </View>
                <HorizontalLine thickness={0.2} verticalMargin={2}/>
            </React.Fragment>
        );
    }

    const emailContent = (email) => {
        setSelectedEmail(email.email_id);
        if( Platform.OS === 'web') {    
            setSource(email.body);
        } else {
            setSource({ html: email.body, webSecurityEnabled: false });
        }
    }

    return (
        <View style={GSS.mainContainer}>
            <TopHeader 
                navigation={navigation}
                endImageLink={{
                    image : refreshImage,
                    goLink : fetchEmails
                }}
            >
                {emailAccount?.email}
            </TopHeader>
            <View style={GSS.mainViewBox}>
                {emails.map(emailList)}
            </View>
            { Platform.OS !== 'web' && source && (
                <WebView 
                    ref={webviewRef}
                    style={{flex:1}}
                    //scalesPageToFit={false}
                    source={source}
                    originWhitelist={['*']}
                />           
            )}
            { Platform.OS === 'web' && source && (
                <ScrollView style={{flex:1}}>
                    <div dangerouslySetInnerHTML={{ __html: source }}></div> 
                </ScrollView>
            )}
        </View>
    );
}    

const styles = StyleSheet.create({
    navigationContainer: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
  });