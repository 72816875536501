import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler'; // Reanimated와 호환되는 ScrollView입니다.
import Animated, { useSharedValue, useAnimatedScrollHandler, withTiming, useAnimatedStyle, useDerivedValue } from 'react-native-reanimated';

const AutoScrollingView = ({ children, style, scrollSpeed = 3000 }) => {
  if (!children || children.length === 0) {
    return null;
  }

  const { width: screenWidth } = Dimensions.get('window');
  const scrollX = useSharedValue(0); // Reanimated shared value
  const isUserScrolling = useSharedValue(false); // 사용자 스크롤 감지를 위한 shared value
  const scrollIndex = useSharedValue(0); // 현재 스크롤 인덱스

  const handleScroll = useAnimatedScrollHandler({
    onScroll: (event) => {
      scrollX.value = event.contentOffset.x; // 현재 스크롤 위치 업데이트
    },
    onBeginDrag: (event) => {
      isUserScrolling.value = true; // 사용자가 스크롤하기 시작함
    },
    onEndDrag: (event) => {
      isUserScrolling.value = false; // 사용자 스크롤이 끝남
    },
  });

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: withTiming(-scrollX.value) }], // smooth transition for the scrolling
    };
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      // 사용자가 스크롤 중이 아닐 때만 자동 스크롤
      if (!isUserScrolling.value) {
        scrollIndex.value = (scrollIndex.value + 1) % children.length; // 다음 항목으로 이동
        scrollX.value = withTiming(screenWidth * scrollIndex.value); // 부드러운 애니메이션과 함께 스크롤
      }
    }, scrollSpeed);

    return () => clearInterval(intervalId); // 컴포넌트 unmount 시 타이머 제거
  }, []);

  return (
    <View style={[styles.container, style]}>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        onScroll={handleScroll.onScroll} 
        scrollEventThrottle={16}
        style={styles.scrollView}>
        <Animated.View style={[styles.animatedView, animatedStyle]}>
          {children}
        </Animated.View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollView: {
    flexDirection: 'row',
  },
  animatedView: {
    flexDirection: 'row',
  },
});

export default AutoScrollingView;