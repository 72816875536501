import React, { useEffect, useState, useRef  } from 'react';
import { Text, View,  TouchableOpacity, StyleSheet, Image } from 'react-native';
import GSS from '../GlobalStyleSheet2.js'
import TopHeader from '../TopHeader';
import { GetNewEmail, SetSyncEmailID, SetEmailPassword } from '../api.js';
import * as Clipboard from 'expo-clipboard';
import { generateRandomPassword } from '../EmailAccount.js';
import { useModal } from '../ModalContext';
import { showToast } from '../Global.js';
import LinkImage from '../../assets/share.png';

export default function ChangeAccountEmail({navigation, route}) {
    const { used_item, emailAccount } = route.params;
    const [emailID, setEmailID] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const { confirmModal, showMessage } = useModal();
    const [ curStep, setCurStep] = useState(1);
    const [ stepTitle, setStepTitle] = useState('완료');

    // 다음 버튼 처리에 대한 셋팅딩
    const [isActive, setIsActive] = useState(true);
    ////////////////////////

    useEffect(() => {
        setPassword(generateRandomPassword());
    }, []);

    const copyToClipboard = async (text) => {
        await Clipboard.setStringAsync(text);
        showMessage(`[${text}]가 클립보드에 복사되었습니다`);
    };  


    const _completeProc = async () => {
        try {
            const ret = await SetEmailPassword(emailAccount.account_id, password);
            if(ret.success) {
                showMessage('감사합니다. 비밀번호가 저장되었습니다');
                navigation.navigate('DisplayUsedItem', {used_item, item, accountPwd : password });                
            } else {
                showMessage('비밀번호 저장에 실패하였습니다')
            }
        } catch(error) {
            console.log(error);
        }
    }

    const completeProc = () => {
        const _confirem = {
            afterOK : _completeProc,
            body: `새 비번[${password}]으로 변경이 완료되었나요?`
        }
        confirmModal(_confirem);
    }

    function goUrl() {
        window.open('https://www.netflix.com/password', '_blank');
    }      

    return (
        <View style={GSS.mainContainer}>
            <TopHeader 
                navigation={navigation}
            >
                {emailAccount?.email}
            </TopHeader>             
            <View style={GSS.mainViewBox}>
                <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                    <Text style={[GSS.mainDefaultText, {width:80}]}>계정</Text>
                    <Text style={[GSS.mainDefaultText, {flex:1}]}>{emailAccount?.email}</Text>
                    <TouchableOpacity onPress={() => copyToClipboard(emailAccount?.email)}>
                        <Text style={[GSS.mainDefaultText, {marginRight:10}]}>복사</Text>
                    </TouchableOpacity>
                </View>
            </View>
            <View style={GSS.mainViewBox}>
                <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                    <Text style={[GSS.mainDefaultText, {width:80}]}>기존 비번</Text>
                    <Text style={[GSS.mainDefaultText, {flex:1}]}>{emailAccount?.pwd}</Text>
                    <TouchableOpacity onPress={() => copyToClipboard(emailAccount?.pwd)}>
                        <Text style={[GSS.mainDefaultText, {marginRight:10}]}>복사</Text>
                    </TouchableOpacity>
                </View>                
            </View>
            <View style={GSS.mainViewBox}>
                <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                    <Text style={[GSS.mainDefaultText, {width:80}]}>새 비번</Text>
                    <Text style={[GSS.mainDefaultText, {flex:1}]}>{password}</Text>
                    <TouchableOpacity onPress={() => copyToClipboard(password)}>
                        <Text style={[GSS.mainDefaultText, {marginRight:10}]}>복사</Text>
                    </TouchableOpacity>
                </View>                
            </View>
            <View style={GSS.mainViewBox}>
                <TouchableOpacity
                    onPress={goUrl}
                    style={{flexDirection:'row'}}
                >
                    <Text style={GSS.mainInfoText}>계정 비밀번호 변경하러 가기</Text>
                    <Image
                        source={LinkImage}
                        style={{ marginLeft:10, width: 20, height: 20 }}
                    />
                </TouchableOpacity>
                <Text style={GSS.mainSmallText}>★ 위 비번을 복사하시고 비밀번호 란에 붙여 넣기하세요. 비밀번호 변경 완료 후에 완료버튼을 클릭하세요</Text>
            </View>            
            <View style={{marginLeft:5, alignItems:'center', justifyContent:'center', borderWidth:0.5, borderRadius:10,
                backgroundColor: isActive ? '#017cf7' : 'gray'}}>
                <TouchableOpacity 
                    onPress={completeProc}
                >
                    <Text style={[GSS.mainBigText, {color:'#ffffff'}]}>{stepTitle}</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
}    

const styles = StyleSheet.create({
    navigationContainer: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
  });