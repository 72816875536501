import React, {useState, useEffect} from 'react';
import { StyleSheet, Text, View, Pressable, ActivityIndicator } from 'react-native';
import { useAuth, useEnv, OTTs} from '../AppContext';
import GSS from '../GlobalStyleSheet2';
import ProgressButton from '../ProgressButton';
import { CancelWaitingFromDB, ImmediatelyMatchingFromDB } from '../api';
import { useUserItem } from '../UserItemContext';
import { useModal } from '../ModalContext';

function MatchWaiting ({navigation, route}) {
    const { isLoggedIn  } = useAuth();
    const { ENV } = useEnv();
    const { waitingItem, item } = route.params;
    const { ReadUserItem } = useUserItem();
    const { confirmModal } = useModal();
    const [isLoading, setIsLoading] = useState(false);

    // useEffect(() => {
    //     console.log('MatchWaiting : ', waitingItem);
    //     console.log('MatchWaiting : ', item);
    // }, []);    

    async function _CancelMatching()
    {
        setIsLoading(true);
        try {
            await CancelWaitingFromDB(waitingItem.waiting_id);
            const result = await ReadUserItem(waitingItem.user_id);
            navigation.navigate('Home');
          } catch (error) {
            console.log('CancelMatching error : ', error);
          }
          setIsLoading(false);
    }

    async function CancelMatching()
    {
        const _confirm = {
            afterOK : _CancelMatching,
            body : '파티 매칭을 취소하시겠습니까?'
        }
        confirmModal(_confirm);
    }

    async function ImmediatelyMatching()
    {
        try {
            //console.log('Called ImmediatelyMatching()')
            const ret = await ImmediatelyMatchingFromDB(waitingItem.waiting_id);
            const ret2 = await ReadUserItem(waitingItem.user_id);
            //console.log(ret);
            navigation.navigate('Home');
        } catch (e) {
            console.log('ImmediatelyMatching error : ', e);
        }
    }    

    return (
        <View style={[GSS.mainContainer]}>
            <View style={[GSS.emptyViewBox, { flex: 1, marginVertical: 10, alignItems:'center', justifyContent:'center' }]}>
                <Text style={GSS.mainTitleText}><Text style={GSS.textEmphasis}>{item.display_name}</Text> 파티 매칭 중</Text>
                <Text style={[GSS.mainInfoText, {marginTop:15}]}>매칭이 완료되면 카톡 또는 문자로 알려드립니다</Text>
                <Pressable activeOpacity={0.5} onPress={() => CancelMatching()}>
                    <Text style={[GSS.mainInfoText, {marginTop:30, textDecorationLine: 'underline'}]}>매칭 대기 취소</Text>                    
                </Pressable>     
                {/* <Pressable activeOpacity={0.5} onPress={() => ImmediatelyMatching()}>
                    <Text style={[GSS.mainInfoText, {marginTop:30, textDecorationLine: 'underline'}]}>즉시 매칭</Text>                    
                </Pressable>                             */}
            </View>
            {isLoading && (
                <View style={GSS.activityIndicatorContainer}>
                    <ActivityIndicator size='large' color="#0000ff" />
                </View>
            )}         
            <View style={{flex:0.9, alignItems: 'center', justifyContent: 'center'}}>
                <ProgressButton
                    title = '확인'
                    onPress={navigation.goBack} 
                    style={{alignItems:'center'}}
                />
            </View>
        </View>
    );
};

export default MatchWaiting;