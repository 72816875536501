import React, {useState, useEffect} from 'react';
import { StyleSheet, Text, View, Pressable , TouchableOpacity, Image, Animated  } from 'react-native';
import userManagerImage from '../../assets/images/user_manager.png';
import userAltImage from '../../assets/images/users_alt.png';
import { useItemContext } from '../ItemContext';
import { useAuth, useEnv, OTTs} from '../AppContext';
import GSS from '../GlobalStyleSheet2';
import TopHeader from '../TopHeader';
import { ProgressNext } from '../ProgressButton';
import { useModal } from '../ModalContext';
import ConfirmImage from '../ConfirmImage';
import SelectFreeType from './SelectFreeType';

function JoinShare ({navigation, route}) {
  const [isManager, setCheckManager] = useState(false);
  const { isLoggedIn, login, logout } = useAuth();
  const {Item, setItem} = useItemContext();
  const { openModal, closeModal } = useModal();
  const [modalID, setModalID] = useState();

  const freeSelect = (selectedData) => {
    //console.log(selectedData);
    if(selectedData === false) {
      const newItem = { ...Item };
      newItem.free_charge = true;
      setItem(newItem);
    }
    if(Item.item_id === 1)  // 넷플릭스인 경우
      navigation.navigate('NetShare1');
    else
      navigation.navigate('ManagerShare1');
  }

  const SelectFree = () => {
    const id = openModal(
      <SelectFreeType
        modalID={modalID}
        onSelect={freeSelect}
        closeModal={closeModal}
      /> ,
      0.8,    // heightPercentage, 필요한 경우 다른 값으로 설정
      0.90,   // widthPercentage
      'slide', // animation, 필요한 경우 다른 값으로 설정
      {},      // style, 필요한 경우 스타일 객체로 설정
      //true     // centered, 모달을 중앙에 배치하려면 true로 설정                
    );
    setModalID(id);
  }

  function NextStep()
  {
    Item.check_manager = isManager;
    if(isManager) {
      SelectFree();
    } else {
      if(Item.item_id === 1) {
        const newItem = { ...Item };
        newItem.check_new_using = true;
        setItem(newItem);
      }
      navigation.navigate('Share1');
    }
  }

  // 애니메이션 값 추가
  const scaleAnim = useState(new Animated.Value(1))[0]; // 기본값으로 1을 설정합니다.

  useEffect(() => {
    // 상태가 바뀔 때마다 애니메이션을 초기 상태로 되돌립니다.
    scaleAnim.setValue(0.8); // 애니메이션의 초기값을 줄입니다 (조금 축소됨). 
    Animated.spring(
      scaleAnim, 
      {
        toValue: 1, // 최종 값. 이 경우 원래 크기인 1로 돌아갑니다.
        tension: 150, 
        friction: 5,
        useNativeDriver: true, // 이 옵션을 사용하면 애니메이션 성능이 향상됩니다.
      }
    ).start();  

    }, [isManager, scaleAnim]); // isManager가 변경될 때마다 useEffect 실행

  const renderContent = () => {
    const commonProps = {
      style: [GSS.mainViewBox, { flex: 4, marginVertical: 10 }],
    };
    const paragraphs = isManager
      ? [
          " 수수료 50% 더 저렴하게 이용",
          " 지금 바로 시청 가능",
          " 공유원의 이용료를 정산받습니다",
          " 지인들에게 무료로 공유할 수도 있습니다",
        ]
      : [
          " 공유 계정을 부담없이 이용",
          " 자동으로 매칭",
          " 해지해도 남은 기간만큼 환불",
        ];
  
      return (
        <Animated.View
          style={[GSS.mainViewBox, { flex: 2, marginVertical: 10, transform: [{scale: scaleAnim}] }]}
        >
          {paragraphs.map((text, index) => (
            <View  key={index} style={{flexDirection:'row', marginBottom:10}}>
              <ConfirmImage isActive={true}/><Text style={[styles.paragraph]}>{text}</Text>
            </View>
          ))}
          <View style={styles.marginTop}>
            <ProgressNext
                title={isManager ? "파티장으로 진행" : "파티원으로 진행"}
                onPress={NextStep}
                width={200}
            />    
          </View>
        </Animated.View>    
      );
  };

  return (
    <View style={GSS.mainContainer}>
      <TopHeader 
        titleImage={OTTs[Item.ott_id].iconImage}
        navigation={navigation}
      >
        {Item.display_name} 공유 역할을 선택
      </TopHeader>
      <View style={ { flex: 1, marginVertical: 2, flexDirection: 'row' }}>
        <TouchableOpacity
          style={[GSS.mainItem, {borderWidth:1, borderColor:'#f1f1f1'},  isManager == true && {backgroundColor:'#291B44'} ]}
          onPress={() => setCheckManager(true)}
          accessibilityLabel="파티장 선택"  // 예시
        >
          <View style={{ backgroundColor:'#f8f8f8',  padding: 5, width:40, height:40, borderRadius:20}}>
              <Image source={userManagerImage} style={[GSS.mainItemImage, isManager == true && { borderRadius:15, backgroundColor:'#ffffff'}]} />
          </View>
          <View style={[GSS.labelContainer, {margin: 10, backgroundColor:'#00cdd0'}]}>
              <Text style={GSS.labelText}>파티장</Text>
          </View>
          <View >
              <Text style={isManager ? GSS.mianDefaultTextW : GSS.mainDefaultTextB}>내 계정을 공유</Text>
          </View>         
        </TouchableOpacity>
        <TouchableOpacity
          style={[GSS.mainItem, {borderWidth:1, borderColor:'#f1f1f1'},  isManager == false && {backgroundColor:'#291B44'}]}
          onPress={() => setCheckManager(false)}
          accessibilityLabel="파티원 선택"  // 예시
        >
          <View style={{borderWidth:0, backgroundColor:'#f8f8f8',  padding: 5, width:40, height:40, borderRadius:20}}>
              <Image source={userAltImage} style={[GSS.mainItemImage, isManager == false && { borderRadius:15, backgroundColor:'#ffffff'}]} />
          </View>
          <View style={[GSS.labelContainer, {margin: 10, backgroundColor:'#00cdd0'}]}>
              <Text style={GSS.labelText}>파티원</Text>
          </View>
          <View >
              <Text style={!isManager ? GSS.mianDefaultTextW : GSS.mainDefaultTextB}>공유받은 계정을 사용</Text>
          </View>
        </TouchableOpacity>
      </View>
      {renderContent()}
    </View>
  );
};

const styles = StyleSheet.create({
  paragraph: {
    fontSize: 16,
    marginBottom: 10,
    color: '#555',
    fontWeight: 'bold',
    // 여기에 필요한 스타일을 추가하시면 됩니다.
  },
  marginTop: {
    marginTop: 20,
    alignItems:'center'    
  },  
});

export default JoinShare;

