import React, {useState, useEffect, useRef} from 'react';
import { Image, Text, View, StyleSheet, Dimensions, TouchableOpacity, ActivityIndicator, Button } from 'react-native';
import ConfirmImage from '../ConfirmImage';
import GSS from '../GlobalStyleSheet2';
import { ProgressNext } from '../ProgressButton';
import CloseHeader from '../CloseHeader';
import { showToast, routerServer } from '../Global';
import { ScrollView } from 'react-native-gesture-handler';
import NetImage1 from '../../assets/images/netflix_match_1.png';
import NetImage2 from '../../assets/images/netflix_match_2.png';
import NetImage3 from '../../assets/images/netflix_match_3.png';
import * as WebBrowser from 'expo-web-browser';
import LinkImage from '../../assets/share.png';
import { useModal } from '../ModalContext';
import { NextflixMatchedToDB } from '../api';
import { useUserItem } from '../UserItemContext';

import * as Clipboard from 'expo-clipboard';
import { WebView } from 'react-native-webview';

function NetflixMatchedProc ({navigation, route}) {
    const { user_item, item, userAccounts } = route.params;
    const [iframeSrc, setIframeSrc] = useState('');
    const [currentUrl, setCurrentUrl] = useState('');

    // iframe 내 URL 변경 감지
    useEffect(() => {
        const handleLocationChange = (event) => {
            // iframe 내부의 URL을 가져오려면, postMessage 같은 방법을 사용해야 할 수도 있습니다.
            // 여기서는 단순화를 위해 상위 컴포넌트에서 변경된 URL을 감지합니다.
            console.log('URL : ', event.target.src);
            setCurrentUrl(event.target.src);
        };

        // iframe 요소 찾기
        const iframe = document.querySelector('iframe');
        iframe.addEventListener('load', handleLocationChange);

        return () => {
          iframe.removeEventListener('load', handleLocationChange);
        };
    }, []);  

    // // iframe URL 변경 함수
    // const changeIframeUrl = (newUrl) => {
    //     setIframeUrl(newUrl);
    // };    

    const fetchNewUrl = async () => {
      try {
        const urlToFetch = 'https://www.netflix.com/kr/';
        const encodedUrl = encodeURIComponent(urlToFetch);

        const response = await fetch(`${routerServer}/fetch-url?url=${encodedUrl}`);
        const result = await response.json();
        
        const blob = new Blob([result.content], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        setIframeSrc(url);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };    

    const windowWidth = Dimensions.get('window').width * 0.8;
    return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Button
          title="Load Example Content"
          onPress={() => fetchNewUrl()}
        />
        <iframe src={iframeSrc} style={{ width: '100%', height: '100%' }} />
      </View>
    );
};

const SS = StyleSheet.create({
    container: {
      // 컨테이너를 flex 컨테이너로 설정합니다.
      flex: 1,
      justifyContent: 'flex-end', // 자식 요소들을 컨테이너의 오른쪽으로 정렬합니다.
      alignItems: 'center', // 자식 요소들을 컨테이너의 중앙으로 정렬합니다(수직 방향).
      borderWidth:1,
      borderColor : '#8F8C83',
      margin:5,
      borderRadius:10,
      marginBottom : 15
    },
    confirmView: {
        //flex: 
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems : 'center',
        marginBottom : 10
    },
    textContainer: {
        alignItems:'center',
        padding:10, 
        borderWidth:1,
        borderRadius:5,
        borderColor: '#8F8C83'
    }
});

export default NetflixMatchedProc;