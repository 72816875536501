import { GestureHandlerRootView } from 'react-native-gesture-handler';
import React, {useState, useEffect} from 'react';
import { View, Text, Image, Platform, TouchableOpacity } from 'react-native';
import { NavigationContainer, useNavigation } from "@react-navigation/native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Home from './components/Home/Home.js';

import { AuthProvider, SystemInfoProvider, EnvProvider, useAuth } from './components/AppContext.js';
import ShareHome from './components/Share/ShareHome.js';
import JoinShare from './components/Share/JoinShare.js';
import Login from './components/Login.js';
import LoginKakao from './components/LoginKakao.js';
import MyInfoHome from './components/MyInfo/MyInfoHome.js';
import SplashScreen from './components/SplashScreen.js';
import { ItemContextProvider } from './components/ItemContext.js';
import ManagerShare1 from './components/Share/ManagerShare1.js';
import ManagerShare11 from './components/Share/ManagerShare11.js';
import ManagerShare12 from './components/Share/ManagerShare12.js';
import ManagerShare2 from './components/Share/ManagerShare2.js';
import ManagerShare3 from './components/Share/ManagerShare3.js';
import ManagerShareEnd from './components/Share/ManagerShareEnd.js';
import Share1 from './components/Share/Share1.js';
import { ModalProvider } from './components/ModalContext.js';
import Share2 from './components/Share/Share2.js';
import AlertMessageView from './components/AlertMessageView';
import * as Font from 'expo-font';
import { UserItemContextProvider } from './components/UserItemContext.js';
import ShareEnd from './components/Share/ShareEnd.js';
import MatchWaiting from './components/Home/MatchWaiting.js';
import MatchedProc1 from './components/Home/MatchedProc1.js';
import MatchedProc2 from './components/Home/MatchedProc2.js';
import DisplayUsedItem from './components/Home/DisplayUsedItem.js';
import DisplayUserItem from './components/Home/DisplayUserItem.js';
import SettingUsedItem from './components/Home/SettingUsedItem.js';
import SendUserMessage from './components/Home/SendUserMessage.js';
import UpdateProfileOfHost from './components/Home/UpdateProfileOfHost.js';
import SendHostMessage from './components/Home/SendHostMessage.js';
import SettingHostItem from './components/Home/SettingHostItem.js';
import ChangeOTTAccount from './components/Home/ChangeOTTAccount.js';
import LoginNaver from './components/LoginNaver.js';
import AdminQA from './components/MyInfo/AdminQA.js';
import AdminAccount from './components/MyInfo/AdminAccount.js';
import MyAccount from './components/MyInfo/MyAccount.js';
import ChangeBankAccount from './components/Pay/ChangeBankAccount.js';
import * as Linking from "expo-linking"
import LoginRedirect from './components/LoginRedirect.js';
import AdminPay from './components/MyInfo/AdminPay.js';
import RegisterCard from './components/Pay/RegisterCard.js';
import RegisterCardOfPayple from './components/Pay/RegisterCardOfPayple.js';
import CertifyID from './components/MyInfo/CertifyID.js';
import LoginApple from './components/LoginApple.js';
import NetShare1 from './components/Share/NetShare1.js';
import Share11 from './components/Share/Share11.js';
import NetflixMatchedProc from './components/Home/NetflixMatchedProc.js';
import LoginWeb from './components/LoginWeb.js';
import CheckEmailContent from './components/Home/CheckEmailContent.js';
import ChangeAccountEmail from './components/Home/ChangeAccountEmail.js';
import GlobalAppStatus from './components/GlobalAppStatus.js';
import NetflixMatchedProc_web from './components/Home/NetflixMatchedProc_web.js';
import ChangeMyshottAccount from './components/Home/ChangeMyshottAccount.js';
import ChangeMyshottPassword from './components/Home/ChangeMyshottPassword.js';
import ChangeAccountPwd from './components/Home/ChangeAccountPwd.js';
import MyshottEmail from './components/Home/MyshottEmail.js';
import NotifyError from './components/Home/NotifyError.js';
import InviteProc from './components/Home/InviteProc.js';
import LinkProc from './components/Home/LinkProc.js';

const Tab = createBottomTabNavigator();
const ShareStack = createNativeStackNavigator();
const MyInfoStack = createNativeStackNavigator();
const HomeStack = createNativeStackNavigator();

const navigationRef = React.createRef();
export function navigateToTab(tabName) {
  navigationRef.current?.navigate(tabName);
}

// Linking.getInitialURL().then((url) => {
//   if (url) {
//     console.log(url);
//   }
// });

const prefix = Linking.createURL("/");
const linking = {
  prefixes: [prefix,'http://localhost:19006', 'https://myshott.co.kr', 'http://my.chyh.kr', 'http://eva.iptime.org:19006'],
  config: {
    screens: {
      home: {
        initialRouteName: 'Home',
        screens: {
          Home : 'Home',
          LoginRedirect : 'Login/:user_id/:last_login_type',
          LinkProc : 'B/:link_code?/:user_id?',
          InviteProc : '406/:invite_code'                  
        }
      },
      Share: {
        initialRouteName: 'ShareHome',
        screens: {
          ShareHome : 'ShareHome',
        }
      },      
      MyInfo: {
        initialRouteName: 'MyInfoHome',
        screens: {
          MyInfoHome : 'MyInfoHome',
          RegisterCardOfPayple : 'register_card/:card_info',
          CertifyID : 'ok_std/:ok_info',
        }
      },      
    },
  },
}


const LoginScreens = [
  { name: 'Login', component: Login, options: { headerShown: false } },
  { name: 'LoginKakao', component: LoginKakao, options: { headerShown: false } },
  { name: 'LoginNaver', component: LoginNaver, options: { headerShown: false } },
  { name: 'LoginRedirect', component: LoginRedirect, options: { headerShown: false } },
  { name: 'LoginApple', component: LoginApple, options: { headerShown: false } },
  { name: 'LoginWeb', component: LoginWeb, options: { headerShown: false } },
  // 추가적인 공유 스크린들이 있다면 여기에 추가하세요.
];

const ShareStackNavigation = () => {
  return (
    <ShareStack.Navigator
      initialRouteName={'ShareHome'}

      screenOptions={{
        // headerLeft: (props) => <CustomBackButton {...props} />, // 모든 스크린에 적용되는 기본 옵션
        // headerStyle: {
        //   // 헤더 스타일을 조정합니다. 필요에 따라 여기에 다른 스타일링 옵션을 추가할 수 있습니다.
        //   backgroundColor: 'transparent', // 또는 헤더에 원하는 배경 색상 설정
        //   elevation: 0, // Android에서 그림자 제거
        //   shadowOpacity: 0, // iOS에서 그림자 제거          
        // },
        // headerTitleContainerStyle: {
        //   marginLeft: -15, // 제목과 버튼 사이의 간격을 조정합니다. 값은 프로젝트의 실제 레이아웃에 따라 다를 수 있습니다.
        // },
        // headerLeftContainerStyle: {
        //   marginLeft: 0, // 버튼을 왼쪽 가장자리에 붙입니다.
        //},        
        // ...   
        headerShown: false,
      }}
    >
      <ShareStack.Screen
        name={'ShareHome'}
        component={ShareHome}
        options={{
          headerShown: false,
        }}
      />
      <ShareStack.Screen
        name={'JoinShare'}
        component={JoinShare}
        options={{
          headerShown: false,
        }}       
      />
      <ShareStack.Screen
        name={'ManagerShare1'}
        component={ManagerShare1}
        options={{
          headerShown: false,
        }}       
      />
      <ShareStack.Screen
        name={'ManagerShare11'}
        component={ManagerShare11}
        options={{
          headerShown: false,
        }}       
      /> 
      <ShareStack.Screen
        name={'ManagerShare12'}
        component={ManagerShare12}
        options={{
          headerShown: false,
        }}       
      />            
      <ShareStack.Screen
        name={'ManagerShare2'}
        component={ManagerShare2}
        options={{
          headerShown: false,
        }}       
      />      
      <ShareStack.Screen
        name={'ManagerShare3'}
        component={ManagerShare3}
        options={{
          headerShown: false,
        }}       
      />
      <ShareStack.Screen
        name={'ManagerShareEnd'}
        component={ManagerShareEnd}
        options={{
          headerShown: false,
        }}    
      />               
      <ShareStack.Screen
        name={'Share1'}
        component={Share1}
        options={{
          headerShown: false,
        }}       
      />
      <ShareStack.Screen
        name={'Share2'}
        component={Share2}
        options={{
          headerShown: false,
        }}        
      />      
      <ShareStack.Screen
        name={'ShareEnd'}
        component={ShareEnd}
        options={{
          headerShown: false,
        }}        
      />
       <ShareStack.Screen
        name={'RegisterCardOfPayple'}
        component={RegisterCardOfPayple}
        options={{
          headerShown: false,
        }}
      />
      <ShareStack.Screen
        name={'ChangeBankAccount'}
        component={ChangeBankAccount}
        options={{
          headerShown: false,
        }}
      />
       <ShareStack.Screen
        name={'CertifyID'}
        component={CertifyID}
        options={{
          headerShown: false,
        }}
      />
       <ShareStack.Screen
        name={'NetShare1'}
        component={NetShare1}
        options={{
          headerShown: false,
        }}       
      />                       
       <ShareStack.Screen
        name={'Share11'}
        component={Share11}
        options={{
          headerShown: false,
        }}       
      />      
      {LoginScreens.map((screen) => (
        <ShareStack.Screen 
          key={screen.name}
          name={screen.name} 
          component={screen.component} 
          options={screen.options} 
        />
      ))}
    </ShareStack.Navigator>
  );
}

const MyInfoStackNavigation = () => {
  return (
    <MyInfoStack.Navigator
      initialRouteName={'MyInfoHome'}
      // headerShown={false}
    >
      <MyInfoStack.Screen
        name={'MyInfoHome'}
        component={MyInfoHome}
        options={{
          headerShown: false,
        }}
      />      
      {LoginScreens.map((screen) => (
        <MyInfoStack.Screen 
          key={screen.name}
          name={screen.name} 
          component={screen.component} 
          options={screen.options} 
        />
      ))}
      <MyInfoStack.Screen
        name={'AdminQA'}
        component={AdminQA}
        options={{
          headerShown: false,
        }}
      />   
      <MyInfoStack.Screen
        name={'AdminAccount'}
        component={AdminAccount}
        options={{
          headerShown: false,
        }}
      />   
      <MyInfoStack.Screen
        name={'MyAccount'}
        component={MyAccount}
        options={{
          headerShown: false,
        }}
      />     
      <MyInfoStack.Screen
        name={'ChangeBankAccount'}
        component={ChangeBankAccount}
        options={{
          headerShown: false,
        }}
      />    
      <MyInfoStack.Screen
        name={'AdminPay'}
        component={AdminPay}
        options={{
          headerShown: false,
        }}
      /> 
      <MyInfoStack.Screen
        name={'RegisterCard'}
        component={RegisterCard}
        options={{
          headerShown: false,
        }}
      />
       <MyInfoStack.Screen
        name={'RegisterCardOfPayple'}
        component={RegisterCardOfPayple}
        options={{
          headerShown: false,
        }}
      />  
       <MyInfoStack.Screen
        name={'CertifyID'}
        component={CertifyID}
        options={{
          headerShown: false,
        }}
      />
    </MyInfoStack.Navigator>
  );
}

const HomeStackNavigation = () => {
  return (
    <HomeStack.Navigator
      initialRouteName={'Home'}
      // headerShown={false}
    >
      <HomeStack.Screen
        name={'Home'}
        component={Home}
        options={{
          headerShown: false,
        }}
      />      
      <HomeStack.Screen
        name={'MatchWaiting'}
        component={MatchWaiting}
        options={{
          headerShown: false,
        }}
      />  
      <HomeStack.Screen
        name={'MatchedProc1'}
        component={MatchedProc1}
        options={{
          headerShown: false,
        }}
      />          
      <HomeStack.Screen
        name={'MatchedProc2'}
        component={MatchedProc2}
        options={{
          headerShown: false,
        }}
      />     
      <HomeStack.Screen
        name={'DisplayUsedItem'}
        component={DisplayUsedItem}
        options={{
          headerShown: false,
        }}
      />           
      <HomeStack.Screen
        name={'DisplayUserItem'}
        component={DisplayUserItem}
        options={{
          headerShown: false,
        }}
      />      
      <HomeStack.Screen
        name={'SettingUsedItem'}
        component={SettingUsedItem}
        options={{
          headerShown: false,
        }}
      />   
      <HomeStack.Screen
        name={'SendUserMessage'}
        component={SendUserMessage}
        options={{
          headerShown: false,
        }}
      />       
      <HomeStack.Screen
        name={'UpdateProfileOfHost'}
        component={UpdateProfileOfHost}
        options={{
          headerShown: false,
        }}
      />  
      <HomeStack.Screen
        name={'SendHostMessage'}
        component={SendHostMessage}
        options={{
          headerShown: false,
        }}
      />     
      <HomeStack.Screen
        name={'SettingHostItem'}
        component={SettingHostItem}
        options={{
          headerShown: false,
        }}
      />      
      <HomeStack.Screen
        name={'ChangeOTTAccount'}
        component={ChangeOTTAccount}
        options={{
          headerShown: false,
        }}
      />  
      <HomeStack.Screen
        name={'ChangeBankAccount'}
        component={ChangeBankAccount}
        options={{
          headerShown: false,
        }}
      />      
      <HomeStack.Screen
        name={'NetflixMatchedProc'}
        component={NetflixMatchedProc}
        options={{
          headerShown: false,
        }}
      />   
      <HomeStack.Screen
        name={'CheckEmailContent'}
        component={CheckEmailContent}
        options={{
          headerShown: false,
        }}
      />
      <HomeStack.Screen
        name={'ChangeAccountEmail'}
        component={ChangeAccountEmail}
        options={{
          headerShown: false,
        }}
      />
      <HomeStack.Screen
        name={'NetflixMatchedProc_web'}
        component={NetflixMatchedProc_web}
        options={{
          headerShown: false,
        }}
      />
      <HomeStack.Screen
        name={'ChangeMyshottAccount'}
        component={ChangeMyshottAccount}
        options={{
          headerShown: false,
        }}
      />     
      <HomeStack.Screen
        name={'ChangeMyshottPassword'}
        component={ChangeMyshottPassword}
        options={{
          headerShown: false,
        }}
      />
      <HomeStack.Screen
        name={'ChangeAccountPwd'}
        component={ChangeAccountPwd}
        options={{
          headerShown: false,
        }}
      />
      <HomeStack.Screen
        name={'MyshottEmail'}
        component={MyshottEmail}
        options={{
          headerShown: false,
        }}
      />
      <HomeStack.Screen
        name={'NotifyError'}
        component={NotifyError}
        options={{
          headerShown: false,
        }}
      /> 
       <HomeStack.Screen
        name={'LinkProc'}
        component={LinkProc}
        options={{
          headerShown: false,
        }}       
      />  
      <HomeStack.Screen
        name={'InviteProc'}
        component={InviteProc}
        options={{
          headerShown: false,
        }}
      />                     
      {LoginScreens.map((screen) => (
        <HomeStack.Screen 
          key={screen.name}
          name={screen.name} 
          component={screen.component} 
          options={screen.options} 
        />
      ))}
    </HomeStack.Navigator>
  );
}


function RootNavigator() {
 
  function MyTabBar({ state, descriptors, navigation }) {
    return (
      <View style={ {flexDirection: 'row', backgroundColor: '#FFFFFF', borderWidth:1, borderColor: '#e3e3e3'}}>
        {state.routes.map((route, index) => {
          const { options } = descriptors[route.key];

          if(options.tabBarStyle?.display == 'none') {
            return null;
          }
  
          if (route.name === 'SplashScreen') {
            return null; // SplashScreen 탭을 렌더링하지 않음
          }
  
          const label =
            options.tabBarLabel !== undefined
              ? options.tabBarLabel
              : options.title !== undefined
              ? options.title
              : route.name;
  
          const isFocused = state.index === index;
  
          const onPress = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            });
  
            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate({ name: route.name, merge: true });
            }
          };
  
          const onLongPress = () => {
            navigation.emit({
              type: 'tabLongPress',
              target: route.key,
            });
          };
  
          // Define the image source and style
          const tabIcon =
          route.name === 'home'
            ? require('./assets/images/home.png')
            : route.name === 'Share'
            ? require('./assets/images/add-group.png')
            : route.name === 'MyInfo'
            ? require('./assets/images/user.png')
            : null; // Add more conditions as needed
          //console.log(tabIcon);
                    
          return (
            <TouchableOpacity
              accessibilityRole="button"
              accessibilityState={isFocused ? { selected: true } : {}}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              testID={options.tabBarTestID}
              onPress={onPress}
              onLongPress={onLongPress}
              style={{ flex: 1, alignItems: 'center', marginBottom:-2,marginTop:2 }}
              key={route.key} // 각 탭에 고유한 key 추가
            >
              {tabIcon && (
                <Image
                  source={tabIcon}
                  style={{ width:Platform.OS === 'android' ? 18 : 24, height: Platform.OS === 'android' ? 18 : 24, tintColor: isFocused ? '#4876D6' : '#222' }}
                />
              )}
              <Text style={{ color: isFocused ? '#4876D6' : '#222'}}>
                {label}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  }

  return (
    <NavigationContainer ref={navigationRef} linking={linking}  
      onStateChange={(state) => {
        // 네비게이션 상태가 변경될 때마다 실행됩니다.
        const routeName = state.routes[state.index]?.name;
        // 스크린 이름에 따라 document.title 변경
        switch (routeName) {
          case 'home':
            document.title = '마이샷 - 홈';
            break;
          case 'Share':
            document.title = '마이샷 - 공유';
            break;
          case 'MyInfo':
            document.title = '마이샷 - 내정보';
            break;           
          default:
            document.title = '마이샷';
            break;
        }
      }}
      fallback={
        <View style={{flex:1, alignItems:'center', justifyContent:'center'}}>
          <Text>Loading...</Text>
        </View>
      }>
      <Tab.Navigator 
          screenOptions={({ route }) => ({
            headerShown: false,
            //tabBarStyle: {display: 'none'}, 
          })}
          tabBar={props => 
            <MyTabBar {...props} />
          }
        >
        <Tab.Screen name="SplashScreen" component={SplashScreen} 
            options={{ headerShown: false }}
        />     
        <Tab.Screen name="home" component={HomeStackNavigation} 
          options={{
            title: "홈",
          }}
          listeners={({ navigation, route }) => ({
            tabPress: e => {
              navigation.navigate('home');
            },
          })}           
        />
        <Tab.Screen name="Share" component={ShareStackNavigation} 
          options={{
            title: "공유",
          }}  
          listeners={({ navigation, route }) => ({
            tabPress: e => {
              navigation.navigate('Share');
            },
          })}               
        />
        <Tab.Screen name="MyInfo" component={MyInfoStackNavigation} 
          options={{
            title: "내정보",
          }}
          listeners={({ navigation, route }) => ({
            tabPress: e => {
              // 탭 클릭 시 항상 Home 스택의 초기 스크린으로 이동
              navigation.navigate('MyInfo');
            },
          })}              
        />        
      </Tab.Navigator>
    </NavigationContainer>
  );
}

export default function App() {
  const [fontLoaded, setFontLoaded] = useState(false);
 
  // 폰트 로드 함수
  async function loadFonts() {
    await Font.loadAsync({
      'BlackHanSans': require('./assets/fonts/BlackHanSans-Regular.ttf'), // 경로를 자신의 폰트 파일 경로로 변경하세요.
      'Gaegu': require('./assets/fonts/Gaegu-Bold.ttf'),
      'Audiowide': require('./assets/fonts/Audiowide-Regular.ttf'),
   });
  }

  useEffect(() => {
    async function loadAppResources() {
      await loadFonts();
      setFontLoaded(true);
    }
  
    loadAppResources();
  }, []);

  if (!fontLoaded) {
    return null;
  }

  return (
    <GestureHandlerRootView style={{ flex: 1 }}> 
      <SystemInfoProvider>
        <AuthProvider>
          <EnvProvider>
            <ItemContextProvider>
              <UserItemContextProvider>
                <ModalProvider>
                  <GlobalAppStatus/>
                  <View style={{flex: 1, justifyContent: 'center', alignItems: 'center',}}>
                    <View style={{maxWidth: '800px', width: '100%', flex:1, }}>
                      <AlertMessageView/>
                      <View style={{flex:1, backgroundColor: 'lightgray'}} >
                        <RootNavigator />
                      </View>
                    </View>
                  </View>     
                </ModalProvider>
              </UserItemContextProvider>
            </ItemContextProvider>
          </EnvProvider>
        </AuthProvider>
      </SystemInfoProvider>
    </GestureHandlerRootView>
  );
}
