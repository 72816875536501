import React, {useState, useEffect} from 'react';
import { StyleSheet, Text, View, Pressable , TouchableOpacity, Image, Animated  } from 'react-native';
import GSS from '../GlobalStyleSheet2';
import CloseHeader from '../CloseHeader';
import ConfirmImage from '../ConfirmImage';
import ProgressButton from '../ProgressButton';

const SelectFreeType = ({modalID, onSelect, closeModal}) => {
    const [isFreeParty, setIsFreeParty] = useState(true);

    const NextStep = () => {
        onSelect(isFreeParty);
        closeModal(modalID);
    };         

    const CloseButton = () => {
        closeModal(modalID);
    };     

    const renderContent = () => {
        const commonProps = {
          style: [GSS.mainViewBox, { flex: 1, marginVertical: 10 }],
        };
        const paragraphs = isFreeParty
        ? [
            " 일반회원은 공유 이용료를 지급합니다",
            " 일반회원 모집은 자동매칭으로 이루어집니다 ",
        ] : [
            " 공유 이용료를 지급 받지 않습니다",
            " 무료회원은 어떠한 비용도 발생하지 않습니다",
            " 지인들에게 파티의 자리를 제공하세요",
            " 파티가 개설된 후 파티 초대 URL을 보내실 수 있습니다",
        ];
      
        return (
            <View style={[GSS.mainViewBox, {flex:1}]}>
                {paragraphs.map((text, index) => (
                <View  key={index} style={{flexDirection:'row', marginBottom:10, marginRight:10}}>
                    <ConfirmImage isActive={true}/><Text style={[styles.paragraph]}>{text}</Text>
                </View>
                ))}
                <View style={styles.marginTop}>
                <ProgressButton
                    title={isFreeParty ? "일반회원 모집" : "무료회원 모집"}
                    onPress={NextStep}
                    width={200}
                />    
                </View>
            </View>   
        );
    };    

    return (
        <View style={GSS.mainContainer}>
            <View style={{height:30}}>
                <CloseHeader
                    closeAction={CloseButton}
                /> 
            </View>
            <View style={GSS.emptyViewBox}>
                <Text style={GSS.mainInfoTextB}>어떤 회원이 이용하시나요?</Text>
            </View>
            <View style={ { marginVertical: 2, flexDirection: 'row' }}>
                <TouchableOpacity
                    style={[GSS.mainItem, {borderWidth:1, borderColor:'#f1f1f1'},  isFreeParty == true && {backgroundColor:'#291B44'} ]}
                    onPress={() => setIsFreeParty(true)}
                    accessibilityLabel="추가회원 선택"  // 예시
                >
                    <View style={[GSS.labelContainer, {margin: 10, backgroundColor: isFreeParty ? '#f1f1f1' : '#F7F7F7'}]}>
                        <Text style={[GSS.labelText, {color: isFreeParty ? '#291B44' : '#8F8C83'}]}>일반회원</Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[GSS.mainItem, {borderWidth:1, borderColor:'#f1f1f1'},  isFreeParty == false && {backgroundColor:'#291B44'}]}
                    onPress={() => setIsFreeParty(false)}
                    accessibilityLabel="일반회원 선택"  // 예시
                >
                    <View style={[GSS.labelContainer, {margin: 10, backgroundColor: isFreeParty ? '#F7F7F7': '#f1f1f1'}]}>
                        <Text style={[GSS.labelText, {color: isFreeParty ? '#8F8C83' : '#291B44' }]}>무료회원</Text>
                    </View>
                </TouchableOpacity>
            </View>
            {renderContent()}
        </View>
    );
}

const styles = StyleSheet.create({
    paragraph: {
      fontSize: 16,
      marginBottom: 10,
      color: '#555',
      fontWeight: 'bold',
      // 여기에 필요한 스타일을 추가하시면 됩니다.
    },
    marginTop: {
      marginTop: 20,
      alignItems:'center'    
    },  
});

export default SelectFreeType;