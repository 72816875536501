import React, {useState, useEffect} from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet, Dimensions,Linking } from 'react-native';
import GSS from '../GlobalStyleSheet2';
import { getOperatingSystem, SaveToSystem, setTargetAfterLogin } from '../Global';
import userLogin from '../../assets/userLogin.png'
import { GetLinkInfo, SetLinkInfo, CheckInstallled, RegisterTodo } from '../api';
import { useEnv, GetOTTByName} from '../AppContext';
import ConfirmImage from '../ConfirmImage';
import { useFocusEffect, CommonActions } from '@react-navigation/native';
import ProgressButton from '../ProgressButton';
import { useModal } from '../ModalContext';
import { useUserItem } from '../UserItemContext';

const LinkProc = ({navigation, route}) => {
    let linkCode = route.params?.link_code ?? null;
    const [ OS, setOS] = useState();
    const [ linkInfo, setLinkInfo] = useState();
    const [ OTT, setOTT] = useState();
    const [ checkConfirms, setCheckConfirms] = useState([false, false, false]);
    const [ linkStep, setLinkStep] = useState(0);
    const { ENV  } = useEnv();
    const { showMessage} = useModal();
    const { ReadUserItem } = useUserItem();
    const [ buttonTitle, setButtonTitle] = useState();
    const [ textTitle, setTextTitle] = useState();

    const GetLinkCode = () => {
        return localStorage.getItem('linker_code');
    }

    const SaveLinkCode = (link_code) => {
        localStorage.setItem('linker_code', link_code);
    }

    const GetLinkStep = () => {
        return localStorage.getItem('linker_step');
    }

    const SaveLinkStep = (step) => {
        localStorage.setItem('linker_step', step);
        setLinkStep(step);
    }

    const isAppInstalled = async () => {

    };

    useFocusEffect (
        React.useCallback(() => {
            (async () => {
                const operatingSystem = getOperatingSystem();
                setOS(operatingSystem);

                //console.log('linkCode', linkCode);
                if( linkCode ) {
                    //console.log('딥링크를 통해 호출 되었을때');
                    SaveLinkCode(linkCode);
                    SaveLinkStep(0);
                } else {
                    linkCode = GetLinkCode();
                    const step = GetLinkStep();
                    setLinkStep(step);
                    //console.log(step);
                }
                // console.log('ENV', ENV);
                //window.location.href = 'https://myshott.co.kr';       
                //window.location.href = 'http://localhost:19006';       
    
                const ret = await GetLinkInfo(linkCode);
                if( ret.success ) {
                    //console.log(ret.data);
                    ret.data.link_code = linkCode;
                    setLinkInfo(ret.data);
                    const ott = GetOTTByName(ret.item_name);
                    setOTT(ott);
                }
                if(ENV.user_id) {
                    await confirm(0, ret.data, operatingSystem);
                }
                linkCode = null;
          })();          
        }, [])
    );     

    // useEffect(() => {

    // }, [ENV]);      

    // useEffect(() => {
    //     const unsubscribe = navigation.addListener('focus', () => {
    //       // URL에서 link_code 확인
    //       // link_code가 없다면, 내부 네비게이션 라우터 로직 적용

    //         // URL 경로에서 link_code 추출하기
    //         const pathSegments = window.location.pathname.split('/');
    //         // 예상 경로: ["", "B", "8ea66f9b"]
    //         const linkCodeIndex = pathSegments.findIndex(segment => segment === 'B') + 1;
    //         const linkCode = pathSegments.length > linkCodeIndex ? pathSegments[linkCodeIndex] : undefined;

    //         console.log(linkCode); // '8ea66f9b' 또는 undefined
    //     });
      
    //     return unsubscribe;
    // }, [navigation]);   

    const LogIn = () => {
        setTargetAfterLogin('LinkProc');
        navigation.push('LoginWeb');
    }    

    let isInstalled = false;
    const confirm = async (idx, info, os) => {
        try {
            if( !ENV?.user_id) {
                LogIn();
                return;
            }

            if(info === null) {
                info = linkInfo;
            }
            if(os===null) {
                os = OS;
            }

            if( os === 'Android') {
                const ret = await CheckInstallled(ENV.user_id);
                if( ret.success) {
                    isInstalled = ret.data;
                }
                const ret2 = await RegisterTodo(ENV.user_id, 1, info?.link_code, true);
                if( ret2.success) {
                    if(isInstalled) {
                        setButtonTitle('마이샷 실행');
                        setTextTitle('마이샷 앱을 실행해 주세요');
                        showMessage(`마이샷 실행 후 ${info?.item_name} 파티 가입이 자동진행됩니다`);
                    } else {
                        setButtonTitle('마이샷 설치');
                        setTextTitle('마이샷 앱을 설치해 주세요');
                        showMessage(`마이샷 설치 후 ${info?.item_name} 파티 가입이 자동진행됩니다`);
                    }
                } else {
                    showMessage(ret2.error);
                }
            } else {
                setButtonTitle('마이샷 홈 바로가기');
                setTextTitle(`${info?.item_name} 파티에 가입 되었습니다`);
                const ret = await SetLinkInfo(info?.link_codee, ENV.user_id);
                if( !ret.success ) {
                    showMessage(ret.error.message);
                    return;
                }
                await ReadUserItem(ENV.user_id);
            }
            const newChecks = [...checkConfirms];
            newChecks[idx] = true;
            setCheckConfirms(newChecks);
            setLinkStep(1);
        } catch(e) {
            console.log(e);
        }
    }

    const GoHome = async () => {
        if( OS === 'Android') {
            if( isInstalled) {
                const url = 'myshott://'; 
                const canOpen = await Linking.canOpenURL(url);      
                if (canOpen) {
                    await Linking.openURL(url);
                  } else {
                    showMessage('앱을 열 수 없습니다. 마이샷을 직접 실행해 주세요.');
                  }
            } else {
                const appStoreUrl = "market://details?id=com.myshott.eva";
                Linking.openURL(appStoreUrl).catch(err => {
                    console.error('Failed to open URL:', err);
                });
            }
        } else {
            navigation.dispatch(
                CommonActions.reset({
                    index: 0,
                    routes: [{ 
                    name: 'Home',
                    // params: { target_after_home: target },
                    }],
                })
            );
        }
    }

    const GoHomeButton = () => {
        return (
          <ProgressButton
            title={buttonTitle}
            onPress={GoHome}
          />
        );
    }  

    const windowWidth = Dimensions.get('window').width;
    return (
    <View style={GSS.mainContainer}>
        <View style={{flexDirection: 'row', alignItems:'center', marginTop:25, marginBottom:25}}>
            <View style={{flex:1}}>
                <Text style={SS.logoTitleText}>마이샷</Text>
            </View>
            <TouchableOpacity onPress={LogIn}>
                <Image source={userLogin} style={[SS.loginImage]} />
            </TouchableOpacity>
        </View>
        {/* <View style={GSS.mainViewBox}>
            <Text style={GSS.mainInfoTextB}>{OS}</Text>
        </View>        */}
        <View style={GSS.mainViewBox}>
            <Text style={GSS.mainInfoTextB}>{linkInfo?.user_name}님의 [{linkInfo?.item_name}]파티에 초대되었습니다</Text>
        </View>
        <View style={GSS.mainViewBox}>
            <Text style={GSS.mainInfoText}>파티는 자동으로 가입되며 비용이 들지 않습니다</Text>
        </View>        
        <View style={GSS.mainViewBox}>
            <View style={SS.confirmView}>
                <Text style={[GSS.mainInfoText, {margin:3, width:windowWidth*0.8, }]}>파티 초대를 수락하시겠습니까?</Text>
                <ConfirmImage
                    isActive={checkConfirms[0]}
                    onPress={() => confirm(0, null, null)}
                />                        
            </View>
        </View>
        { linkStep > 0 ? (
            <View style={[GSS.mainViewBox, {flex:1}]}>
                <Text style={GSS.mainInfoText}>{linkInfo?.item_name} 파티에 가입 되었습니다</Text>
                <View style={[GSS.mainViewBox, {margin: 5, alignItems:'center', justifyContent:'center'}]}>
                    <GoHomeButton/>
                </View>                
            </View>
        ) : (
            <View style={[{flex:1}]}>
            </View>
        )}
    </View>
    );
};

const SS = StyleSheet.create({
    logoTitleText: {
    textAlign: 'center',
    fontSize: 40,
    color: '#00e5e0',
    fontFamily: 'Gaegu'
    },
    loginImage: {
        width: 30, // 이미지 너비
        height: 30, // 이미지 높이
        borderRadius: 15,
        backgroundColor: '#291B44',
        padding: 5,
        marginRight: 5,
    },
    confirmView: {
        //flex: 
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems : 'center',
    },     
});

export default LinkProc;