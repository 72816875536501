import React, {useState, useEffect, useCallback } from 'react';
import { 
  Text, View, Image, TextInput, StyleSheet,ScrollView, 
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import GSS from '../GlobalStyleSheet2';
import { ProgressNext } from '../ProgressButton';
import CloseHeader from '../CloseHeader';
import { saveNotifyError, GetErrorType } from '../api';
import { useModal } from '../ModalContext';
import DropDownPicker from 'react-native-dropdown-picker';

function NotifyError ({navigation, route}) {
  const { used_item } = route.params;
  const [message, setMessage] = useState('');
  const { showMessage } = useModal();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);  
  const [ errorTypes, setErrorTypes] = useState([]);

  async function NextStep()
  {
    try {
      // console.log(open);
      // console.log(value);
      const notifyContent = {
        user_item_id : used_item.user_item_id,
        used_item_id : used_item.used_item_id,
        user_id : used_item.user_id, 
        error_type : parseInt(value, 10),
        error_desc : message
      }
      const ret = await saveNotifyError(notifyContent);
      if( ret.success ) {
        showMessage([
          '파티 오류 내용이 신고되었습니다',
          '오류 내용이 신속히 처리되도록 노력하겠습니다'
        ]);   
        navigation.goBack();
      }
    } catch (e) {
      console.log('NotifyError', e);
      showMessage([
        '죄송합니다',
        '현재 오류를 신고할 수 없습니다'
      ]);   
    }
  }  

  const NextButton = () => {
    return (
      <ProgressNext
        title={'오류 신고'}
        onPress={NextStep}
        disabled={value!==null ? false : true}
      />
    );
  }  

  const CloseButton = () => {
    navigation.goBack();
  };    

  useEffect(() => {
    (async () => {
      const ret = await GetErrorType();
      if( ret.success) {
        //console.log(ret);
        const _itmes = ret.data.map(item => ( {
          label : item.error_type_desc,
          value : item.error_type
        }));  
        setErrorTypes(_itmes);
      }
    })(); 
  }, []); 


  return (
    <View style={GSS.mainContainer}>
    <View>
      <CloseHeader
          closeAction={CloseButton}
        >
          <Text style={GSS.mainDefaultText}>파티 오류 신고</Text>
        </CloseHeader>
      <View style={[GSS.mainViewBox, {flex:1}]}>
        <Text style={GSS.mainInfoTextB}>현재 파티에 어떤 문제가 있는지 알려 주세요</Text>
      </View>
    </View>
    <View style={[GSS.mainViewBox,{ zIndex: 1000 }]}>
        <DropDownPicker
          open={open}
          value={value}
          items={errorTypes}
          setOpen={setOpen}
          setValue={setValue}
          setItems={setErrorTypes}
          placeholder="문제점을 선택해 주세요"
        />
    </View>          
    <KeyboardAwareScrollView style={{ flex: 1 }}>
      <View style={[GSS.mainViewBox, {height:100}]}>
      <TextInput
            style={SS.input}
            multiline={true}
            maxLength={1000}
            placeholder="설명하고자 하는 오류 내용이 있으면 작성해 주세요(선택사항)"
            onChangeText={(text) => setMessage(text)}
            value={message}
          />
      </View>
    </KeyboardAwareScrollView>
    <View style={[GSS.mainViewBox, { margin: 5, alignItems:'center', justifyContent: 'center'}]}>
      <NextButton/>
    </View> 
  </View> 
  );
};

const SS = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
        backgroundColor: '#fff',
      },
      scrollView: {
        flex: 1,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 4,
      },
      input: {
        padding: 10,
      },
  });

export default NotifyError;