import React, {useEffect, useState } from 'react';
import { Text, View, Image} from 'react-native';
import { useItemContext } from '../ItemContext';
import { useNavigation, useFocusEffect  } from '@react-navigation/native';
import GSS from '../GlobalStyleSheet2';
import ProgressButton from '../ProgressButton';

function ManagerShareEnd ({navigation, route}) {
    const {Item, ResetItem} = useItemContext();
    const [itemName, setItemName] = useState();
    const free_charge = route.params?.free_charge;

    async function NextStep()
    {
      navigation.reset({
        index: 0,
        routes: [{ name: 'home' }],
      });  
    }   

    useFocusEffect(
      React.useCallback(() => {
        const unsubscribe = navigation.addListener('blur', () => {
          // 화면이 포커스를 잃었을 때 실행할 작업
          // 예: 상태 재설정, 데이터 정리 등
          navigation.reset({
            index: 0,
            routes: [{ name: 'home' }],
          });            
        });
  
        // 컴포넌트가 언마운트되거나 화면이 포커스를 잃었을 때 리스너를 제거합니다.
        return unsubscribe;
      }, [navigation]),
    )
    
    useEffect(() => {
      setItemName(itemName);
      if( Item.item_id) {
        ResetItem();
      }
        
    }, []);     

    const mainViewBoxNoBorder = {
        ...GSS.mainViewBox,
        borderWidth: undefined, // 'borderWidth'를 제외합니다.
        backgroundColor: undefined
    };  
    
    const NextButton = () => {
        return (
          <ProgressButton
            title={'확인'}
            onPress={NextStep}
          />

        );
    }     

    return (
        <View style={GSS.mainContainer}>
            <View style={[mainViewBoxNoBorder]}>
              <Text style={{fontWeight: 'bold', fontSize: 22,margin: 10}}><Text style={GSS.textEmphasis}>{itemName}</Text> 파티가 생성되었습니다!</Text>
              { free_charge === true ? (
                <Text style={[GSS.mainDefaultText, {margin: 10}]}>홈에서 파티 관련 초대 기능을 이용해 파티원을 초대 해 주세요</Text>
              ) : (
                <Text style={[GSS.mainDefaultText, {margin: 10}]}>자동매칭으로 파티원이 모집 되면 카카오톡, 또는 문자로 자동연락이 갑니다</Text>
              )
              }
              
            </View>
            <View style={[GSS.mainViewBox, {margin: 5, alignItems:'center', justifyContent:'center'}]}>
              <NextButton/>
            </View>   
        </View>    
    );
};

export default ManagerShareEnd;