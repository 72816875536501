import React, {useState, useEffect} from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import GSS from '../GlobalStyleSheet2';
import { getOperatingSystem, SaveToSystem } from '../Global';
import { useInitProc } from '../InitProc';

const InviteProc = ({navigation, route}) => {
    const invite_code = route.params?.invite_code ?? null;
    const [ OS, setOS] = useState();
    const { initProc} = useInitProc();

    useEffect(() => {
        (async () => {
            const operatingSystem = getOperatingSystem();
            setOS(operatingSystem);

            await initProc();
            localStorage.setItem('inviter_code', invite_code);
            window.location.href = 'https://myshott.co.kr';       
            //window.location.href = 'http://localhost:19006';       

        })(); 
      }, []);    

    return (
    <View style={GSS.mainContainer}>
        <View style={GSS.mainViewBox}>
            <Text>초대코드 처리</Text>
        </View>
        <View style={GSS.mainViewBox}>
            <Text>OS: {OS}</Text>
        </View>        
        <View style={GSS.mainViewBox}>
            <Text>초대코드: {invite_code}</Text>
        </View>       
    </View>
    );
};

export default InviteProc;