import React, {useEffect} from 'react';
import { Text, View, Image, ActivityIndicator, Platform } from 'react-native';
import GSS from './GlobalStyleSheet2';
import { useEnv } from './AppContext';
import { useFocusEffect, CommonActions } from '@react-navigation/native';
import { useLoginProc } from './LoginProc2';
import { getTargetAfterLogin } from './Global';
import { useInitProc } from './InitProc';

export default function LoginRedirect({navigation, route}) {
    const { ENV, } = useEnv();
    let user_id = route.params?.user_id;
    let last_login_type = route.params?.last_login_type;
    const { loginProc } = useLoginProc();
    const { initProc} = useInitProc();

    useFocusEffect (
      React.useCallback(() => {
        // 포커스될 때 실행할 코드
        // console.log('스크린이 포커싱되었습니다.');
        if(user_id && last_login_type) {
          // console.log(user_id, last_login_type);
          (async () => {
                try {
                    let newENV = [];
                    if( Platform.OS === 'web') {
                      newENV = await initProc();
                    }
                    newENV.user_id = user_id;
                    newENV.last_login_type = last_login_type;
                    //console.log(newENV);
                    await loginProc(newENV);
                    //navigation.navigate(getTargetAfterLogin(), {login_before : true});
                    if(Platform.OS === 'web') {
                      let target = localStorage.getItem('tmp_data');
                      //console.log(target);
                      navigation.dispatch(
                        CommonActions.reset({
                          index: 0,
                          routes: [{ 
                            name: 'Home',
                            // params: { target_after_home: target },
                          }],
                        })
                      );
                    } else {
                      const target = getTargetAfterLogin();
                      //console.log(target);
                      navigation.navigate(target, {login_before : true});   
                    }
                } catch (e) {
                    console.error(e);
                }
            })();          
        }        
        return () => {
          // 스크린이 포커스를 잃을 때 실행할 정리(clean-up) 코드
          // console.log('스크린이 포커스를 잃었습니다.');
          if(user_id) {
            user_id = null;
            last_login_type = null;
          }
        };
      }, [])
    );

    return (
      <View style={GSS.mainContainer}>
        {
            user_id && (
                <View style={{alignItems: 'center', justifyContent:'center'}}>
                    <Text style={GSS.mainDefaultText}>로그인되었습니다</Text>
                    <View style={GSS.activityIndicatorContainer}>
                        <ActivityIndicator size='large' color="#0000ff" />
                    </View>                    
                </View>    
            )
        }        
      </View>
    );
}
