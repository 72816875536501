
import React, { useState, useEffect} from 'react';
import { View, Image, StyleSheet, TouchableOpacity  } from 'react-native';
import { useSystemInfo, useEnv } from './AppContext';
import closeImage from '../assets/close_black.png';
import ScrollingTextView from './ScrollingTextView';
import { SaveToSystem } from './Global';

function AlertMessageView () {
    const [scrollingTexts, setScrollingTexts] = useState([]);
    const { systemInfo } = useSystemInfo();
    const { ENV, setEnv } = useEnv();

    useEffect(() => {
        try {
            // console.log(ENV.message_ymd);
            // console.log(systemInfo.message_ymd);
            //console.log(ENV);
            if(ENV.check_system_message) {
                if( systemInfo.system_message ) {
                    const messages = systemInfo.system_message.split('|||');
                    //console.log(messages);
                    setScrollingTexts(messages);
                } else {
                    setScrollingTexts([]);
                }
            }
        } catch(e) {
            console.error('AlertMessageView', e);
        }

    //}, [ENV.message_ymd, systemInfo.message_ymd]);     
    }, [ENV.check_system_message]);

    const confirmMessage = async () => {
      const newENV = {...ENV};
      newENV.check_system_message = false;
      newENV.message_ymd = new Date();
      await SaveToSystem(newENV);
      setEnv(newENV);
    };    

    if(!ENV.check_system_message || scrollingTexts.length < 1 ) return null;
    return(
        <View style={ {
            flexDirection: 'row', // 수평 방향으로 배치
            justifyContent: 'space-between', // 좌측과 우측으로 정렬    
            alignItems: 'center',
            height: 25,
            backgroundColor: '#291B44',
          } }>
            <View style={{ flex: 1, overflow: 'hidden'}}>
              <ScrollingTextView 
                texts={scrollingTexts} 
                textStyle={styles.scrollingText} 
                containerStyle={styles.scrollingContainer}
                speed={50}
                delay={1000}
              />
            </View>
            <TouchableOpacity onPress={confirmMessage}>
              <Image source={closeImage} style={{ width: 18, height: 18, marginRight: 5, marginLeft: 10 }}/>
            </TouchableOpacity>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    scrollingContainer: {
      // 스크롤링 텍스트 뷰의 컨테이너 스타을 여기서 정의합니다.
      // 예: backgroundColor, padding 등
      backgroundColor : '#291B44'
    },
    scrollingText: {
      // 텍스트 자체의 스타을 여기서 정의합니다.
      // 예: fontSize, color 등
      color : '#F7F7F7'
    },
});

export default AlertMessageView;