import { Platform } from 'react-native';
import { useEnv } from './AppContext';
import { useUserItem } from './UserItemContext';
import { RegisterForPushNotifications } from './PushNotification.js';
import * as Device from 'expo-device';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SetDeviceInfo } from './api';

export const useLoginProc = () => {
    const { ENV, setEnv } = useEnv();
    const { ReadUserItem } = useUserItem();

    const saveValue = async (key, value) => {
      try {
        const stringValue = JSON.stringify(value); // 값 변환
        await AsyncStorage.setItem(key, stringValue);
        //console.log(`${key} saved successfully`);
      } catch (error) {
        console.error(`Error saving ${key}`, error);
      }
    };    

    const getValue = async (key) => {
      try {
        const stringValue = await AsyncStorage.getItem(key);
        const value = JSON.parse(stringValue); // 문자열에서 원래 타입으로 변환
        //console.log(`${key}:`, value);
        return value;
      } catch (error) {
        console.error(`Error reading ${key}`, error);
        return null;
      }
    };   

    const loginProc = async (newENV) => {
        try {
            setEnv(prevEnv => ({
              ...prevEnv,
              ...newENV
            }));            
            if( newENV.user_id) {
              const ret = await ReadUserItem(newENV.user_id);
              if (ret.success) {
                if(Platform.OS !== 'web') {
                  await RegisterForPushNotifications(newENV.user_id);

                  const checkName = `${newENV.user_id}.isInstallReported`;
                  const isAlreadyReported = await getValue(checkName);
                  if (!isAlreadyReported) {
                    // console.log(`Device Brand: ${Device.brand}`);
                    // console.log(`Device Model: ${Device.modelName}`);
                    // console.log(`OS Name: ${Device.osName}`);
                    // console.log(`OS Version: ${Device.osVersion}`);  
                    const device_info = `${Device.brand}.${Device.modelName}.${Device.osName}.${Device.osVersion}`;
                    const ret = await SetDeviceInfo(device_info, newENV.user_id);
                    if( ret.success ) {
                      await saveValue(checkName, true);            
                    }
                  }
                }
              } else {
                console.log('LoginProc2 error(failed ReadUserItem): ', ret.error);
                //Alert.alert('LoginProc error', ret.error)
              }
            }      
          } catch(e) {
            console.log('LoginProc2 error', e);
            //Alert.alert('LoginProc error', e)
          }        
    }

    return { loginProc };
};