import React, {useState, useEffect } from 'react';
import { StyleSheet, Text, View, Platform , TouchableOpacity, Image, Animated} from 'react-native';
import { useAuth, useEnv, OTTs} from '../AppContext';
import { useItemContext } from '../ItemContext';
import ConfirmImage from '../ConfirmImage';
import { useModal } from '../ModalContext';
import closeImage from '../../assets/close.png';
import bellImage from '../../assets/bell.png';
import BlinkingText from '../BlinkingText';
import GSS from '../GlobalStyleSheet2';
import TopHeader from '../TopHeader';
import ProgressButton, { ProgressNext } from '../ProgressButton';

function ManagerShare1 ({navigation, route}) {
    const { isLoggedIn  } = useAuth();
    const { ENV } = useEnv();
    const {Item, setItem} = useItemContext();
    const [confirmImageActive, setConfirmImageActive] = useState(false);
    const { openModal, closeModal } = useModal();
    let selectedNumberValue = 3;
    let modalID;

    function NextStep()
    {
      //console.log(selectedNumberValue);
      const newData = { ...Item }; // 먼저 Item을 펼칩니다.
      // 그런 다음 명시적으로 값을 할당합니다.
      newData.maximum_usage = selectedNumberValue;
      newData.remaining_usage = selectedNumberValue;
      setItem(newData);
      if(Item.free_charge === true) {
        navigation.navigate('ManagerShare3');
      } else {
        navigation.navigate('ManagerShare11');
      }
    }

    useEffect(() => {
      if(isLoggedIn) {
        const newData = { ...Item };
        newData.user_id = ENV.user_id;
        setItem(newData);
      } else {
          const newData = { ...Item };
          newData.target_after_login = 'ManagerShare1';
          setItem(newData);
          if(Platform.OS === 'web')
          {
            navigation.navigate('LoginWeb', {target_after_login : 'ManagerShare1'});
          } else {
            navigation.navigate('Login', {target_after_login : 'ManagerShare1'});
          }                   
      }
    }, [isLoggedIn]); 

    const [selectedNumber, setSelectedNumber] = useState(selectedNumberValue);
    const [fadeAnim] = useState(new Animated.Value(0));
  
    const handlePress = (number) => {
      setSelectedNumber(number);
  
      fadeAnim.setValue(0);
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: true,
      }).start();
    };

    const DetailedInfoView = ({ selectedNumber }) => {
      selectedNumberValue = selectedNumber;
      const receiverPrice = selectedNumberValue * Item.monthly_price;
      const managerPrice = (4-selectedNumberValue) * Item.monthly_price
      return (
        <View style={[GSS.mainViewBox, { padding:15}]}>
          <Text style={GSS.mainInfoText}>
            {Item.display_name} {(4*Item.monthly_price).toLocaleString()}원/월
          </Text>            
          <Text style={GSS.mainInfoText}>
            - 내 부담금 : <Text style={GSS.emphasisText}>{managerPrice.toLocaleString()}</Text>원/월
          </Text>
          <Text style={GSS.mainInfoText}>
            - 파티원 {selectedNumber}명의 지급금 : <Text style={GSS.emphasisText}>{receiverPrice.toLocaleString()}</Text>원/월
          </Text>        
        </View>
      );
    };         
    
  const renderNumberButtons = () => {
    const [count, setCount] = useState(3);
    return(
      <View style={{flexDirection: 'row', alignItems:'center', justifyContent: 'space-between'}}>
        {/* "-" 버튼 */}
        <TouchableOpacity 
          style={[SS.plusminusButton, count <= 1 ? SS.plusminusButtonDisabled : null]} 
          onPress={() => {
            setCount(prev => {
              const newCount = Math.max(prev - 1, 1);
              handlePress(newCount);
              return newCount;
            });
          }}
          disabled={count <= 1}>
          <Text style={[SS.plusminusText, count<=1 ? SS.plusminusTextDisabled : null]}>-</Text>
        </TouchableOpacity>

        {/* 중앙의 숫자 표시 */}
        <Text style={[SS.countNumber]}>{count}</Text>

        {/* "+" 버튼 */}
        <TouchableOpacity 
          style={[SS.plusminusButton, count >= 3 ? SS.plusminusButtonDisabled : null]} 
          onPress={() => {
            setCount(prev => {
              const newCount = Math.min(prev + 1, 3);
              handlePress(newCount);
              return newCount;
            });
          }}       
          disabled={count >= 3}>
          <Text style={[SS.plusminusText, count >= 3 ? SS.plusminusTextDisabled : null]}>+</Text>
        </TouchableOpacity>
      </View>
    );

  };

  const confirmPress = () => {
    if (!confirmImageActive) {
      setConfirmImageActive(true);
      handleOpenModal();
    } else {
      setConfirmImageActive(false);
    }
  };

  const ModalScreen = () => {
    return (
      <View style={{ 
        flex: 1, 
        //justifyContent: 'space-between', // 컨텐츠 사이에 공간을 만듭니다.
        padding: 3, // 내부 여백을 추가합니다.
        //borderWidth: 1, 
        backgroundColor: '#FFFFFF',
      }}> 
        <View style={{
          //borderWidth: 1,
          flex:0.4,
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
          <Image
            source={bellImage}
            style={{ width: 25, height: 25 }}
          />          

        </View>      
        <View style={{
          flex : 2,
          backgroundColor: '#F1F1F1',
          //alignItems : 'center',
          justifyContent: 'center',
          padding: 5,
          //borderWidth: 2, 
          //borderColor: '#FF00FF'
        }}>
          <Text style={[GSS.mainInfoText, {marginBottom : 10}]}>• {Item.display_name}을 앱(APP)에서 결제할 경우 인앱 결제 수수료가 추가 되어 <Text style={GSS.emphasisText}>정가보다 비쌉니다</Text></Text>
          <Text style={GSS.mainInfoText}>• {Item.display_name} <Text style={GSS.emphasisText}>웹사이트</Text>에서 결제를 하면 <Text style={GSS.textEmphasis}>{(Item.monthly_price * 4).toLocaleString()}원에 결제</Text>가 가능합니다</Text>
        </View>
        <View style={{flex:0.9, alignItems: 'center', justifyContent: 'center'}}>
          <ProgressButton
            title = '확인'
            onPress={() => closeModal(modalID)} 
            style={{alignItems:'center'}}
          />
        </View>
      </View>       
    );
  }

  const handleOpenModal = () => {
    modalID = openModal(<ModalScreen />, 0.35);
  };  

  const NextButton = () => {
    return (
      <ProgressNext
        title={'다음'}
        onPress={NextStep}
        disabled={(confirmImageActive && selectedNumber > 0) ? false : true}
      />
    );
  }

  return (
    <View style={GSS.mainContainer}>
      <TopHeader 
        titleImage={OTTs[Item.ott_id].iconImage}
        navigation={navigation}
      >
        {Item.display_name} 파티 생성
      </TopHeader>
      <View style={[GSS.mainViewBox, SS.confirmView]}>
          <BlinkingText 
            shouldBlink={!confirmImageActive}
            textStyle={GSS.mainInfoTextB}
          >'<Text style={{color:'#fb3c67'}}>{Item.display_name}</Text>' 프리미엄 계정이 있으신가요?</BlinkingText>
          <ConfirmImage
            isActive={confirmImageActive}
            noDisabled={false}
            onPress={confirmPress}
          />
      </View>
      <View style={[GSS.mainViewBox, { flex: 7, alignItems : 'center' }]}>
        <View style={{marginBottom:15}}>
          <Text style={GSS.mainInfoTextB}>모집 파티인원을 선택</Text>
        </View>
        <View style={SS.buttonsContainer}>{renderNumberButtons()}</View>
        <Animated.View
          style={[
            SS.fadingContainer,
            {
              opacity: fadeAnim,
            },
        ]}>
          {/* 선택된 라벨을 직접 표시합니다. */}
          {selectedNumber && (
              <Text style={SS.numberDisplay}>
                공유 받을 <Text style={GSS.emphasisText}>{selectedNumber}명</Text>을(를) 모집합니다
              </Text>
          )}
        </Animated.View>
        {/* 선택된 항목에 대한 상세 정보를 렌더링합니다. */}
        {selectedNumber && <DetailedInfoView selectedNumber={selectedNumber} />}            
      </View>  
      <View style={[GSS.mainViewBox, { flex: 1.5, alignItems:'center' }]}>
          <NextButton/>
      </View>
  </View>
  );
};

const SS = StyleSheet.create({
  plusminusButton: {
    height: 40,
    width: 40,
    borderRadius: 20,
    borderColor: '#00e5e0',
    borderWidth: 2,
    alignItems: 'center',
    margin: 15
  },
  plusminusButtonDisabled: {
    borderColor: '#f1f3f6'
  },
  plusminusText: {
    fontSize: 30,
    color: '#291B44',
    fontFamily: 'Audiowide',   
  },
  plusminusTextDisabled: {
    color: '#e2e0e5'
  },
  countNumber: {
    fontSize: 40,
    fontFamily: 'Audiowide',
    color: '#291B44',
    marginHorizontal: 25,
  },
  numberDisplay: {
    fontSize: 20, 
    fontWeight: 'bold',
    color: '#01579b',
  },  
  confirmView: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems : 'center'
  }, 
  buttonsContainer: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  fadingContainer: {
    paddingVertical: 4,
    paddingHorizontal: 16,
    backgroundColor: "rgba(255,255,255,0.6)",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  }, 
});

export default ManagerShare1;