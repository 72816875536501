import React, {useState, useEffect } from 'react';
import { Image, Text, View, ActivityIndicator , TextInput, StyleSheet, Dimensions, TouchableOpacity } from 'react-native';
import { useAuth, useEnv, OTTs} from '../AppContext';
import { useItemContext } from '../ItemContext';
import ConfirmImage from '../ConfirmImage';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import GSS from '../GlobalStyleSheet2';
import TopHeader from '../TopHeader';
import { ProgressNext } from '../ProgressButton';
import { useUserItem} from '../UserItemContext'
import { SaveItemToDB } from '../api';
import * as WebBrowser from 'expo-web-browser';
import LinkImage from '../../assets/share.png';
import { GetAccountEmailofDB } from '../api';
import { showToast } from '../Global';
import * as Clipboard from 'expo-clipboard';

function ManagerShare3 ({navigation, route}) {
    const { isLoggedIn, login, logout } = useAuth();
    const {Item, setItem} = useItemContext();
    const [userid, setUserid] = useState('');
    const [password, setPassword] = useState('');    
    // 입력 필드가 채워지면 버튼을 활성화합니다.
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const {ReadUserItem} = useUserItem();
    const [isProcessing, setIsProcessing] = useState(false);
    const [checkedSecond, setChecedkSecond] = useState(false);
    const [checkedSecond2, setChecedkSecond2] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ accountEmails, setAccountEmails] = useState([]);
    
    async function NextStep()
    {
      if (isProcessing) {
        return; // 이미 처리 중이면 아무것도 하지 않음
      }
      setIsProcessing(true); // 처리 중 플래그 설정
      const newItem = {...Item};
      newItem.account_userid = userid;
      newItem.account_pwd = password;
      newItem.account_email_id = null;
      newItem.account_email_id2 = null;
      //console.log(accountEmails);
      if(accountEmails.length > 0) {
        if( accountEmails.length === 2) {
          newItem.account_email_id2 = accountEmails[1].account_id;
        }
        newItem.account_email_id = accountEmails[0].account_id;
      }
      //console.log(newItem);
      const ret = await SaveItemToDB(newItem);
      if( ret.success ) {
        await ReadUserItem(newItem.user_id);
      
        //console.log(ret.data);
        if( ret.data.matchCount2) {
          // 추가회원이 매칭 되었으므로 바로 추가회원 이메일 정보를 등록하게 이동하자.
        }
        navigation.reset({
          index: 0,
          routes: [
              { 
                  name: 'ManagerShareEnd',
                  params: { free_charge : Item.free_charge }
              },
          ],
        });                         
      } 
      else {
        navigation.navigate('ManagerShare1');
      }
    }

    useEffect(() => {
        const initialize = async () => {
            try {
              if (!isLoggedIn) {
                ENV.target_after_login = 'ManagerShare1';
                if (Platform.OS === 'web') {
                  navigation.navigate('LoginWeb');
                } else {
                  navigation.navigate('Login');
                }
              }
              if(Item.maximum_usage2 > 0) {
                setIsLoading(true);
                const ret = await GetAccountEmailofDB(Item.user_id, Item.maximum_usage2);
                if( ret.success ) {
                  //console.log(ret.data);
                  setAccountEmails(ret.data);
                } else {
                  console.log(ret.error);
                }
                setIsLoading(false);
              }
              setChecedkSecond(true);
              setChecedkSecond2(true);
            } catch (error) {
              console.error(error);
              // 오류 발생 시 사용자에게 알리기 (예: 알림, 토스트 메시지 등)
              alert('오류가 발생했습니다. 다시 시도해주세요.'); // 여기서 'alert'은 간단한 예시입니다. 실제 환경에선 사용자에게 더 나은 피드백을 제공해야 합니다.
            }
          };
        
          initialize();
    }, [isLoggedIn]); 

    useEffect(() => {
      setIsButtonEnabled(userid && password);
    }, [userid, password]);

    const NextButton = () => {
        return (
          <ProgressNext
            title={ isProcessing ? '처리 중...' : '다음'}
            onPress={ ((checkedSecond && checkedSecond2) && isButtonEnabled) ? NextStep : null}
            disabled={((checkedSecond && checkedSecond2) && isButtonEnabled && isProcessing===false) ? false : true}
          />
        );
    };

    const handleChangeID = (input) => {
      // 금지된 문자들
      const restrictedChars = [' ', '!', '#', '$', '%', '^', '&', '*', '(', ')', '+', '=', '{', '}', '[', ']', '|', '\\', ':', ';', '\'', '"', '<', '>', ',', '?', '/'];
      const cleanInput = input.split('').filter(char => !restrictedChars.includes(char) && !/[ㄱ-ㅎ가-힣]/.test(char)).join('');

      // 상태 업데이트
      setUserid(cleanInput);      // // 공백과 특수문자를 제외한 문자만 허용하는 정규식
      // const validRegex = /^[a-zA-Z0-9]*$/;
      // // 입력값이 정규식에 맞는 경우에만 상태를 업데이트
      // if (validRegex.test(input)) {
      //   setUserid(input);
      // }     
    };

    const handleChangePWD = (input) => {
      if (input.includes(' ')) {
        return;
      }
      setPassword(input);
    }; 

    const mainViewBoxNoBorder = {
        ...GSS.mainViewBox,
        borderWidth: undefined, // 'borderWidth'를 제외합니다.
        backgroundColor: undefined
    };    

    // 각 문자열의 확인 상태를 추적하는 state
    const [confirmStatus, setConfirmStatus] = useState([false, false, false]);
    // 모든 문자열이 확인되었는지 확인
    const [allConfirmed, setAllConfirmed] = useState(false);
    // 문자열을 확인하는 함수
    useEffect(() => {
      //console.log(allConfirmed);
      if(Item.maximum_usage === 0 && allConfirmed) {
        setIsButtonEnabled(true);
      }
    }, [allConfirmed]);   

    const confirmText = (index) => {
        const newConfirmStatus = [...confirmStatus];
        newConfirmStatus[index] = !newConfirmStatus[index];
        setConfirmStatus(newConfirmStatus);
      // 여기서 newConfirmStatus를 사용하여 allConfirmed를 업데이트합니다.
      setAllConfirmed(newConfirmStatus.every(status => status === true));
    };
    
    const confirmText2 = (index) => {
      if( index === 0)
        setChecedkSecond(prev => !prev);
      else 
        setChecedkSecond2(prev => !prev);

      if(Item.maximum_usage === 0) {
        setIsButtonEnabled(true);
      }
    };

    async function goUrl() {
      await WebBrowser.openBrowserAsync('https://www.netflix.com/ManageExtraMembers');
    }

    const copyToClipboard = async (text) => {
      await Clipboard.setStringAsync(text);
      showToast('클립보드에 복사되었습니다.');
    };    

    // 확인할 문자열 목록
    const stringsToConfirm = [
        '프리미엄 요금제 계정 인가요?', 
        '성인인증이 완료되었나요?', 
        '페이스북, 네이버등 소셜로그인 계정이 아닌가요?', 
    ];

    const stringsToConfirm2 = [
      [
        '넷플릭스 계정에서 추가회원(5,000원)을 구매 하셔야합니다',
        '추가회원을 구매하셨다면 이메일계정을 초대해 주세요',
      ],
      [
        '넷플릭스 계정에서 두번째 추가회원(5,000원)을 구매 하셔야합니다',
        '추가회원을 구매하셨다면 이메일계정을 초대해 주세요',
      ]

    ];

    const windowWidth = Dimensions.get('window').width;
    return (
      <KeyboardAwareScrollView
        style={{ flex: 1 }}
        resetScrollToCoords={{ x: 0, y: 0 }}  // 필요한 경우 스크롤 위치 조정
        contentContainerStyle={{ flexGrow: 1 }}  // 컨텐츠 스타일 조정
        scrollEnabled={true}  // 필요에 따라 스크롤 비활성화
      >   
        <View style={GSS.mainContainer}>
          <TopHeader 
              titleImage={OTTs[Item.ott_id].iconImage}
              navigation={navigation}
          >
              {Item.display_name} 계정 등록
          </TopHeader>            
          <View style={[mainViewBoxNoBorder, {flex:1}]}>
            <Text style={[GSS.mainDefaultTextB, {fontWeight: 'bold', fontSize: 20,}]}>마지막으로 확인 후, 계정을 등록해주세요</Text>
          </View>
          <View style={[GSS.mainViewBox , {flex:1}]}>
              {/* 문자열과 확인 버튼 렌더링 */}
              {stringsToConfirm.map((string, index) => {
                if(index > 2 ) {
                  return null;
                }
                return (
                  <View key={index} style={styles.confirmView}>
                      <Text style={[GSS.mainInfoText, {margin:3, width:windowWidth*0.8, }]}>{string}</Text>
                      <ConfirmImage
                          isActive={confirmStatus[index]}
                          onPress={() => confirmText(index)}
                          noDisabled={false}
                      />                        
                  </View>
                )
              })}
          </View>
          {isLoading && (
            <View style={GSS.activityIndicatorContainer}>
              <ActivityIndicator size='large' color="#0000ff" />
            </View>
          )}             
          { allConfirmed && (
          <View style={[GSS.mainViewBox , {flex:1}]}>
            <View style={{marginBottom:5, marginLeft:20}}>
                <Text style={GSS.mainSmallText}>ID는 영문자를 입력해세요(한글입력불가)</Text>
            </View> 
            { Item.item_id===1 &&
              <View style={{marginBottom:15, marginLeft:20}}>
                <Text style={GSS.mainSmallText}>ID/PW는 초대메일 발송등 편의를 위해 필요합니다</Text>
              </View>
            }
            <View style={GSS.textInputContainer}>
                <Text style={[GSS.textInputTile, {width: 40}]}>ID</Text>
                <TextInput
                    style={GSS.textInputText}
                    placeholder={`${Item.display_name} 아이디를 입력`}
                    value={userid}
                    maxLength={60}
                    onChangeText={handleChangeID}
                />
            </View>
            <View style={GSS.textInputContainer}>
            <Text style={[GSS.textInputTile, {width: 40}]}>PW</Text>
                <TextInput
                    style={GSS.textInputText}
                    placeholder={`${Item.display_name} 비밀번호를 입력`}
                    value={password}
                    maxLength={32}
                    onChangeText={handleChangePWD}
                    //secureTextEntry // 비밀번호 필드로 설정합니다.
                />
            </View>                   
          </View>
          )}
          { isProcessing && (
            <View style={[GSS.activityIndicatorContainer, {marginTop:30}]}>
              <ActivityIndicator size='large' color="#0000ff" />
            </View>
          )}
          <View style={[GSS.mainViewBox, {flex:1, margin: 5, alignItems:'center', justifyContent: 'center'}]}>
            <NextButton/>
          </View>   
        </View>      
      </KeyboardAwareScrollView>     
    );
};
const styles = StyleSheet.create({
    confirmView: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems : 'center'
    },    
});

const SS = StyleSheet.create({
  container: {
    // 컨테이너를 flex 컨테이너로 설정합니다.
    flex: 1,
    justifyContent: 'flex-end', // 자식 요소들을 컨테이너의 오른쪽으로 정렬합니다.
    alignItems: 'center', // 자식 요소들을 컨테이너의 중앙으로 정렬합니다(수직 방향).
    borderWidth:1,
    borderColor : '#8F8C83',
    margin:5,
    borderRadius:10,
    marginBottom : 15
  },
  confirmView: {
      //flex: 
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems : 'center',
      marginBottom : 10
  },
  textContainer: {
      alignItems:'center',
      padding:10, 
      borderWidth:1,
      borderRadius:5,
      borderColor: '#8F8C83'
  }
});

export default ManagerShare3;