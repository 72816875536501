import React,{ useRef, useEffect } from 'react';
import { Text, View, Image, StyleSheet, Dimensions, 
    ScrollView, ImageBackground,TouchableOpacity, Platform } from 'react-native';
import GSS from '../GlobalStyleSheet2'
import { OTTs, useEnv} from '../AppContext';
import AutoScrollingView from './AutoScrollingView ';
import logoImage from '../../assets/logo.png'
import { ProgressButtonOrg } from '../ProgressButton';
import CurvedBorder from '../CurvedBorder';
import userLogin from '../../assets/userLogin.png'
import { useNavigation  } from '@react-navigation/native';
import { setTargetAfterLogin } from '../Global';

function HomeOfLogout () {
    const { height, width: screenWidth } = Dimensions.get('window');
    const navigation = useNavigation();
    const { ENV } = useEnv();

    // 첫 번째 줄과 두 번째 줄에 표시될 이미지를 구분합니다.
    const firstRowImages = OTTs.slice(0, 3); // 또는 필요한 만큼의 이미지
    const secondRowImages = OTTs.slice(3);
    // 각 줄에 대한 이미지 뷰를 렌더링합니다.
    const renderImageToRow = (imageSet) => {
        return (
            <View style={styles.row}>
                {imageSet.map((img) => (
                   <Image key={img.id} source={img.logoImage} style={styles.image} />
                ))}
            </View>
        );
    };    

    const Progress = () => {
        navigation.navigate('Share');
    };

    const LogIn = () => {
        setTargetAfterLogin('Home');
        if(Platform.OS === 'web')
            navigation.navigate('LoginWeb');
        else
            navigation.navigate('Login');
    }

    if( Platform.OS === 'web') {
        const scrollViewRef = useRef(null);
        const scrollWidth = Dimensions.get('window').width - 40;
        const totalViews = 3; // 뷰의 총 개수
      
        useEffect(() => {
          let currentViewIndex = 0;
          const interval = setInterval(() => {
            if (currentViewIndex < totalViews) {
              scrollViewRef.current.scrollTo({
                x: (scrollWidth + 40) * currentViewIndex,
                animated: true,
              });
              currentViewIndex++;
            } else {
              // 마지막 뷰에 도달하면 처음으로 돌아감
              scrollViewRef.current.scrollTo({ x: 0, animated: true });
              currentViewIndex = 0;
            }
          }, 3000); // 3초마다 스크롤
      
          return () => clearInterval(interval);
        }, []);
    
        const LogIn = () => {
            setTargetAfterLogin('Home');
            navigation.navigate('LoginWeb');
        }
    
        return (
            <View style={[GSS.mainContainer, ]}>
                <View style={{ flexDirection: 'row', alignItems:'center'}}>
                    <View style={{flex:1}}>
                        <Text style={styles.logoTitleText}>마이샷</Text>
                    </View>
                    <TouchableOpacity onPress={LogIn}>
                     <Image source={userLogin} style={[styles.loginImage]} />
                    </TouchableOpacity>
                </View>
                <View style={{}}>
                    <View style={{flex:1, justifyContent: 'center'}}>
                    <ScrollView
          horizontal={true}
          ref={scrollViewRef}
          showsHorizontalScrollIndicator={false}
        //   contentContainerStyle={styles2.contentContainer}
        >
          {/* 여러 뷰 예시 */}
          <View style={[styles.container]}>
                        <Text style={styles.text}>{`     1/4 가격으로,`}</Text>
                        <Text style={styles.text2}>{`     바로 즐기세요!`}</Text>
                    </View>
                    <View style={[styles.container]}>
                        <Text style={styles.text}>비어 있는 프로파일,</Text>
                        <Text style={styles.text2}>낭비 하지마세요!</Text>
                    </View>     
                    <View style={[styles.container]}>
                        <Text style={styles.text}>자동매칭, 자동정산,</Text>
                        <Text style={styles.text2}>편하고 저렴하게 OTT를 구독하세요!</Text>
                    </View> 
                    <View style={[styles.container]}>
                        <Text style={styles.text}>마이샷!!!</Text>
                        <Text style={styles.text2}>에서 시작하세요</Text>
                    </View>
          {/* 추가 뷰를 여기에 배치할 수 있습니다 */}
        </ScrollView>
                    </View>
    
                    <CurvedBorder curveValue={-25} position='top' />                      
                </View>
    
                <View style={[GSS.mainViewBox, {borderWidth:1, padding:10}]}>
                    <View style={{alignItems:'center'}}>
                        <ProgressButtonOrg
                            title='계정공유 시작하기'
                            onPress={Progress}
                            style={{width:250, backgroundColor:'#fb3c67'}}
                        />
                    </View>                
                    <View style={{justifyContent: "center"}}>
                        <View style={styles.overlay} />
                        {renderImageToRow(firstRowImages)}
                        {renderImageToRow(secondRowImages)}
                    </View> 
                </View>
            </View>
        );
    } else {
        return (
            <View style={[GSS.mainContainer, {height: height}]}>
                <View style={{flex:0.8,  flexDirection: 'row', alignItems:'center'}}>
                    <View style={{flex:1}}>
                        <Text style={styles.logoTitleText}>마이샷</Text>
                    </View>
                    <TouchableOpacity onPress={LogIn}>
                     <Image source={userLogin} style={[styles.loginImage]} />
                    </TouchableOpacity>
                </View>
                <View style={{ flex:2}}>
                    <AutoScrollingView style={{marginBottom: -15}} >
                        <View style={[styles.container, { width:screenWidth }]}>
                            <Text style={styles.text}>1/4 가격으로,</Text>
                            <Text style={styles.text2}>바로 즐기세요!</Text>
                        </View>
                        <View style={[styles.container, { width:screenWidth }]}>
                            <Text style={styles.text}>비어 있는 프로파일,</Text>
                            <Text style={styles.text2}>낭비 하지마세요!</Text>
                        </View>     
                        <View style={[styles.container, { width:screenWidth }]}>
                            <Text style={styles.text}>자동매칭, 자동정산,</Text>
                            <Text style={styles.text2}>편하고 저렴하게 OTT를 구독하세요!</Text>
                        </View> 
                        <View style={[styles.container, { width:screenWidth }]}>
                            <Text style={styles.text}>마이샷!!!</Text>
                            <Text style={styles.text2}>OTT 공유</Text>
                        </View>                                              
                        {/* {viewsData.map((view, index) => (
                            <View key={index} style={[styles.container, { width:screenWidth }]}>
                                <Text style={styles.text}>{view.text}</Text>
                            </View>
                        ))}              */}
                    </AutoScrollingView>
                    <CurvedBorder curveValue={-25} position='top' style={{backgroundColor:'white'}}/>                      
                </View>
    
                <View style={[GSS.mainViewBox, {flex: 5, borderWidth:1, 
                    // RGBA에서 A는 알파(투명도)를 나타냅니다.
                    // 여기서 '0.5'는 50% 투명도를 의미합니다.            
                    //backgroundColor: 'rgba(0, 0, 0, 0.1)'
                }]}>
                    <View style={{alignItems:'center', marginBottom: -5}}>
                        <ProgressButtonOrg
                            title='계정공유 시작하기'
                            onPress={Progress}
                            style={{width:200, backgroundColor:'#fb3c67'}}
                        />
                    </View>           
                    <ImageBackground 
                        source={logoImage} 
                        resizeMode="cover" 
                        style={{flex:1, justifyContent: "center",}}>
                        <View style={styles.overlay} />
                        {renderImageToRow(firstRowImages)}
                        {renderImageToRow(secondRowImages)}
                    </ImageBackground>
                </View>
            </View>
        );
    }
};

let styles;
if( Platform.OS === 'web') {
    styles = StyleSheet.create({
        container: {
            width: Dimensions.get('window').width - 40,
            margin: 20,
            height: 200,
            marginLeft: 50,       
            justifyContent: 'center',
            //alignItems: 'center',
            //backgroundColor: '#FFFFFF',
            fontFamily: 'BlackHanSans'      
        },
        // background 스타일 정의는 이제 불필요하므로 제거할 수 있습니다.
        text: {
          fontSize: 35,
          //fontWeight: 'bold',
          //textAlign: 'center',
          margin: 5,
          color: '#291B44',
          fontFamily: 'BlackHanSans'
        },
        text2: {
            fontSize: 35,
            //fontWeight: 'bold',
            //textAlign: 'center',
            margin: 5,
            color: '#00e5e0',
            fontFamily: 'BlackHanSans'
         },    
         row: {
            // 행에 대한 스타을 정의합니다.
            flexDirection: 'row', // 가로 방향으로 배열합니다.
            //justifyContent: 'space-around', // 각 아이템 사이에 동일한 간격을 둡니다.
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10, // 줄 간격을 위해 마진을 추가합니다.
          },
          image: {
            // 이미지에 대한 스타을 정의합니다.
            width: 75, // 이미지 너비
            height: 75, // 이미지 높이
            borderRadius: 25,
            //borderWidth: 3,
            //borderColor: '#F1F1F1',
            //backgroundColor: 'rgba(200,200,200,0.1)',
            marginRight: 10
    
          },
          overlay: {
            ...StyleSheet.absoluteFillObject, // overlay를 부모의 모든 면에 꽉 차게 합니다.
            backgroundColor: 'rgba(255,255,255,0.95)', // 투명도 30%의 검은색.
          },
          logoTitleText: {
            textAlign: 'center',
            fontSize: 40,
            color: '#00e5e0',
            fontFamily: 'Gaegu'
          },
          loginImage: {
            width: 30, // 이미지 너비
            height: 30, // 이미지 높이
            borderRadius: 15,
            backgroundColor: '#291B44',
            padding: 5,
            marginRight: 5,
          }
    });
} else {
    styles = StyleSheet.create({
        container: {
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#FFFFFF',
          fontFamily: 'BlackHanSans'      
        },
        // background 스타일 정의는 이제 불필요하므로 제거할 수 있습니다.
        text: {
          fontSize: 30,
          //fontWeight: 'bold',
          textAlign: 'center',
          margin: 5,
          color: '#291B44',
          fontFamily: 'BlackHanSans'
        },
        text2: {
            fontSize: 30,
            //fontWeight: 'bold',
            textAlign: 'center',
            margin: 5,
            color: '#00e5e0',
            fontFamily: 'BlackHanSans'
         },    
         row: {
            // 행에 대한 스타을 정의합니다.
            flexDirection: 'row', // 가로 방향으로 배열합니다.
            //justifyContent: 'space-around', // 각 아이템 사이에 동일한 간격을 둡니다.
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10, // 줄 간격을 위해 마진을 추가합니다.
          },
          image: {
            // 이미지에 대한 스타을 정의합니다.
            width: 50, // 이미지 너비
            height: 50, // 이미지 높이
            borderRadius: 25,
            //borderWidth: 3,
            //borderColor: '#F1F1F1',
            //backgroundColor: 'rgba(200,200,200,0.1)',
            marginRight: 5
    
          },
          overlay: {
            ...StyleSheet.absoluteFillObject, // overlay를 부모의 모든 면에 꽉 차게 합니다.
            backgroundColor: 'rgba(255,255,255,0.95)', // 투명도 30%의 검은색.
          },
          logoTitleText: {
            textAlign: 'center',
            fontSize: 34,
            color: '#00e5e0',
            fontFamily: 'Gaegu'
          },
          loginImage: {
            width: 30, // 이미지 너비
            height: 30, // 이미지 높이
            borderRadius: 15,
            backgroundColor: '#291B44',
            padding: 5,
            marginRight: 5,
          }
    });
}
export default HomeOfLogout;