import React, {useState, useEffect } from 'react';
import { Text, View, Image, TouchableOpacity, StyleSheet  } from 'react-native';
import GSS from '../GlobalStyleSheet2';
import { useEnv } from '../AppContext';
import TopHeader from '../TopHeader';
import nextImage from '../../assets/next.png';
import { GetAccountInfoFromDB } from '../api';
import { Banks } from '../bankContext.js'
import { DeleteBankAccountOfDB } from '../api';
import { useFocusEffect } from '@react-navigation/native';

function MyAccount ({navigation, route}) {
    const { ENV, setEnv } = useEnv();
    const [backAccount, setBankAccount] = useState();
    const bankUpdated = route.params?.bankUpdated;
    const [isCheckingCertiID, setIsCheckingCertiID] = useState(false);

    useFocusEffect (
        React.useCallback(() => {
            (async () => {
                try {
                    const ret = await GetAccountInfoFromDB(ENV.user_id);
                    if(ret.success) {
                        //console.log(ret.data, true);
                        if( ret.data.checked_certification === 1 ) {    // true로 비교 하지 말자 mysql 의 불리언 형은 TINYINT(1) 로 0, 1 값 중 하나다
                            setIsCheckingCertiID(true);
                            if( ret.data.bank_account) {
                                const bankInfos = ret.data.bank_account.split('|||');
                                let bankId = +bankInfos[0];
                                
                                const _bankInfo = {
                                    name : Banks[bankId].name,
                                    bankNumber : bankInfos[1],
                                    logoImage : Banks[bankId].logoImage
                                };
                                setBankAccount(_bankInfo);
                                // console.log('id', bankId);
                                // console.log(Banks[bankId].id);
                            }
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
          })();          
        }, [ENV.user_id])
    );         

    const RegisterAccount= () => {
        //console.log(isCheckingCertiID);
        if( isCheckingCertiID ) {
            navigation.navigate('ChangeBankAccount');
        } else {
            const targetAfterRegister = 'ChangeBankAccount';
            navigation.navigate('CertifyID', {targetAfterRegister});
        }
    }

    const DeleteAccount = async () => {
        const ret = await DeleteBankAccountOfDB(ENV.user_id);
        if( ret.success)
            navigation.navigate('MyInfoHome');
    }

    return (
        <View style={GSS.mainContainer}>
            <View style={{height:50}}>
                <TopHeader 
                    navigation={navigation}
                >정산계좌</TopHeader>
            </View>
            { backAccount ? (
                <View style={[GSS.mainViewBox, {flex:1}]}>
                    <View>
                        <Text style={[GSS.mainInfoTextB, {margin:10}]}>현재 정산되는 계좌</Text>
                    </View>
                    <View style={{flex:1}}>
                        <View style={{flexDirection:'row', justifyContent:'space-between', backgroundColor:'#f8f8f8'}}>
                            <View style={{flexDirection:'row', height:70,  alignItems:'center'}}>
                                <Image
                                    source={backAccount.logoImage}
                                    style={styles.mainItemImage}
                                />
                                <View>
                                    <Text style={[GSS.mainDefaultText, {}]}>{backAccount.name}</Text>
                                    <Text style={GSS.mainInfoTextB}>{backAccount.bankNumber}</Text>
                                </View>
                            </View> 
                            <View style={{flexDirection:'row', height:70,  alignItems:'center', width:75, }}>
                                <TouchableOpacity onPress={RegisterAccount} >
                                    <Text sty={GSS.mainSmallText}>계좌변경</Text>
                                </TouchableOpacity>
                            </View> 
                        </View>  
                        <View style={[GSS.mainViewBox, {margin: 10, alignItems:'center', justifyContent: 'center'}]}>
                            <TouchableOpacity onPress={DeleteAccount} >
                                <Text style={GSS.textEmphasisAlert}>등록 계좌 삭제하기</Text>
                            </TouchableOpacity>
                        </View>                      
                    </View>                 
                </View>
            ) : (
                <View style={[GSS.mainViewBox, {flex:1}]}>
                    <Text style={GSS.mainSmallText}>정산 받으실 계좌를 등록하세요</Text>
                    <TouchableOpacity 
                        onPress={() => RegisterAccount()}
                        style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                        <Text style={[GSS.mainInfoTextB]}>정산계좌 등록</Text>
                        <Image
                            source={nextImage}
                            style={{width:25, height:25}}
                        />            
                    </TouchableOpacity>                 
                </View>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    mainItemImage: {
        width: 45, // 예시 크기, 필요에 따라 조정
        height: 45, // 예시 크기, 필요에 따라 조정
    },
});

export default MyAccount;