import React, {useEffect} from 'react';
import { StyleSheet, Text, Pressable, View, Image, Platform, } from 'react-native';
import myshottLogoImage from '../assets/logo-back.png';
import GSS from './GlobalStyleSheet2';
import KakaoIcon from '../assets/images/kakao_icon.png';
import NaverIcon from '../assets/images/naver_icon_w.png';
import { useEnv } from './AppContext';
import GoogleIcon from '../assets/images/google.png';
import { useGoogleAuth2 } from './LoginGoogle.js';
import AppleIcon from '../assets/images/apple.png';

export default function Login({navigation, route}) {
    const { ENV, setEnv } = useEnv();
    const { googleAuth } = useGoogleAuth2();
    const user_id = route.params?.user_id;
    const last_login_type = route.params?.last_login_type;

    // useEffect(() => {
    //   let isMounted = true;
    //   if( isMounted) {
    //     const newENV = {...ENV};
    //     newENV.target_after_login = 'MyInfoHome';
    //     setEnv(newENV);
    //   }
    //   return () => {
    //     isMounted = false;
    //   };    
    // }, []);     

    function kakaoLogin()
    {
      navigation.navigate('LoginKakao');
    }

    function NaverLogin()
    {
      navigation.navigate('LoginNaver');
    }    

    const LastLoginType = () => {
      //console.log(ENV);
      //console.log(ENV.last_login_type);
      if(ENV.last_login_type) {
          if(ENV.last_login_type === "kakao") return <Text>카카오 로그인</Text>;
          else if(ENV.last_login_type === "naver") return <Text>네이버 로그인</Text>;
          else if(ENV.last_login_type === "google") return <Text>구글 로그인</Text>;
          else if(ENV.last_login_type === "apple") return <Text>애플 로그인</Text>;
          else return <Text>{`${ENV.last_login_type} 로그인`}</Text>;
      } else {
          return null;
      }
    }  
    
    async function GoogleLogin()
    {
      googleAuth(); 
      //navigation.navigate('LoginRedirect');
    }   

    function AppleLogin()
    {
      navigation.navigate('LoginApple');
    }  

    return (
      <View style={GSS.mainContainer}>
        <View style={[styles.container, {alignItems: 'center'}]}>
            <Image 
                style={styles.image}
                source={myshottLogoImage} 
                resizeMode="contain"
            />
             <Text style={GSS.mainInfoText}>빠른매칭과 자동 정산,</Text>
            <Text style={GSS.mainInfoText}>저렴한 수수료까지</Text>          
        </View>  
        { LastLoginType() !== null && (
          <View style={GSS.emptyViewBox}>
           <Text style={GSS.mainDefaultText}>최근 {LastLoginType()}을 이용하셨습니다</Text>
          </View>         
        )}
        <View style={{ alignItems:'center', backgroundColor:'#fee500', borderRadius:5}}>
          <Pressable activeOpacity={0.5} onPress={() => kakaoLogin()}>
            <View style={{flexDirection:'row', padding: 10 }}>
              <Image source={KakaoIcon} style={{height:25, width:25, marginRight:10}}></Image>
              <Text style={GSS.mainInfoTextB}>카카오로 시작하기</Text>
            </View>
          </Pressable>
        </View>           
        <View style={{ marginTop: 10, alignItems:'center', backgroundColor:'#1ec502', borderRadius:5}}>
          <Pressable activeOpacity={0.5} onPress={() => NaverLogin()}>
            <View style={{flexDirection:'row', padding: 10 }}>
              <Image source={NaverIcon} style={{height:25, width:25, marginRight:10}}></Image>
              <Text style={[GSS.mainInfoTextB, {color:'#f1f3f7'}]}>네이버로 시작하기</Text>
            </View>
          </Pressable>
        </View>     
        { Platform.OS !== 'ios' && (
        <View style={{marginTop: 10, alignItems:'center', borderWidth:1, borderColor:'#CFCCC3', borderRadius:5}}>
          <Pressable activeOpacity={0.5} onPress={() => GoogleLogin()}>
            <View style={{flexDirection:'row', padding: 10 }}>
              <Image source={GoogleIcon} style={{height:25, width:25, marginRight:10}}></Image>
              <Text style={[GSS.mainInfoTextB, {}]}>구글로 시작하기</Text>
            </View>
          </Pressable>
        </View> 
        )}
        <View style={{marginTop: 10, marginBottom: 40, alignItems:'center', borderWidth:1, borderColor:'#CFCCC3', borderRadius:5}}>
          <Pressable activeOpacity={0.5} onPress={() => AppleLogin()}>
            <View style={{flexDirection:'row', padding: 10 }}>
              <Image source={AppleIcon} style={{height:25, width:25, marginRight:10}} resizeMode="contain"></Image>
              <Text style={[GSS.mainInfoTextB, {}]}>애플로 시작하기</Text>
            </View>
          </Pressable>
        </View>            
      </View>
    );
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
    //   paddingTop: StatusBar.currentHeight,
    //   backgroundColor: '#A9A9A9',
    },
    image: {
        width: 180,
        height: 180
    },    
  });
