import { StyleSheet, Platform } from 'react-native';
export default StyleSheet.create({
    droidSafeArea: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: '#fefefe',
        padding: 8,
        // backgroundColor: npLBlue,
        paddingTop: Platform.OS === 'android' ? 25 : 0,    
    },
    mainContainer: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: '#f8f8f8',
        padding: 8,
    },
    mainViewBox: {
        padding: 8,
        margin: 5,  // mainViewBox 각각에 주변 공간을 만들어 간격을 만든다
        //borderWidth: 1, // 외곽선 두께
        borderColor: '#D3D3D3', // 외곽선 색상
        borderRadius: 10, // 외곽선의 모서리 반경
        justifyContent: 'center',
        backgroundColor: '#FFFFFF',
    },
    emptyViewBox: {
        backgroundColor: '#f8f8f8',
        padding: 8,
        margin: 5, 
    },
    mainSubjectText: {
        fontWeight: 'bold',
        fontSize: 16,
        color: '#291B44'
    },
    mainTitleText: {
        fontWeight: 'bold',
        fontSize: 20,
        color: '#291B44'
    },
    mainItem: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',    
        borderRadius: 8,
        margin: 2,
        //borderWidth: 1,
        //borderColor: '#D3D3D3',
    },
    mainSelectedItem: {
        //backgroundColor: '#F1F1F1',
        //borderWidth: 2,
        backgroundColor: '#00e5e0',
    },    
    mainSubjectTextW: {
        fontWeight: 'bold',
        fontSize: 16,
        color: '#F7F7F7'
    },
    mainTitleTextW: {
        fontWeight: 'bold',
        fontSize: 18,
        color: '#F7F7F7',
        fontFamily: 'BlackHanSans'      
    },   
    mainPriceTextW: {
        fontSize: 18,
        color: '#FFFFFF',
        fontFamily: 'BlackHanSans'      
    },
    mainDefaultText: {  // 기본을 강조
        fontSize: 14,
        color: '#291B44'
    },
    mianDefaultTextW: {
        fontSize: 14,
        color: '#F7F7F7'
    },
    mainDefaultTextB: {
        fontWeight: 'bold',
        fontSize: 14,
        color: '#291B44'
    },
    mainSmallText: {
        fontSize: 12,
        color: '#291B44'
    },
    mainSmallTextW: {
        fontSize: 12,
        color: '#F7F7F7'
    },    
    mainSecondText: {
        fontWeight: 'bold',
        fontSize: 14,
        color: '#00e5e0'
    },    
    mainInfoText: {     // 안내글 및 주의 사항
        fontSize: 16,
        color: '#291B44'
    }, 
    mainInfoTextB: {     // 안내글 및 주의 사항
        fontSize: 16,
        fontWeight: 'bold',
        color: '#291B44'
    },
    mainBigText: {
        fontSize: 22,
        fontWeight: 'bold',
        color: '#291B44'
    },
    mainDisableText: {
        color: '#BFBCB3'
    },
    labelContainer: {   // 형광편색 효과로 글자 강조
        //backgroundColor: '#00e5e0',
        backgroundColor: '#00cdd0',
        paddingHorizontal: 10,
        paddingVertical: 5,
        borderRadius: 20,
        elevation: 2, // for Android shadow
        shadowColor: '#000', // for iOS shadow
        shadowOffset: { width: 0, height: 1 }, // for iOS shadow
        shadowOpacity: 0.2, // for iOS shadow
        shadowRadius: 2, // for iOS shadow
    }, 
    labelText: {
        fontSize: 16,
        color: '#fefefe',
    }, 
    mainItemImage: {
        width: 30, // Set the width as needed
        height: 30, // Set the height as needed
    },
    emphasisText: {
        fontWeight: 'bold',
        color: '#fb3c67',
    },
    textEmphasisAlert : {
        fontWeight: 'bold',
        color: 'red', // 강조할 텍스트의 색상
    },
    textEmphasis : {
        fontWeight: 'bold',
        color: '#0000CD', // 강조할 텍스트의 색상
    },
    textInputContainer: {
        flexDirection: 'row', 
        alignItems: 'center', 
        borderWidth:1, 
        borderColor: '#F3F3F5',
        margin:3,
        borderRadius: 10,
    },
    textInputTile: {
        fontWeight: 'bold', 
        fontSize:18, 
        marginLeft: 15, 
        color:'#C0C0C0'
    },
    textInputText: {
        flex: 1, 
        fontWeight: 'bold', 
        fontSize:18,  
        marginLeft: 15
    },         
    messageTitleText: {
        fontSize: 18,
        color: '#1e1f31'
    },
    messageText: {
        fontSize: 16,
        color: '#1e1f31'        
    },
    activityIndicatorContainer: {
        flex: 1,
        justifyContent: 'center', // 세로 축에서 중앙에 위치
        alignItems: 'center', // 가로 축에서 중앙에 위치
    },  
});