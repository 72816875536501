import React, {useState, useEffect} from 'react';
import { Text, View, TouchableOpacity , Image, StyleSheet, Platform } from 'react-native';
import GSS from '../GlobalStyleSheet2';
import { GetOTTByName } from '../AppContext';
import nextImage from '../../assets/next.png';
import SettingImage from '../../assets/settings.png'
import UserImage from '../../assets/user.png';
import CopyImage from '../../assets/copy.png';
import { GetIdPwdFromDB, GetAccountEmail } from '../api';
import * as Clipboard from 'expo-clipboard';
import TopHeader from '../TopHeader';
import { CheckUserAccountToDB } from '../api';
import { useModal } from '../ModalContext';
import { useUserItem } from '../UserItemContext';
import { useFocusEffect } from '@react-navigation/native';
import { showToast } from '../Global';
import * as WebBrowser from 'expo-web-browser';
import LinkImage from '../../assets/share.png';

function DisplayUsedItem ({navigation, route}) {
    const { used_item, item } = route.params;
    const OTT = GetOTTByName(item.item_name);
    const [profiles, setProfiles] = useState([]);
    const [ account_id, setAccountID] = useState();
    const [ account_pwd, setAccountPWD] = useState();
    const [ adult_pwd, setAdultPWD ] = useState();
    const [ checkDisplayID, setCheckDisplayID] = useState(false);
    const [ checkDisplayPWD, setCheckDisplayPWD] = useState(false);
    const { confirmModal, showMessage } = useModal();
    const { ReadUserItem } = useUserItem();
    const [emailAccount, setEmailAccount]  = useState();
    const { accountPwd } = route.params;
    const [maxPartyLen, setMaxPartyLen] = useState(3);

    const prevAction = () => {
        navigation.goBack();
    }

    const createProfileArray = (maxLen) => {
        const hostProfiles = used_item.host_nick_name.split('|||');
        const newProfiles = [
          { type: '내프로필', title: '내 프로필', nick_name: used_item.nick_name }
        ];
    
        for (let i = 0; i < hostProfiles.length; i++) {
          newProfiles.push({ type: '파티장', title: '파티장', nick_name: hostProfiles[i] });
        }

        
        if( used_item.user_nick_names.length === 0) {
            while (newProfiles.length <= maxLen) {
                newProfiles.push({ type: '파티원', tile:'파티원', nick_name: '자동매칭' });
              }
        } else {
            const userProfiles = used_item.user_nick_names.split('|||');
            for (let i = 0; i < userProfiles.length; i++) {
                newProfiles.push({ type: '파티원', title: '파티원', nick_name: userProfiles[i] });
              }
        }
        return newProfiles;
    };

    useEffect(() => {
        try {
            let maxLen = 3;
            if(!used_item.host_nick_name) {
                used_item.host_nick_name = '주인장';
                maxLen = 4;
            }
            const initialProfiles = createProfileArray(maxLen); 
            setProfiles(initialProfiles);
            (async () => {
                if( used_item.using_type !== 1 || used_item.use_admin_account===1) {
                    const ret = await GetIdPwdFromDB(used_item.user_item_id);
                    //console.log(ret);
                    if (ret.success) {
                        setAccountID(ret.data.account_userid);
                        setAccountPWD(ret.data.account_pwd);
                        setAdultPWD(ret.data.adult_pwd);
                    } else {
                        showMessage('아이디/비밀번호를 확인할 수 없습니다. 관리자에게 문의 하세요');
                    }
                }
            })();         
        } catch (error) {
            console.error(error.message);
        }
    }, [used_item]); 

    useFocusEffect (
        React.useCallback(() => {
            (async () => {
                try {
                    if( used_item.using_type === 1) {
                        const ret = await GetAccountEmail(used_item.account_email_id);
                        if(ret.success) {
                            setEmailAccount(ret.data);
                        } else {
                            showMessage('추가회원 계정 정보를 확인할 수 없습니다. 관리자에게 문의 하세요');
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
          })();          
        }, [used_item, accountPwd])
    );    

    const checkAccount = async () => {
        try {
            const ret = await CheckUserAccountToDB(used_item.used_item_id);
            //console.log(ret);
            if (ret.success) {
                await ReadUserItem(used_item.user_id);
            }   
        } catch (error) {
            console.error(error.message);
        }       
    }

    // useEffect(() => {
    //     if(used_item.using_type === 1 && used_item.check_account !== 1) {
    //         const _confirem = {
    //             afterOK : checkAccount,
    //             noTitle : '아니오',
    //             yesTitle : '예',
    //             body : `[${used_item.user_account}]에서 넷플릭스 초대메일을 확인 하셨나요?`
    //         }   
    //         confirmModal(_confirem);        
    //     }
    // }, []);    

    const updateProfile = () => {
        const matchedItem = {...used_item};
        navigation.navigate('MatchedProc2', { matchedItem, item });
    }

    const sendMessageToHost = () => {
        navigation.navigate('SendUserMessage', { used_item });
    }

    const ActionView = ({type}) => {
        if(type === '내프로필') {
            return (
                <TouchableOpacity  onPress={updateProfile}>
                    <View style={SS.actionContainer}>
                        <Text style={{color:'#42494f'}}>수정</Text>
                    </View>
                </TouchableOpacity>
            );            
        } else if (type ==='파티장' ) {
            return (
                <TouchableOpacity  onPress={sendMessageToHost}>
                    <View style={SS.actionContainer}>
                      <Text style={{color:'#42494f'}}>쪽지</Text>
                    </View>
                </TouchableOpacity>
            );  
        } else {
            return null;
        }
    }

    let keyIndex = 10;
    const ShowProfile = ({type}) => {
        //console.log(type);
        const ps = profiles.filter(obj => obj.type === type);
        if( ps.length === 0) return null;
        //console.log(ps);
        keyIndex += 10;
        if( ps.length === 1) {
            return(
                <View>
                    <View style={SS.titleContainer}>
                        <Text style={{color:'#42494f'}}>{type}</Text>
                    </View>
                    <View style={SS.iconWrapper}>
                        <Image source={ps[0].nick_name==='자동매칭' ? UserImage :  OTT.profileImage} style={SS.icon} />
                        <Text style={GSS.mainDefaultTextB}>{ps[0].nick_name}</Text>
                    </View> 
                    <ActionView
                        type = {type}
                    />
                </View>                
 
            );
        } else {
            return (
                <React.Fragment>
                    <View>
                        <View style={SS.titleContainer}>
                         <Text style={{color:'#42494f'}}>{type}</Text>
                        </View>
                        <View style={{flexDirection:'row',justifyContent: 'space-between',position: 'relative', alignItems:'center'}}>
                            {ps.map((profile, index) => (
                                <React.Fragment key={index+keyIndex}>
                                    <View style={SS.iconWrapper}>
                                        <Image
                                            source={profile.nick_name === '자동매칭' ? UserImage : OTT.profileImage}
                                            style={SS.icon}
                                        />
                                        <Text style={GSS.mainDefaultTextB}>{profile.nick_name}</Text>
                                    </View>
                                    {index !== ps.length - 1 && <View style={{ width: 25 }} />} 
                                </React.Fragment>
                            ))}                   
                        </View>
                        <ActionView
                            type = {type}
                        />              
                    </View>
                </React.Fragment>
            );
        }
    }

    const DisplayParty = () => {
        // console.log(used_item.host_nick_name);
        // console.log(used_item.user_nick_names);
        // console.log(profiles);
       
        return (
            <View style={SS.container}>
                <View style={SS.iconContainer}>
                    <ShowProfile
                        type={'내프로필'}
                    />
                    <ShowProfile
                        type={'파티장'}
                    />
                    <ShowProfile
                        type={'파티원'}
                    />
                </View>
            </View>         
        );
    }

    function showDisplayID() {
        setCheckDisplayID(prevState => !prevState);
        setCheckDisplayPWD(false);
    }

    function showDisplayPWD() {
        setCheckDisplayPWD(prevState => !prevState);
        setCheckDisplayID(false);
    }   

    const copyToClipboard = async (text) => {
        await Clipboard.setStringAsync(text);
        showToast('클립보드에 복사되었습니다.');
    };  

    const ShowAdultPWD = () => {
        if(!adult_pwd) return null;
        return (
            <View style={[SS.textContainer, {marginTop:10}]}>
                <View style={{width:100}}>
                    <Text style={GSS.mainDefaultText}>성인인증 비번</Text>
                </View>
                <View style={{flex:1, flexDirection:'row', alignItems:'center'}}>
                    <Text style={GSS.mainInfoText}>{adult_pwd}</Text>
                </View>
            </View>             
        );
    }

    const SettingUsedItem = () => {
        navigation.navigate('SettingUsedItem', { used_item, item });
    }

    const checkEmailContent = () => {
        navigation.navigate('CheckEmailContent', { emailAccount });
    }

    const changePassword = () => {
        // if( Platform.OS == 'web') {
        //     showMessage('쉽고 빠르게 넷플릭스 추가회원 비밀번호 변경을 하시려면 안드로이드,iOS 마이샷 앱버젼을 이용하세요')
        // } else {
            
        // }
        navigation.navigate('ChangeAccountPwd', { used_item, item, emailAccount });    
    }    

    const changePassword2 = () => {
        // if( Platform.OS == 'web') {
        //     showMessage('쉽고 빠르게 넷플릭스 추가회원 비밀번호 변경을 하시려면 안드로이드,iOS 마이샷 앱버젼을 이용하세요')
        // } else {
            
        // }
        navigation.navigate('ChangeAccountEmail', { used_item, emailAccount });    
    }    

    const myshottEmail = () => {
        // if( Platform.OS == 'web') {
        //     showMessage('쉽고 빠르게 넷플릭스 추가회원 비밀번호 변경을 하시려면 안드로이드,iOS 마이샷 앱버젼을 이용하세요')
        // } else {
            
        // }
        navigation.navigate('MyshottEmail', { used_item, emailAccount });    
    }    

    const goOTTSite = async () => {

        if( Platform.OS === 'web') {
            //window.open('https://www.netflix.com/kr/', '_blank', 'width=1280,height=720');
            window.open(OTT.homeUrl, '_blank');
        } else {
            await WebBrowser.openBrowserAsync(OTT.homeUrl);
        }
    }    
    
    const notifyError = () => {
        navigation.navigate('NotifyError', { used_item });    
    }  
   
    return (
        <View style={GSS.mainContainer}>
            <TopHeader 
                titleImage={OTT.logoImage}
                navigation={navigation}
            >
                {item.display_name} 파티
            </TopHeader>  
            <View style={[GSS.mainViewBox]}>
                <View style={{flexDirection:'row', justifyContent: 'space-between', alignItems:'center'}}>
                    <View style={{flex:1, marginLeft:10}}>
                        <Text style={GSS.mainInfoText}>{item.display_name} 파티원으로 이용중</Text>
                    </View>
                    <TouchableOpacity  onPress={SettingUsedItem}>
                        <Image
                            source={SettingImage}
                            style={[GSS.mainItemImage, {marginRight:10, height:25, width:25}]}     
                        />
                    </TouchableOpacity>
                </View>
            </View>
            { used_item.using_type === 1 ? (
                <View style={[GSS.mainViewBox, {flex:1,}]}>
                    <View style={{flexDirection:'row', marginLeft:10}}>
                        <View style={[SS.iconWrapper,]}>
                            <Image
                                source={OTT.profileImage2}
                                style={SS.icon}
                            />
                        </View>  
                        <View style={{marginLeft:5, alignItems:'center', justifyContent:'center'}}>
                            <Text style={GSS.mainDefaultText}>넷플릭스 추가회원</Text>
                        </View>
                    </View>
                    <View style={{flexDirection:'row' }}>
                        <View style={[SS.textContainer, {flex:1, borderWidth:1, marginLeft:10, flexDirection:'column', alignItems:'flex-start'}]}>
                            <Text style={{color:'#949494'}}>이메일 계정</Text>
                            <View style={{ flexDirection:'row'}}>
                                <View style={{flex:1, flexDirection:'row', alignItems:'center'}}>
                                    <Text style={GSS.mainInfoText}>{used_item.use_admin_account===1? account_id : emailAccount?.email}</Text>
                                    <TouchableOpacity  onPress={() => copyToClipboard(used_item.use_admin_account===1? account_id : emailAccount?.email)}>
                                        <Image
                                            source={CopyImage}
                                            style={{width:20, height:20, marginLeft:10}}
                                        />
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>                                          
                    </View>
                    <View style={{flexDirection:'row', marginTop:5 }}>
                        <View style={[SS.textContainer, {flex:1, borderWidth:1, marginLeft:10, flexDirection:'column', alignItems:'flex-start'}]}>
                            <Text style={{color:'#949494'}}>비밀번호</Text>
                            <View style={{ flexDirection:'row'}}>
                                <View style={{flex:1, flexDirection:'row', alignItems:'center'}}>
                                    { (emailAccount?.pwd || used_item.use_admin_account===1) ? (
                                        <React.Fragment>
                                            <Text style={GSS.mainInfoText}>{used_item.use_admin_account===1 ? account_pwd : emailAccount?.pwd}</Text>
                                            <TouchableOpacity  onPress={() => copyToClipboard(used_item.use_admin_account===1 ? account_pwd : emailAccount?.pwd)}>
                                                <Image
                                                    source={CopyImage}
                                                    style={{width:20, height:20, marginLeft:10}}
                                                />
                                            </TouchableOpacity>
                                        </React.Fragment>
                                    ) : (
                                        <Text style={{color:'#949494'}}>비밀번호가 설정되지 않았습니다</Text>
                                    )}
                                </View>
                            </View>
                        </View>                                          
                    </View>                    
                    { emailAccount?.last_status === 2 && (
                    <View style={{marginLeft:10, marginTop:10}}>
                        <TouchableOpacity 
                            onPress={checkEmailContent}
                            style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                        >
                            <Text style={[GSS.mainInfoTextB]}>추가회원 초대를 수락해주세요</Text>
                            <Image
                                source={nextImage}
                                style={{width:25, height:25}}
                            />            
                        </TouchableOpacity>
                        <View>
                            <Text style={GSS.mainSmallText}>추가회원 계정에 대한 비밀번호도 지정할 수 있습니다</Text>
                        </View>
                    </View>
                    )}
                    { used_item.use_admin_account !== 1 && (
                        <React.Fragment>
                            <View style={{marginLeft:10, marginTop:10}}>
                                <TouchableOpacity 
                                    onPress={changePassword}
                                    style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                                >
                                    <Text style={[GSS.mainInfoText]}>비밀번호 변경</Text>
                                    <Image
                                        source={nextImage}
                                        style={{width:25, height:25}}
                                    />            
                                </TouchableOpacity>    
                            </View>
                            <View style={{marginLeft:10, marginTop:10}}>
                                <TouchableOpacity 
                                    onPress={changePassword2}
                                    style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                                >
                                    <Text style={[GSS.mainInfoText]}>비밀번호 재설정</Text>
                                    <Image
                                        source={nextImage}
                                        style={{width:25, height:25}}
                                    />            
                                </TouchableOpacity>    
                                <Text style={GSS.mainSmallText}>★ 현재 비밀번호가 일치 하지 않을때</Text>
                            </View>
                            <View style={{marginLeft:10, marginTop:10}}>
                                <TouchableOpacity 
                                    onPress={myshottEmail}
                                    style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                                >
                                    <Text style={[GSS.mainInfoText]}>마이샷 이메일</Text>
                                    <Image
                                        source={nextImage}
                                        style={{width:25, height:25}}
                                    />            
                                </TouchableOpacity>    
                            </View>
                        </React.Fragment>
                    )}
                    <View style={{marginLeft:10, marginTop:10}}>
                        <TouchableOpacity 
                            onPress={goOTTSite}
                            style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                        >
                            <Text style={[GSS.mainInfoText]}>넷플릭스 바로가기</Text>
                            <Image
                                source={nextImage}
                                style={{width:25, height:25}}
                            />            
                        </TouchableOpacity>    
                    </View>                      
                    <View style={{marginLeft:10, marginTop:10}}>
                        <TouchableOpacity 
                            onPress={sendMessageToHost}
                            style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                        >
                            <Text style={[GSS.mainInfoText]}>파티장에게 쪽지 보내기</Text>
                            <Image
                                source={nextImage}
                                style={{width:25, height:25}}
                            />            
                        </TouchableOpacity>    
                    </View>  
                </View>
            ) : (
            <React.Fragment>
                <View style={[GSS.mainViewBox, {}]}>
                    <View style={{flexDirection:'row', justifyContent: 'space-between',position: 'relative', alignItems:'center'}}>
                        <DisplayParty/>
                    </View>
                </View>
                <View style={[GSS.mainViewBox, {}]}>
                    <View style={{padding:5, marginBottom:10}}>
                        <Text style={GSS.mainInfoText}>{item.display_name} 아이디/비밀번호</Text>
                    </View>
                    <View style={[SS.textContainer, {marginBottom:10}]}>
                        <View style={{width:50}}>
                            <Text style={GSS.mainDefaultText}>아이디</Text>
                        </View>
                        <View style={{flex:1, flexDirection:'row', alignItems:'center'}}>
                            <Text style={GSS.mainInfoText}>{checkDisplayID ? account_id : '●●●●●●●●'}</Text>
                            <TouchableOpacity  onPress={() => copyToClipboard(account_id)}>
                                <Image
                                    source={CopyImage}
                                    style={{width:25, height:25, marginLeft:5}}
                                />
                            </TouchableOpacity>
                        </View>
                        <TouchableOpacity  onPress={showDisplayID}>
                            <Text style={GSS.mainInfoText}>표시</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={SS.textContainer}>
                        <View style={{width:50}}>
                            <Text style={GSS.mainDefaultText}>비번</Text>
                        </View>
                        <View style={{flex:1, flexDirection:'row', alignItems:'center'}}>
                            <Text style={GSS.mainInfoText}>{checkDisplayPWD ? account_pwd : '●●●●●●●●'}</Text>
                            <TouchableOpacity  onPress={() => copyToClipboard(account_pwd)}>
                                <Image
                                    source={CopyImage}
                                    style={{width:25, height:25, marginLeft:5}}
                                />
                            </TouchableOpacity>
                        </View>
                        <TouchableOpacity onPress={showDisplayPWD}>
                        <Text style={GSS.mainInfoText}>표시</Text>
                        </TouchableOpacity>
                    </View>               
                    <ShowAdultPWD/>
                </View> 
                <View style={GSS.mainViewBox}>
                    <View style={{marginLeft:10, marginTop:10}}>
                        <TouchableOpacity 
                            onPress={goOTTSite}
                            style={{ flexDirection: 'row', lignItems: 'center' }}
                        >
                            <Text style={[GSS.mainInfoText]}>{item.display_name} 바로가기</Text>
                            <Image
                                source={LinkImage}
                                style={{width:25, height:25, marginLeft:5}}
                            />            
                        </TouchableOpacity>    
                    </View> 
                </View>  
                <View style={GSS.mainViewBox}>
                    <View style={{marginLeft:10, marginTop:10}}>
                        <TouchableOpacity
                            style={{ flexDirection:'row', justifyContent:'space-between', alignItems:'center',}}
                            onPress={notifyError}
                        >
                            <Text style={GSS.mainInfoText}>파티 오류 신고</Text>
                            <Image
                                source={nextImage}
                                style={{width:30, height:30}}
                            />
                        </TouchableOpacity>   
                    </View> 
                </View>                                         
            </React.Fragment>
            )}
        </View>
    );
};


const SS = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    titleContainer: {
        alignItems:'center', 
        justifyContent:'center', 
        marginBottom:10, 
        padding:5, 
        backgroundColor:'#eaebed',
        borderRadius:5
    },
    actionContainer: {
        alignItems:'center', 
        justifyContent:'center', 
        marginTop:10, 
        padding:5, 
        borderRadius:5,
        borderWidth: 1,
        borderColor: '#00e5e0'
    },
    iconContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
    },
    iconWrapper: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        width: 30, // 아이콘 크기에 맞게 조정하세요
        height: 30, // 아이콘 크기에 맞게 조정하세요
        marginBottom: 5,
        borderRadius:5
    },
    textContainer: {
        flexDirection:'row', 
        alignItems:'center',
        padding:10, 
        borderWidth:1,
        borderRadius:5,
        borderColor: '#8F8C83'
    }
  });
export default DisplayUsedItem;