import React, {useState, useEffect, useCallback } from 'react';
import { 
  Text, View, Image, TextInput, StyleSheet,ScrollView, ActivityIndicator
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import GSS from '../GlobalStyleSheet2';
import { ProgressNext } from '../ProgressButton';
import CloseHeader from '../CloseHeader';
import { SendHostMessageToDB } from '../api';
import ConfirmImage from '../ConfirmImage';
import { useModal } from '../ModalContext';

function SendHostMessage ({navigation, route}) {
  const { user_item, nick_name, new_netflix } = route.params;
  const [message, setMessage] = useState('');
  const [ checkAllMessage, setCheckAllMessage] = useState(false);
  const { showModalMessage } = useModal();
  const [isLoading, setIsLoading] = useState(false);

  async function NextStep()
  {
    try {
      setIsLoading(true);
      const _user_item = {
        user_item_id : user_item.user_item_id,
        from_user_id : user_item.user_id,
        from_nick_name : new_netflix ? '파티장' : user_item.nick_name,
        item_id : user_item.item_id,
        new_netflix : new_netflix ? true : false
      };
      const ret = await SendHostMessageToDB(_user_item, checkAllMessage ? '' : nick_name, message);
      setIsLoading(false);
      if( ret.success ) {
        //console.log(ret.data);
        //Alert.alert('쪽지발송 성공', '파티원에게 쪽지가 발송 되었습니다습니다');
        const _message = {
          title : '쪽지발송 성공',
          body : '파티원에게 쪽지가 발송 되었습니다.',
        };
        showModalMessage(_message);       
        navigation.goBack();
      } else {
        //Alert.alert('쪽지발송 실패', ret.error);
        const _message = {
          title : '쪽지발송 실패',
          body : ret.error
        };
        showModalMessage(_message);         
      }
    } catch (e) {
      console.log('SendHostMessage.SendHostMessageToDB', e);
      //Alert.alert('쪽지발송 실패', e);
      const _message = {
        title : '쪽지발송 실패',
        body : e.message,
      };
      showModalMessage(_message);        
    }
  }

  const NextButton = () => {
    return (
      <ProgressNext
        title={'쪽지 보내기'}
        onPress={ message.length>5 ? NextStep : null}
        disabled={message.length>5 ? false : true}
      />
    );
  }  

  const CloseButton = () => {
    navigation.goBack();
  };    

    return (
        <KeyboardAwareScrollView
            style={{ flex: 1 }}
            resetScrollToCoords={{ x: 0, y: 0 }}  // 필요한 경우 스크롤 위치 조정
            contentContainerStyle={{ flexGrow: 1 }}  // 컨텐츠 스타일 조정
            scrollEnabled={true}  // 필요에 따라 스크롤 비활성화
        >
            <View style={GSS.mainContainer}>
                <CloseHeader
                  closeAction={CloseButton}
                >
                  <Text style={GSS.mainDefaultText}>마이샷 쪽지 발송</Text>
                </CloseHeader>
                <View style={[GSS.mainViewBox, {flex:1}]}>
                  <Text style={GSS.mainInfoTextB}>파티원 <Text style={GSS.textEmphasis}>{nick_name}</Text>님에게 보낼 쪽지를 작성해 주세요</Text>
                </View>
                <View style={[GSS.mainViewBox, {flex:1}]}>
                    <View style={SS.container}>
                        <ScrollView style={SS.scrollView}>
                            <TextInput
                                style={SS.input}
                                multiline={true}
                                maxLength={500}
                                placeholder="메시지를 입력하세요..."
                                onChangeText={(text) => setMessage(text)}
                                value={message}
                            />
                        </ScrollView>
                    </View>  
                </View>  
                {isLoading && (
                  <View style={GSS.activityIndicatorContainer}>
                    <ActivityIndicator size='large' color="#0000ff" />
                  </View>
                )}                    
                <View style={[GSS.mainViewBox, {margin: 5, alignItems:'center', justifyContent: 'center'}]}>
                    <View style={{flexDirection:'row', marginBottom:15}}>
                        <ConfirmImage
                            isActive={checkAllMessage}
                            //disabled={!checkAllMessage}
                            noDisabled = {false}
                            onPress={ () => setCheckAllMessage( prev => !prev)}
                        />
                        <Text>파티원 전체에게 쪽지 전송</Text>
                    </View>
                    <NextButton/>
                </View> 
            </View>  
        </KeyboardAwareScrollView>
    );
};

const SS = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
        backgroundColor: '#fff',
      },
      scrollView: {
        flex: 1,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 4,
      },
      input: {
        padding: 10,
      },
  });

export default SendHostMessage;