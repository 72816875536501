import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const CurvedBorder = ({ children, curveValue = 80, style, borderHeight = 50, position = "top", fillColor='#291B44' }) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const onLayout = (event) => {
    if (dimensions.width === 0 && dimensions.height === 0) {
      const { width, height } = event.nativeEvent.layout;
      setDimensions({ width, height });
    }
  };

  const { width, height } = dimensions;
  const viewBox = `0 0 ${width} ${height}`;
  const topCurve = `M0,0 C${curveValue},${height} ${width - curveValue},${height} ${width},0 L${width},${height} L0,${height}`;
  const bottomCurve = `M0,0 L0,80 C${width/4},${curveValue/4} ${3*width/4},${curveValue/4} ${width},80 L${width},0`;

  let path;
  if (position === "top") {
    path = topCurve;
  } else if (position === "bottom") {
    path = bottomCurve;
  } else if (position === "both") {
    path = `${topCurve} ${bottomCurve}`;
  }

  const styles7 = StyleSheet.create({
    container: {
      height: borderHeight,
      overflow: 'hidden',
    },
    // loadingContainer: {
    //   height: borderHeight,
    //   alignItems: 'center',
    //   justifyContent: 'center',
    // },
    // loadingText: {
    //   color: 'gray',
    // },
  });

  // if (!layoutReady) {
  //   return (
  //     <View style={[styles7.loadingContainer, style]}>
  //       <Text style={styles7.loadingText}>Loading...</Text>
  //     </View>
  //   );
  //}

  return (
    <View style={[styles7.container, style, { width: '100%' }]} onLayout={onLayout}>
      {width > 0 && height > 0 && (
        <Svg height="100%" width="100%" viewBox={viewBox}>
          <Path d={path} fill={fillColor} />
        </Svg>
      )}
      {children}
    </View>
  );
};

export default CurvedBorder;