import React, {useEffect, useState} from 'react';
import { StyleSheet, Text, Pressable, View, Image, Platform} from 'react-native';
import myshottLogoImage from '../assets/logo-back.png';
import AsyncStorage from '@react-native-async-storage/async-storage';
import GSS from './GlobalStyleSheet2';
import { routerServer } from './Global';
import * as Crypto from 'expo-crypto';
import { makeRedirectUri, useAuthRequest } from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';
import Axios from 'axios';
import { useEnv } from './AppContext';
import KakaoIcon from '../assets/images/kakao_icon.png';
import NaverIcon from '../assets/images/naver_icon_w.png';
import GoogleIcon from '../assets/images/google.png';
import * as Google from 'expo-auth-session/providers/google'
import { useLoginProc } from './LoginProc2';
import { useModal } from './ModalContext';
import { ScrollView } from 'react-native-gesture-handler';
import { getTargetAfterLogin } from './Global';
import AppleIcon from '../assets/images/apple.png';

WebBrowser.maybeCompleteAuthSession();

const AUTH_URL = `${routerServer}/kakao/kakao_web_login`;

const KAKAO_CLIENT_ID = "90d60a0115ad5aa6b005532d9f7ea2ae";
const redirectUri = makeRedirectUri();    
//const redirectUri = 'http://myshott.chyh.kr:8081';
const NAVER_CLIENT_ID = "RT6jhR9HQJ3ZADONhWfZ";
const GOOGLE_CLIENT_ID = "253648496299-d571bcrgi10guakq0mi1e5f45n04f6pr.apps.googleusercontent.com";

// Endpoint
const discovery = {
  authorizationEndpoint: 'https://kauth.kakao.com/oauth/authorize',
  tokenEndpoint: 'https://kauth.kakao.com/oauth/token',
}
const discovery2 = {
  authorizationEndpoint: 'https://nid.naver.com/oauth2.0/authorize',
  tokenEndpoint: 'https://nid.naver.com/oauth2.0/token',
}

const discovery3 = {
  authorizationEndpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
  tokenEndpoint: 'https://oauth2.googleapis.com/token',
}

export default function LoginWeb({navigation, route, target_after_login2}) {
  const target_after_login = route.params?.target_after_login ?? null;
  const [request, response, promptAsync] = useAuthRequest(
    {
      clientId: KAKAO_CLIENT_ID,
      scopes: [],
      redirectUri: redirectUri
    },
    discovery
  );
  const [request2, response2, promptAsync2] = useAuthRequest(
    {
      clientId: NAVER_CLIENT_ID,
      scopes: [],
      redirectUri: redirectUri
    },
    discovery2
  );  
  const [request3, response3, promptAsync3] = Google.useIdTokenAuthRequest(
    {
      clientId: GOOGLE_CLIENT_ID,
      // redirectUri: redirectUri
    },
    discovery3
  );    

  const {ENV, setEnv} = useEnv();
  const { loginProc } = useLoginProc();
  const { showModalMessage } = useModal(); 

  const newENV = {
    user_id : null,
    last_login_type : null
  }

  const rcvLast = (access_token) => {
      //console.log('Called rcvLast');
      //console.log(access_token);
      Axios.get(`${routerServer}/kakao/kakao_profile/${access_token}`, { params: {} })
        .then(({ data }) => {
          if ( data.user_id) {
              newENV.user_id = data.user_id;
              newENV.last_login_type = data.last_login_type;               
          } else {
              const _msg = {
                body : '마이샷 정보를 확인 할 수 없습니다'
              }
              showModalMessage(_msg);             
              return Promise.resolve();
          }
          navigateAfterLogin();
         })
         .catch(handleError);
  };
  
  const rcvLast2 =  (access_token) => {
    //console.log('Called rcvLast');
    //console.log(access_token);
    Axios.get(`${routerServer}/naver/naver_profile/${access_token}`, { params: {} })
      .then(({ data }) => {
        if ( data.user_id) {
            newENV.user_id = data.user_id;
            newENV.last_login_type = data.last_login_type;               
        } else {
            const _msg = {
              body : '마이샷 정보를 확인 할 수 없습니다'
            }
            showModalMessage(_msg);             
            return Promise.resolve();
        }
        navigateAfterLogin();
      })
      .catch(handleError);
  };

  const navigateAfterLogin = async () => {
      //console.log( states.newENV);
    if( newENV.user_id) {
        await loginProc(newENV);
    } 
    let target;
    if( target_after_login ) {
      target = target_after_login;
    } else if(target_after_login2) {
      target = target_after_login2;
    } else {
      target = getTargetAfterLogin();
    }
    //console.log('로그인 후 이동 :', target , ', user_id : ', newENV.user_id );
    //navigation.navigate(target, {login_before : true}); 
    navigation.reset({
      index: 0,
      routes: [{ name: target }],
    });
     
  };
  
  const handleError = (error) => {
      console.log(error);
      //showToast('오류가 발생하였습니다');
      navigateAfterLogin();
  };  

  async function call(method, uri, param, header) {
    try {
        const response = await Axios({
            method: method,
            url: uri,
            headers: header,
            data: param,
        });
        return response.data;
    } catch (err) {
        console.error("Error during HTTP call:", err);
        throw err;
    }
  }  

  // 액세스 토큰 요청 예시 (클라이언트 측에서 직접 요청하는 경우)
  async function fetchAccessToken(code, codeVerifier) {
    try {
      const response = await fetch(discovery.tokenEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          grant_type: 'authorization_code',
          client_id: KAKAO_CLIENT_ID,
          redirect_uri: redirectUri,
          code: code,
          code_verifier: codeVerifier, // PKCE 코드 검증자 추가
        }),
      });
      const data = await response.json();
      //console.log(data.access_token);
      // 여기서 액세스 토큰을 처리합니다.
      rcvLast(data.access_token);
    } catch (error) {
      console.error('Error fetching access token:', error);
    }
  }

  async function fetchAccessToken2(code, codeVerifier) {
    try {
      const response = await fetch(`${routerServer}/naver/get-naver-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code: code,
          codeVerifier: codeVerifier,
          redirect_uri: redirectUri,
        }),
      });      
      const data = await response.json();
      //console.log(data.access_token);
      // 여기서 액세스 토큰을 처리합니다.
      rcvLast2(data.access_token);
    } catch (error) {
      console.error('Error fetching access token:', error);
    }
  }  

  React.useEffect(() => {
    if (response?.type === 'success') {
      const { code, code_verifier} = response.params;
      const codeVerifier = request.codeVerifier;
      //console.log(response);
      //console.log(`[${codeVerifier}]` );
      fetchAccessToken(code, codeVerifier);
    }
  }, [response]);  

  React.useEffect(() => {
    if (response2?.type === 'success') {
      const { code, code_verifier} = response2.params;
      const codeVerifier = request2.codeVerifier;
      //console.log(response2);
      //console.log(`[${codeVerifier}]` );
      fetchAccessToken2(code, codeVerifier);
    }
  }, [response2]);  

  React.useEffect(() => {
    if (response3?.type === 'success') {
      const { id_token } = response3.params;
      //console.log(response3.params);
      //fetchAccessToken3(id_token);
      const idTokenPayload = decodeIdToken(id_token); // yourIdToken을 실제 토큰으로 대체하세요.
      //console.log(idTokenPayload)
      const _google = {
        google_id : idTokenPayload.sub,
        user_name : idTokenPayload.name,
        email : idTokenPayload.email
      }
      profileGoogle(_google);
    }
  }, [response3]);

  async function profileGoogle(_google) {
    try {
      const response = await fetch(`${routerServer}/google/set_profile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          google_id : _google.google_id,
          user_name : _google.user_name,
          email : _google.email
        }),
      });      
      const data = await response.json();
      //console.log(data);
      if ( data.user_id) {
        newENV.user_id = data.user_id;
        newENV.last_login_type = data.last_login_type;               
      } else {
        const _msg = {
          body : '마이샷 정보를 확인 할 수 없습니다'
        }
        showModalMessage(_msg);             
        return Promise.resolve();
      }
      navigateAfterLogin();      
    } catch (error) {
      console.error('Error fetching access token:', error);
    }    
  }

  function decodeIdToken(idToken) {
    // JWT를 점(.)으로 구분하여 분할합니다.
    const [headerEncoded, payloadEncoded, signatureEncoded] = idToken.split('.');
    // Base64 URL-decoded 형식의 페이로드를 디코딩합니다.
    const payloadDecoded = atob(payloadEncoded.replace(/_/g, '/').replace(/-/g, '+'));
    // 디코딩된 페이로드를 JSON 객체로 변환합니다.
    const payload = JSON.parse(payloadDecoded);
    return payload;
  }
  
  const kakaoLogin = async () => { 
    //console.log(`[${redirectUri}]` );
    //console.log(ENV.target_after_login);
    promptAsync();
  };  

  const NaverLogin = async () => { 
    //console.log(`[${redirectUri}]` );
    //console.log(ENV.target_after_login);
    promptAsync2();
  };  

  const GoogleLogin  = async () => { 
    //console.log(`[${redirectUri}]` );
    //console.log(ENV.target_after_login);
    if( Platform.OS === 'web') {
      let target;
      if( target_after_login ) {
        target = target_after_login;
      } else if(target_after_login2) {
        target = target_after_login2;
      } else {
        target = getTargetAfterLogin();
      }    
      localStorage.setItem('tmp_data', target);
    }
    promptAsync3();
  }; 

  const AppleLogin  = async () => { 
    let target;
    if( target_after_login ) {
      target = target_after_login;
    } else if(target_after_login2) {
      target = target_after_login2;
    } else {
      target = getTargetAfterLogin();
    }
    navigation.navigate('LoginApple', {target_after_login : target});
  };

  const LastLoginType = () => {
    //console.log(ENV);
    //console.log(ENV.last_login_type);
    if(ENV.last_login_type) {
        if(ENV.last_login_type === "kakao") return <Text>카카오 로그인</Text>;
        else if(ENV.last_login_type === "naver") return <Text>네이버 로그인</Text>;
        else if(ENV.last_login_type === "google") return <Text>구글 로그인</Text>;
        else if(ENV.last_login_type === "apple") return <Text>애플 로그인</Text>;
        else return <Text>{`${ENV.last_login_type} 로그인`}</Text>;
    } else {
        return null;
    }
  }  

  return (
    <ScrollView>
    <View style={[GSS.mainContainer, {alignItems:'center'}]}>
      <View style={[styles.container, {alignItems: 'center', marginBottom:150}]}>
          <Image 
              style={styles.image}
              source={myshottLogoImage} 
              resizeMode="contain"
          />
           <Text style={GSS.mainInfoText}>빠른매칭과 자동 정산,</Text>
          <Text style={GSS.mainInfoText}>저렴한 수수료까지</Text>          
      </View> 
      { LastLoginType() !== null && (
        <View style={GSS.emptyViewBox}>
          <Text style={GSS.mainDefaultText}>최근 {LastLoginType()}을 이용하셨습니다</Text>
        </View>         
      )}
      <View style={{ alignItems:'center', backgroundColor:'#fee500', borderRadius:5, width:'60%'}}>
        <Pressable activeOpacity={0.5} onPress={() => kakaoLogin()}>
          <View style={{flexDirection:'row', padding: 10 }}>
            <Image source={KakaoIcon} style={{height:25, width:25, marginRight:10}}></Image>
            <Text style={GSS.mainTitleText}>카카오로 시작하기</Text>
          </View>
        </Pressable>
      </View>           
      <View style={{ marginTop: 20, alignItems:'center', backgroundColor:'#1ec502', borderRadius:5, width:'60%'}}>
        <Pressable activeOpacity={0.5} onPress={() => NaverLogin()}>
          <View style={{flexDirection:'row', padding: 10 }}>
            <Image source={NaverIcon} style={{height:25, width:25, marginRight:10}}></Image>
            <Text style={[GSS.mainTitleText, {color:'#f1f3f7'}]}>네이버로 시작하기</Text>
          </View>
        </Pressable>
      </View>        
      <View style={{marginTop: 20, alignItems:'center', borderWidth:1, borderColor:'#8F8C83', borderRadius:5, width:'60%'}}>
        <Pressable activeOpacity={0.5} onPress={() => GoogleLogin()}>
          <View style={{flexDirection:'row', padding: 10 }}>
            <Image source={GoogleIcon} style={{height:25, width:25, marginRight:10}}></Image>
            <Text style={[GSS.mainTitleText, {}]}>구글로 시작하기</Text>
          </View>
        </Pressable>
      </View>   
      <View style={{marginTop: 20, alignItems:'center', borderWidth:1, borderColor:'#8F8C83', borderRadius:5, width:'60%'}}>
        <Pressable activeOpacity={0.5} onPress={() => AppleLogin()}>
          <View style={{flexDirection:'row', padding: 10 }}>
            <Image source={AppleIcon} style={{height:25, width:25, marginRight:10}}></Image>
            <Text style={[GSS.mainTitleText, {}]}>애플로 시작하기</Text>
          </View>
        </Pressable>
      </View>          
    </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
    container: {
      justifyContent: 'center',
    //   paddingTop: StatusBar.currentHeight,
    //   backgroundColor: '#A9A9A9',
    },
    image: {
      width: 300,
      height: 300
    },    
  });
  
