import React, {useState, useEffect} from 'react';
import { Text, View, Image, Platform, ScrollView, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useEnv } from '../AppContext';
import GSS from '../GlobalStyleSheet2';
import { RegisterForPushNotifications } from '../PushNotification';
import { useModal } from '../ModalContext';
import { GetUserMessagesFromDB, GetSysMessagesFromDB, CheckCertiIDOfDB } from '../api';
import CloseHeader from '../CloseHeader';
import nextImage from '../../assets/next.png';
import { SaveToSystem,  HorizontalLine, appVersion } from '../Global';
import { useUserItem } from '../UserItemContext';
import MessageViewer from './MessageViewer';
import { Share } from 'react-native';

function MyInfoHomeOfLogin ({navigation, route}) {
  const { ENV, setEnv } = useEnv();
  const { openModal, closeModal, showModalMessage } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const { setUserItem } = useUserItem();
  const [modalID, setModalID] = useState();
  const [ checkCertifyID, setCheckCertifyID] = useState(false);
  const [ inviteCode, setInviteCode] = useState();

  async function LogoutStep()
  {
      const newENV = { ...ENV };
      newENV.user_id = null;
      newENV.last_login_type = null;
      await SaveToSystem(newENV);
      setEnv(newENV);  
      setUserItem({
        t_user_item : [],
        t_used_item : [],
        t_waiting_list : [],
        t_user_message : [],
        t_sys_message : []
      });
      navigation.reset({
        index: 0,
        routes: [{ name: 'home' }],
      });      
  }

  const LastLoginType = () => {
    //console.log(ENV);
    //console.log(ENV.last_login_type);
    if(ENV.last_login_type) {
        if(ENV.last_login_type === "kakao") return <Text>카카오 로그인</Text>;
        else if(ENV.last_login_type === "naver") return <Text>네이버 로그인</Text>;
	      else if(ENV.last_login_type === "google") return <Text>구글 로그인</Text>;
        else if(ENV.last_login_type === "apple") return <Text>애플 로그인</Text>;
        else return <Text>{`${ENV.last_login_type} 로그인`}</Text>;
    } else {
        return null;
    }
  }

  // async function DeleteSystemData()
  // {
  //   await PrintSystemData();
  //   await RemoveSystemData();
  //   await PrintSystemData();
  // }

  async function registerForPushNotifications()
  {
    const ret = RegisterForPushNotifications(ENV.user_id);
    let _message;
    if( ret) {
      _message = {
        body : '알림메시지 설정이 성공적으로 저장되었습니다',
      };    
    } else {
      _message = {
        body : '알림메시지 설정이 실패하였습니다. 모바일 설정을 확인하세요',
      }; 
    }
    showModalMessage(_message);
  }  

  const ShowMessages = async (isSystem=false) => {
    try {
      setIsLoading(true);
      let ret;
      if( isSystem ) {
        ret = await GetSysMessagesFromDB(ENV.user_id);
      } else {
        ret = await GetUserMessagesFromDB(ENV.user_id);
      }
      setIsLoading(false);
      // //console.log(ret.data);
      // //console.log('messages :', messages);
      // modalID = openModal(
      //   <MessageViewer
      //     posts={ret.data}
      //   /> ,
      //   0.6,    // heightPercentage, 필요한 경우 다른 값으로 설정
      //   0.85,   // widthPercentage
      //   'slide', // animation, 필요한 경우 다른 값으로 설정
      //   {},      // style, 필요한 경우 스타일 객체로 설정
      //   true     // centered, 모달을 중앙에 배치하려면 true로 설정                
      // );   

      const id = openModal(
        <MessageViewer
          modalID={modalID}
          // onSelect={netflixSelect}
          posts={ret.data}
          closeModal={closeModal}
        /> ,
        0.7,    // heightPercentage, 필요한 경우 다른 값으로 설정
        0.90,   // widthPercentage
        'slide', // animation, 필요한 경우 다른 값으로 설정
        {},      // style, 필요한 경우 스타일 객체로 설정
        //true     // centered, 모달을 중앙에 배치하려면 true로 설정                
      );
      setModalID(id);      
    } catch (error) {
      console.error('메시지를 불러오는데 실패했습니다:', error);
    }
  }    

  function AdminQnA()
  {
    navigation.navigate('AdminQA', { ENV });
  }

  function AdminAccount()
  {
    navigation.navigate('AdminAccount');
  }  

  function MyAccount()
  {
    const bankUpdated = false;
    navigation.navigate('MyAccount',  {bankUpdated});
  }  

  function AdminPay()
  {
    const payUpdated = false;
    navigation.navigate('AdminPay',  {payUpdated});
  }

  function CertifyID()
  {
    const targetAfterRegister = "MyInfoHome";
    navigation.navigate('CertifyID', {targetAfterRegister});
  } 

  useEffect(() => {
    (async () => {
      const ret = await CheckCertiIDOfDB(ENV.user_id);
      if( ret.success) {
        if(ret.data.checked_certification === 1) {
          setCheckCertifyID(true);
          setInviteCode(ret.data.invite_code);
        }
      }
    })(); 
  }, []); 

  const shareMessage = async () => {
    try {
      const result = await Share.share({
        //message: '마이샷을 지인에게 초대해 주세요',
        message: `https://myshott.co.kr/406/${inviteCode}`,
        // URL을 추가하려면 아래 주석을 해제하세요.
        url: `https://myshott.co.kr/406/${inviteCode}`,
        //url: `http://localhost:19006/406/${inviteCode}`,
        title: '마이샷 공유하기'
      });
  
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // 공유된 액티비티 타입이 있는 경우 처리
          //console.log('ActivityType:', result.activityType);
        } else {
          // 공유가 성공적으로 완료된 경우 처리
        }
      } else if (result.action === Share.dismissedAction) {
        // 공유가 취소된 경우 처리
      }
    } catch (error) {
      console.error('Error during sharing', error);
    }
  };

  return (
    <View style={GSS.mainContainer}>
      <View style={GSS.mainViewBox}>
        <View style={{flexDirection:'row', justifyContent: 'space-between', padding:5, alignItems:'center'}}>
          <TouchableOpacity onPress={() => ShowMessages()}>
            <Text style={GSS.mainInfoTextB}>지난 쪽지 보기</Text>
          </TouchableOpacity>
        </View>
        <View style={{flexDirection:'row', justifyContent: 'space-between', padding:5, alignItems:'center'}}>
          <TouchableOpacity onPress={() => ShowMessages(true)}>
            <Text style={GSS.mainInfoTextB}>지난 시스템 메시지 보기</Text>
          </TouchableOpacity>
        </View>
        {isLoading && (
          <View style={GSS.activityIndicatorContainer}>
            <ActivityIndicator size='large' color="#0000ff" />
          </View>
        )}         
        <View style={{padding:5,}}>
          <TouchableOpacity 
            onPress={() => AdminQnA()}
            style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Text style={[GSS.mainInfoTextB]}>관리자에게 문의하기</Text>
            <Image
              source={nextImage}
              style={{width:25, height:25}}
            />            
          </TouchableOpacity>
        </View>
        <HorizontalLine color='#e3e3e3' />
        <View style={{padding:5,}}>
          <TouchableOpacity 
            onPress={AdminPay}
            style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Text style={[GSS.mainInfoTextB]}>결제정보 변경</Text>
            <Image
              source={nextImage}
              style={{width:25, height:25}}
            />            
          </TouchableOpacity>
        </View> 
        <View style={{padding:5,}}>
          <TouchableOpacity 
           onPress={MyAccount}
            style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Text style={[GSS.mainInfoTextB]}>정산계좌 변경</Text>
            <Image
              source={nextImage}
              style={{width:25, height:25}}
            />            
          </TouchableOpacity>
        </View>
        <HorizontalLine color='#e3e3e3' /> 
        { Platform.OS !== 'web' && (
          <View style={{flexDirection:'row', justifyContent: 'space-between', padding:5, alignItems:'center'}}>
            <TouchableOpacity onPress={registerForPushNotifications}>
              <Text style={GSS.mainInfoTextB}>알림서비스 받기(권한설정)</Text>
            </TouchableOpacity>
          </View>          
        )}
        <View style={{padding:5,}}>
          <Text style={[GSS.mainInfoTextB]}>마이샷 {appVersion} </Text>
        </View>
        { checkCertifyID === false ? (
          <View style={{padding:5,}}>
            <TouchableOpacity 
              onPress={CertifyID}
              style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Text style={[GSS.mainInfoTextB]}>본인 인증</Text>
              <Image
                source={nextImage}
                style={{width:25, height:25}}
              />            
            </TouchableOpacity>
          </View> 
        ) : (
          <View style={{padding:5,}}>
            <TouchableOpacity 
              onPress={shareMessage}
              style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Text style={[GSS.mainInfoTextB]}>마이샷 공유하기(초대코드 보내기)</Text>
              <Image
                source={nextImage}
                style={{width:25, height:25}}
              />            
            </TouchableOpacity>
          </View>         
        )}
        <View style={{padding:5,}}>
          <TouchableOpacity 
            onPress={AdminAccount}
            style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Text style={[GSS.mainInfoTextB]}>마이샷 계정 관리</Text>
            <Image
              source={nextImage}
              style={{width:25, height:25}}
            />            
          </TouchableOpacity>
        </View>                          
      </View>
      <View style={[GSS.mainViewBox, {marginTop:25}]}>
        <View style={{ padding:5, alignItems:'center'}}>
          <TouchableOpacity onPress={LogoutStep} style={{flexDirection:'row', justifyContent: 'center',}}>
            <Text style={[GSS.textEmphasisAlert, {marginRight:5}]}>마이샷 로그아웃</Text><Text>({LastLoginType()} {ENV.user_id < 10 && ENV.user_id})</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default MyInfoHomeOfLogin;