import React, { useEffect, useRef, useState } from 'react';
import { View, StyleSheet, Animated, Easing } from 'react-native';

const ScrollingTextView = ({ texts, textStyle, containerStyle, speed = 50, delay = 1000 }) => {
  const translateX = useRef(new Animated.Value(0)).current;
  const [contentWidth, setContentWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [layoutReady, setLayoutReady] = useState(false);

  const handleAnimation = () => {
    // 애니메이션 시작 위치를 재설정합니다.
    translateX.setValue(containerWidth);

    // 스크롤 속도를 계산합니다. 내용의 길이에 따라 시간을 조정합니다.
    const duration = (contentWidth + containerWidth) / speed * 1000;

    Animated.timing(translateX, {
      toValue: -contentWidth,
      duration: duration,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start(({ finished }) => {
      if (finished) {
        // 다음 텍스트로 이동하고, 레이아웃 준비 상태를 리셋합니다.
        setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setLayoutReady(false);
      }
    });
  };

  useEffect(() => {
    // 레이아웃이 준비되고 필요한 모든 치수가 설정되면 애니메이션을 시작합니다.
    if (layoutReady && contentWidth > 0 && containerWidth > 0) {
      handleAnimation();
    }
  }, [layoutReady, contentWidth, containerWidth, currentIndex]); // currentIndex 의존성도 추가합니다.

  // 현재 인덱스의 텍스트를 렌더링합니다.
  const textToDisplay = texts[currentIndex];

  return (
    <View
      style={[styles.container, containerStyle]}
      onLayout={(event) => {
        const { width } = event.nativeEvent.layout;
        setContainerWidth(width);
      }}
    >
      <Animated.Text
        style={[
          textStyle,
          {
            transform: [{ translateX: translateX }],
          },
        ]}
        onLayout={(event) => {
          const { width } = event.nativeEvent.layout;
          setContentWidth(width);
          setLayoutReady(true); // 레이아웃 준비가 완료되었음을 나타냅니다.
        }}
        numberOfLines={1}
      >
        {textToDisplay}
      </Animated.Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden', // 컨테이너 밖의 텍스트를 숨깁니다.
  },
});


export default ScrollingTextView;