import React, {useEffect, useState} from 'react';
import { Text, View, Image, TouchableOpacity } from 'react-native';
import { useAuth, OTTs } from '../AppContext';
import { useItemContext } from '../ItemContext';
import { Banks } from '../bankContext';
import GSS from '../GlobalStyleSheet2';
import TopHeader from '../TopHeader';
import { ProgressNext } from '../ProgressButton';
import nextImage from '../../assets/next.png';
import { GetAccountInfoFromDB } from '../api';
import { useEnv} from '../AppContext';
import { useFocusEffect } from '@react-navigation/native';

function ManagerShare12 ({navigation, route}) {
    const { isLoggedIn, login, logout } = useAuth();
    const {Item} = useItemContext();
    const [backAccount, setBankAccount] = useState();
    const bankUpdated = route.params?.bankUpdated;
    const { ENV } = useEnv();

    function NextStep()
    {
        if(Item.item_id === 1 ) {   // 넷플릭스일때
          navigation.navigate('ManagerShare3');
        } else {
          navigation.navigate('ManagerShare2');
        }
    }    

    useFocusEffect (
      React.useCallback(() => {
        // 포커스될 때 실행할 코드
        //console.log('스크린이 포커싱되었습니다.');
          //console.log(user_id, last_login_type, ENV.target_after_login);
          (async () => {
            try {
              let checking = false;
              const ret = await GetAccountInfoFromDB(ENV.user_id);
              if(ret.success) {
                  if( ret.data.bank_account) {
                      const bankInfos = ret.data.bank_account.split('|||');
                      let bankId = +bankInfos[0];
                      
                      const _bankInfo = {
                          name : Banks[bankId].name,
                          bankNumber : bankInfos[1],
                          logoImage : Banks[bankId].logoImage
                      };
                      setBankAccount(_bankInfo);
                      checking = true;
                      // console.log('id', bankId);
                      // console.log(Banks[bankId].id);
                  }
              }
              if(!checking) {
                setBankAccount(null);
                const targetAfterRegister = 'ManagerShare12';
                navigation.navigate('ChangeBankAccount', {targetAfterRegister});
              }                } catch (e) {
                console.error(e);
            }
        })();          
      }, [])
    );    

    const NextButton = () => {
      return (
          <ProgressNext
              title={'다음'}
              onPress={NextStep}
          />
      );
    }    

    const RegisterAccount= () => {
      const targetAfterRegister = 'ManagerShare12';
      navigation.navigate('ChangeBankAccount', {targetAfterRegister});
    }    

    const CalcPayDate = () => {
      // 현재 날짜를 가져옵니다.
      let currentDate = new Date();

      // 현재 연, 월, 일을 가져옵니다.
      let currentYear = currentDate.getFullYear();
      let currentMonth = currentDate.getMonth();
      let currentDay = currentDate.getDate();

      // 단순하게 한 달을 추가합니다.
      const nextMonthDate = new Date(currentYear, currentMonth + 1, currentDay);
      const month = nextMonthDate.getMonth() + 1;
      const day = nextMonthDate.getDate();
      //console.log("다음 달의 같은 일자:", nextMonthDate.toDateString());
      return `${month}월 ${day}일`;
  }      

    return (
      <View style={GSS.mainContainer}>
          <TopHeader 
              titleImage={OTTs[Item.ott_id].iconImage}
              navigation={navigation}
          >
              {Item.display_name} 정산계좌
          </TopHeader>
          { backAccount && (
            <View style={{flex:1}}>
              <View style={[GSS.mainViewBox, {flex:1}]}>
                <View style={{margin:10, marginBottom:20, marginTop:20}}>
                    <Text style={{fontWeight: 'bold', fontSize: 20,}}>다음 계좌로 매달 자동정산됩니다</Text>
                </View>
                <View>
                    <View style={[GSS.mainViewBox, 
                      { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',
                      borderWidth: 1,borderColor: '#D3D3D3'
                      },
                    ]}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Image
                                source={backAccount.logoImage} // 이미지 파일 경로
                                style={{ width: 50, height: 50, marginRight: 6 }} // 이미지 스타일
                            />
                            <View>
                                <Text style={GSS.mainDefaultText}>{backAccount.name}</Text>
                                <Text style={GSS.mainInfoTextB}>{backAccount.bankNumber}</Text>
                                {/* <Text style={GSS.mainDefaultTextB}>한지은</Text> */}
                            </View>
                        </View>
                        <View>
                          <TouchableOpacity onPress={RegisterAccount} >
                            <Text style={[GSS.mainDefaultTextB, {color: '#6F7983', marginRight: 5}]}>변경</Text>
                          </TouchableOpacity>                        
                        </View>
                    </View>
                </View>
                <View style={[GSS.mainViewBox]}>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        margin: 5
                    }}>
                        <Text style={GSS.mainInfoTextB}>첫번째 정산일자</Text>
                        <Text style={GSS.mainInfoTextB}>{CalcPayDate()}</Text>
                    </View>
                    <View style={{margin: 5}}>
                        <Text style={GSS.mainInfoTextB}>매월 {new Date().getDate()}일에 자동정산 됩니다</Text>
                    </View> 
                </View>                
              </View>
              <View style={[GSS.mainViewBox, {margin: 5, alignItems:'center', justifyContent: 'center'}]}>
              <NextButton/>
              </View>              
            </View>
          )}
      </View>    
    );
};

export default ManagerShare12;