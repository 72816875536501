import React, { useState, useEffect, useRef } from 'react';
import { Text, View, Image, TouchableOpacity, StyleSheet, FlatList, TextInput, ActivityIndicator } from 'react-native';
import GSS from '../GlobalStyleSheet2';
import { useEnv } from '../AppContext';
import TopHeader from '../TopHeader';
import nextImage from '../../assets/next.png';
import { Banks } from '../bankContext.js'
import { ProgressNext } from '../ProgressButton';
import { CheckAccountToDB, SaveBankAccountToDB } from '../api';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useModal } from '../ModalContext';

function ChangeBankAccount({ navigation, route }) {
    const { ENV, setEnv } = useEnv();
    const [bankAccount, setBankAccount] = useState(null);
    const numberOfColumns = 3;
    const textInputRef = useRef(null);
    const [accountNumber, setAccountNumber] = useState();
    const [checkCompleted, setCheckCompleted] = useState(false);
    const targetAfterRegister = route.params?.targetAfterRegister;
    const user_item = route.params?.user_item;
    const item = route.params?.item;
    const [focusedInput, setFocusedInput] = useState(null);
    const { showModalMessage } = useModal();
    const [isLoading, setIsLoading] = useState(false);

    const handleBlur = () => {
       setFocusedInput(null);
    } 
    
    // 포커스 함수
    const focusOnTextInput = () => {
        textInputRef.current && textInputRef.current.focus();
    };   

    // 데이터에 빈 아이템 추가
    const formatData = (data, numberOfColumns) => {
        const numberOfRows = Math.ceil(data.length / numberOfColumns);
        const totalItems = numberOfRows * numberOfColumns;
        const numberOfEmptyItems = totalItems - data.length;

        for (let i = 0; i < numberOfEmptyItems; i++) {
            data.push({ id: `empty-${i}`, empty: true });
        }

        return data;
    };

    useEffect(() => {
    }, []);

    const selectItem = (item) => {
        //console.log(item.id, item.name);
        setBankAccount(item);
        if( item !== null) focusOnTextInput();
        else {
            setAccountNumber('');
            setCheckCompleted(false);
        }
    };

    const renderItem = ({ item }) => {
        if (item.empty) {
            return <View style={[styles.mainItem, styles.itemInvisible]} />;
        }

        return (
            <TouchableOpacity onPress={() => selectItem(item)} style={styles.mainItem}>
                <View style={{ width: '100%', height: '100%' }}>
                    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <Image source={item.logoImage} style={styles.mainItemImage} />
                    </View>
                    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={GSS.mainInfoText}>{item.name}</Text>
                    </View>
                </View>
            </TouchableOpacity>
        );
    };

    const handleOnChangeText = (text) => {
        // 숫자만 허용하는 정규 표현식
        const numericRegex = /^[0-9]*$/;
      
        if (numericRegex.test(text)) {
            setAccountNumber(text);
            if(text.length > 9) setCheckCompleted(true);
            else setCheckCompleted(false);
        }
    };

    // async function _SaveAccount()
    // {
    //     setIsLoading(true);
    //     try {
    //         // console.log(accountNumber);
    //         // console.log(accountName);
    //         // console.log(accountInfo);
    //         // console.log(bankAccount.code);
            
    //         const bankInfo = {
    //             user_id : ENV.user_id,
    //             bank_id : bankAccount.id,
    //             bank_number : accountNumber.trim(),
    //             bank_code : bankAccount.code,
    //             account_info : accountInfo,
    //             account_name : accountName.trim()                
    //         }
    //         const ret = await CheckAccountToDB(bankInfo);
    //         if(ret.success) {
    //             const bankUpdated = `${bankAccount.id}|||${accountNumber}`;
    //             //console.log(targetAfterRegister);
    //             if( targetAfterRegister )
    //                 navigation.navigate(targetAfterRegister, {bankUpdated, user_item, item});
    //             else
    //                 navigation.navigate('MyAccount', {bankUpdated});
    //         } else {
    //             const _message = {
    //                 body : ret.error
    //             }
    //             showModalMessage(_message);
    //         }
    //     } catch (e) {
    //         console.log('ChangeBankAccount.SaveAccount', e);
    //     }
    //     setIsLoading(false);
    // }

    // const SaveAccount = () => {
    //     const _message = {
    //         body : (
    //             <View style={{flex:1, margin:20}}>
    //                 <View style={{flexDirection:'row', justifyContent:'space-between'}}>
    //                     <Text style={GSS.mainInfoTextB}>계좌번호:</Text>
    //                     <Text style={GSS.mainInfoTextB}>{accountNumber}</Text>
    //                 </View>
    //                 <View style={{flexDirection:'row', justifyContent:'space-between'}}>
    //                     <Text style={GSS.mainInfoTextB}>성명:</Text>
    //                     <Text style={GSS.mainInfoTextB}>{accountName}</Text>
    //                 </View> 
    //                 <View style={{flexDirection:'row', justifyContent:'space-between'}}>
    //                     <Text style={GSS.mainInfoTextB}>생년월일:</Text>
    //                     <Text style={GSS.mainInfoTextB}>{accountInfo}</Text>
    //                 </View>  
    //                 <View style={{marginTop:15, alignItems:'center', justifyContent:'center'}}>
    //                     <Text style={GSS.mainDefaultText}>위 내용으로 실명인증을 진행할까요?</Text>
    //                 </View>                                 
    //             </View>
    //         ),
    //         isCode : true,
    //         afterOK : _SaveAccount
    //     }
    //     confirmModal(_message);
    // }

    async function SaveAccount()
    {
        try {
            setIsLoading(true);
            const ret = await SaveBankAccountToDB(ENV.user_id, bankAccount.id, accountNumber, bankAccount.code);
            if(ret.success) {
                setIsLoading(false);
                const bankUpdated = `${bankAccount.id}|||${accountNumber}`;
                //console.log(targetAfterRegister);
                if( targetAfterRegister )
                    navigation.navigate(targetAfterRegister, {bankUpdated, user_item, item});
                else
                    navigation.navigate('MyAccount', {bankUpdated});
            } else {
                setIsLoading(false);
                const _message = {
                    body : ret.error.error
                }
                showModalMessage(_message);
            }
        } catch (e) {
            setIsLoading(false);
            console.log('ChangeBankAccount.SaveAccount', e);
        }
    }

    const NextButton = () => {
        return (
          <ProgressNext
            title={'계좌등록'}
            onPress={ checkCompleted ? SaveAccount : null}
            disabled={checkCompleted ? false : true}
          />
        );
    }      

    const getStyle = (inputName) => {
        return focusedInput === inputName ? styles.inputFocused : styles.input;
    }    

    const handleFocus = (inputName) => {
        setFocusedInput(inputName);
    }   
   
    return (
            <View style={GSS.mainContainer}>
                <View style={{ height: 50 }}>
                    <TopHeader navigation={navigation}>정산 계좌 등록</TopHeader>
                </View>
                <View style={[GSS.mainViewBox, { flex: 1 }]}>
                    { bankAccount ? (
                        <KeyboardAwareScrollView
                        style={{ flex: 1 }}
                        contentContainerStyle={{ flexGrow: 1 }}  // 컨텐츠 스타일 조정
                        scrollEnabled={false}  // 필요에 따라 스크롤 비활성화
                        >   
                        <View style={{flex:1}}>
                            <View>
                                <Text style={[GSS.mainInfoTextB, {margin:10}]}>본인 명의의 계좌번호를 입력하세요</Text>
                            </View>
                            <View style={{flex:1, }}>
                                <View style={{flexDirection:'row', justifyContent:'space-between', backgroundColor:'#f8f8f8'}}>
                                    <View style={{flexDirection:'row', height:50,  alignItems:'center'}}>
                                        <Image
                                            source={bankAccount.logoImage}
                                            style={styles.mainItemImage}
                                            resizeMode='contain'
                                        />
                                        <Text style={[GSS.mainInfoTextB, {}]}>{bankAccount.name}</Text>
                                    </View> 
                                    <View style={{flexDirection:'row', height:50,  alignItems:'center', width:75, }}>
                                        <TouchableOpacity onPress={() => selectItem(null)} >
                                            <Text sty={GSS.mainSmallText}>은행변경</Text>
                                        </TouchableOpacity>
                                    </View>                              
                                </View>
                                <View style={[GSS.textInputContainer,
                                    getStyle('account'), 
                                    {borderWidth:1, height:50, marginTop:10}]}
                                >
                                    <TextInput
                                        //ref={textInputRef} 
                                        style={[GSS.textInputText, ]}
                                        onFocus={() => handleFocus('account')}
                                        onBlur={handleBlur}                                
                                        placeholder="계좌번호 입력"
                                        value={accountNumber}
                                        onChangeText={handleOnChangeText}
                                        keyboardType="numeric"
                                        // onSubmitEditing={() => nameInputRef.current.focus()}
                                    />
                                </View>
                                {isLoading && (
                                    <View style={GSS.activityIndicatorContainer}>
                                        <ActivityIndicator size='large' color="#0000ff" />
                                    </View>
                                )}                                                                                       
                                <View style={[GSS.mainViewBox, {margin: 10, alignItems:'center', justifyContent: 'center'}]}>
                                    <NextButton/>
                                </View>                             
                            </View>
                        </View>                        
                        </KeyboardAwareScrollView>                        

                    ) : (
                        <View style={{flex:1}}>
                            <View>
                                <Text style={[GSS.mainDefaultText, {margin:10}]}>정산 계좌의 은행을 선택하세요</Text>
                            </View>
                            <View style={{flex:1}}>
                                <FlatList
                                    data={formatData(Banks, numberOfColumns)}
                                    numColumns={numberOfColumns}
                                    keyExtractor={(item) => item.id ? item.id.toString() : 'undefined-item'}
                                    renderItem={renderItem}
                                />
                            </View>
                        </View>
                    )}
                </View>
            </View>            
    );
};

const styles = StyleSheet.create({
    mainItem: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',    
        borderRadius: 8,
        //aspectRatio: 1,    
        margin: 2,
        borderWidth: 1,
        borderColor: '#F1F1F1',
        height: 75,
    },
    mainItemImage: {
        width: 45, // 예시 크기, 필요에 따라 조정
        height: 45, // 예시 크기, 필요에 따라 조정
    },
    itemInvisible: {
        backgroundColor: 'transparent',
        borderColor: 'transparent', // 빈 아이템 테두리 없애기
    },  
    input: {
        // 기본 TextInput 스타일
        borderWidth: 0,
        //borderColor: 'gray', // 기본 경계선 색상
        // 다른 스타일...
    },
    inputFocused: {
        // 포커스 됐을 때의 TextInput 스타일
        borderWidth: 1,
        borderColor: 'gray', // 포커스 됐을 때의 경계선 색상
        // 다른 스타일...
    },
});


export default ChangeBankAccount;