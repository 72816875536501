import React, {useState, useEffect} from 'react';
import { Platform, AppState, BackHandler } from 'react-native';
import { LoadFromSystem } from './Global';
import { useLoginProc } from './LoginProc2';
import { useAuth, useEnv } from './AppContext';
import { useUserItem } from './UserItemContext';
import * as Notifications from 'expo-notifications';
import { useModal } from './ModalContext';
import NetInfo from '@react-native-community/netinfo';

const GlobalAppStatus = () => {
  const [appState, setAppState] = useState(AppState.currentState);
  const { loginProc } = useLoginProc();
  const { ENV, setIsNetworkConnected } = useEnv();
  const { ReadUserItem } = useUserItem();
  const { showModalMessage } = useModal();

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener(state => {
      setIsNetworkConnected(state.isConnected);
      // console.log("Connection type", state.type);
      // console.log("Is connected?", state.isConnected);
    });
    return () => unsubscribe();
  }, []);  

  // useEffect(() => {
  //   console.log('GlobalAppStatus called.');
  // }, []);  

  if( Platform.OS === 'web') {
    //console.log( LoadFromSystem() );
    useEffect(() => {
      // async 함수를 정의하고 즉시 호출합니다.
      (async () => {
        try {
          //const sysData = await LoadFromSystem();
          // 컴포넌트가 여전히 마운트 상태인 경우에만 상태를 설정하거나 로깅을 수행합니다.
          //console.log(ENV);
          if(!ENV || ENV.user_id===null) {
            const sysData = await LoadFromSystem();
            const newENV = {};
            if(sysData && sysData.user_id ) {
                newENV.user_id = sysData.user_id;
                newENV.last_login_type =sysData.last_login_type;
                // newENV.kakao_id = sysData.kakao_id;
                newENV.message_ymd = sysData.message_ymd;
                newENV.t_item = sysData.t_item;
                newENV.item_check_time = sysData.item_check_time;
                await loginProc(newENV);
            }            
          }
        } catch (e) {
          // 오류를 적절하게 핸들링합니다. (예: 로깅, 사용자에게 메시지 표시 등)
          console.error(e);
        }
      })();
    }, [ENV.user_id]);  
  } else {
    if(Platform.OS !== 'web') {
      const [notification, setNotification] = useState(null);
      // const [error, setError] = useState(null);
      useEffect(() => {
        const subscription = Notifications.addNotificationResponseReceivedListener(response => {
          try {
            // 알림 데이터 설정
            setNotification(response.notification);
          } catch (err) {
            // 에러 핸들링
            //setError(err);
            console.error("알림 처리 중 에러 발생:", err);
          }
        });
      
        return () => subscription.remove();
      }, []);
    
      useEffect(() => {
        // 포그라운드에서 알림을 받을 때 호출될 리스너
        const subscription = Notifications.addNotificationReceivedListener(notification => {
          //console.log("포그라운드 알림 수신:", notification);
          // 여기서 사용자 정의 UI를 표시하거나 다른 처리를 할 수 있습니다.
          setNotification(notification);
        });
    
        return () => subscription.remove();
      }, []);  
    
      useEffect(() => {
        if (notification) {
          try {
            const title = notification.request.content.title;
            const body = notification.request.content.body;
            //const extraData = response.notification.request.content.data.extraData;
            //console.log(title, body);      
    
            const _message = {
              title : title,
              body : body,
              afterOK : async () => { 
                await ReadUserItem(ENV.user_id);
                //navigation2.navigate('home');
              }
            };
            showModalMessage(_message);
    
            //Alert.alert(title, body);
      
            // // 상태 초기화
            // setNotification(null);
          } catch (err) {
            //setError(err);
            console.error("Alert 표시 중 에러 발생:", err);
          }
        }
      }, [notification]);
  
      // // 에러 상태에 대한 처리 (옵션)
      // useEffect(() => {
      //   if (error) {
      //     // 에러 처리 로직 (예: 사용자에게 에러 메시지 표시)
      //     //Alert.alert('에러 발생', error.message);
      //     console.log('에러 발생', error.message);
      //     setError(null);
      //   }
      // }, [error]);
    }
  }

  useEffect(() => {
    if(Platform.OS === 'web') {
      document.addEventListener("visibilitychange", function() {
        if (document.visibilityState === 'visible') {
          //console.log("웹 페이지가 활성화되었습니다.");
        } else {
          //console.log("웹 페이지가 비활성화되었습니다.");
        }
      });
    } else {
      const handleAppStateChange = nextAppState => {
        if (appState.match(/inactive|background/) && nextAppState === 'active') {
            // 앱이 포그라운드로 돌아올 때 타이머 초기화
        } else if (nextAppState === 'background') {
            // 앱이 백그라운드로 전환될 때 타이머 설정
            //console.log('백그라운드로 전환');
        }
        setAppState(nextAppState);
      };

      // AppState 변경을 위한 리스너 추가 및 구독 객체 반환
      const subscription = AppState.addEventListener('change', handleAppStateChange);

      // Cleanup 함수에서 구독을 해제
      return () => {
          subscription.remove();
          clearTimeout(this.logoutTimer);
      };
    }
  }, [appState]);
  return null;
}

export default GlobalAppStatus;