import React, {useState, useEffect} from 'react';
import { Image, Text, View, StyleSheet, TouchableOpacity} from 'react-native';
import ConfirmImage from '../ConfirmImage';
import GSS from '../GlobalStyleSheet2';
import { ProgressButtonNoWidth } from '../ProgressButton';
import CloseHeader from '../CloseHeader'
import { useSystemInfo } from '../AppContext';
import { DeleteUsedItemToDB } from '../api';
import { useUserItem } from '../UserItemContext';
import { useModal } from '../ModalContext';
import { GetPayInfoOfDB } from '../api';

function SettingUsedItem ({navigation, route}) {
    const { used_item, item } = route.params;
    const { systemInfo } = useSystemInfo();
    const { ReadUserItem } = useUserItem();
    const { showModalMessage, showMessage } = useModal();
    const [cardName, setCardName] = useState();
    const [cardNum, setCardNum] =useState();
    const payUpdated = route.params?.payUpdated;

    useEffect(() => {
        let isMounted = true; // 컴포넌트가 마운트 상태인지 추적합니다.
        (async () => {
          try {
            const ret = await GetPayInfoOfDB(used_item.user_id);
            if(ret.success) {
                if( ret.data.pay_cardname) {
                    setCardName(ret.data.pay_cardname);
                    setCardNum(ret.data.pay_cardnum);
                }
            }
          } catch (e) {
            console.error(e);
          }
        })();
        // 컴포넌트 언마운트 시 isMounted를 false로 설정합니다.
        return () => {
          isMounted = false;
        };
    }, [payUpdated]);   
    

    const CloseButton = () => {
        navigation.goBack();
    };    

    function addMonth(date) {
        var newDate = new Date(date);
        newDate.setDate(1);
        newDate.setMonth(newDate.getMonth() + 1);
        return newDate;
     }

    const DeleteUsedItem = async () => {
        const stateDate = new Date(used_item.start_use);
        const date = addMonth(stateDate);
        const now = new Date();

        //console.log(date);
        const d1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const d2 = new Date(now.getFullYear(), now.getMonth(), now.getDate());        

        if( d1 <= d2 || used_item.user_id < 5 || used_item.free_charge===1) {
            await DeleteUsedItemAdmin();
        } else {
            const msg = `${goOutDate()} 이후에 탈퇴가능합니다.`;
            const _message = {
                title : `[${item.display_name}] 파티`,
                body : msg,
            };
            showModalMessage(_message);            
            //Alert.alert('파티탈퇴 불가능', msg);
        }

        //navigation.navigate('MatchedProc2', { matchedItem, item });
    };    

    const DeleteUsedItemAdmin = async () => {
        const result = await DeleteUsedItemToDB(used_item.used_item_id);
        if( result.success ) {
            await ReadUserItem(used_item.user_id);
            //Alert.alert('파티탈퇴 성공', result.data);
            const _message = {
                title : `[${item.display_name}] 파티탈퇴 성공`,
                body : result.data,
            };
            showModalMessage(_message); 
            navigation.reset({
                index: 0,
                routes: [{ name: 'Home' }],
            });              
        } else {
            //Alert.alert('파티탈퇴 실패', result.error);
            showMessage(result.error._message);
        }
        //navigation.navigate('MatchedProc2', { matchedItem, item });
    };       

    // const ChangeCardInfo = () => {
    //     const targetAfterRegister = 'SettingUsedItem';
    //     navigation.navigate('RegisterCardOfPayple', {targetAfterRegister, used_item, item});
    // };    

    const ChangeCardInfo = () => {
        navigation.navigate('MyInfo', { 
            screen: 'AdminPay',
        });       
    };      

    const formatDate = () => {
        const date = new Date(used_item.start_use);
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // getMonth()는 0부터 시작
        const day = date.getDate();
        return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;        
    };        

    const payDate = () => {
        const date = new Date(used_item.start_use);
        return date.getDate();
    };        

    const payCost = () => {
        //console.log(item);
        if( used_item.using_type === 1) {
            return (item.monthly_price2 + systemInfo.trade_charge).toLocaleString();
        } else {
            return (item.monthly_price + systemInfo.trade_charge).toLocaleString();
        }
        //const item.monthly_price + systemInfo.trade_charge;
    };

    const goOutDate = () => {
        const date = new Date(used_item.start_use);
        let year = date.getFullYear();
        let month = date.getMonth() + 2; // 월을 증가시킴
    
        // 월이 13월이 되는 경우 처리
        if (month > 12) {
            month -= 12; // 월을 1월로 설정
            year += 1; // 연도 증가
        }
    
        const day = date.getDate();
        return `${year}년 ${month.toString().padStart(2, '0')}월 ${day.toString().padStart(2, '0')}일`;        
    };

    return (
        <View style={GSS.mainContainer}>
            <CloseHeader
                closeAction={CloseButton}
            />
            <View style={[GSS.mainViewBoxNoBorder, {flex:1}]}>
                <Text style={[GSS.mainDefaultTextB, {fontWeight: 'bold', fontSize: 20,}]}>
                    {item.display_name} 파티설정
                </Text>
            </View>
            <View style={[GSS.mainViewBox , {flex:5}]}>
                <View style={SS.confirmView}>
                    <Text style={GSS.mainDefaultText}>파티가입 날짜</Text>
                    <Text style={GSS.mainDefaultTextB}>{formatDate()}</Text>
                </View>
                { used_item.free_charge === 0  && (
                    <React.Fragment>
                    <View style={SS.confirmView}>
                        <Text  style={GSS.mainDefaultText}>결제 일자</Text>
                        <Text style={GSS.mainDefaultTextB}>매달 {payDate()}일</Text>
                    </View>                
                    <View style={SS.confirmView}>
                        <Text style={GSS.mainDefaultText}>결제 금액</Text>
                        <Text style={GSS.mainDefaultTextB}>{payCost()}</Text>
                    </View>
                    <View style={SS.confirmView}>
                        <Text style={GSS.mainDefaultText}>결제 카드</Text>
                        <Text style={GSS.mainDefaultText}>{cardName ? cardName + ' ' : ''}{cardNum ? cardNum : ''}</Text>
                    </View>  
                    </React.Fragment>
                )}
            </View>
            { used_item.free_charge === 0  && (
                <View style={[{flex:1, alignItems:'center', justifyContent: 'center', backgroundColor:'#291B44', borderRadius:5 }]}>
                    <TouchableOpacity onPress={ChangeCardInfo}>
                        <Text style={{color:'#F7F7F7', fontSize:16}}>결제카드 변경하기</Text>
                    </TouchableOpacity>
                </View> 
            )}
            <View style={[{flex:1, alignItems:'center', justifyContent: 'center', backgroundColor:'#291B44', borderRadius:5, marginTop:10, marginBottom:10 }]}>
                <TouchableOpacity onPress={DeleteUsedItem}>
                    <Text style={{color:'#F7F7F7', fontSize:16}}>파티탈퇴 하기</Text>
                </TouchableOpacity>
            </View>  
            
            { used_item.free_charge === 0  && (
                <View style={GSS.mainViewBox}>
                    <Text>
                        잦은 파티탈퇴 발생시 파티장님에게 부담이 되기 때문에 파티탈퇴는 최초 가입 한달뒤인 <Text style={GSS.textEmphasis}>{goOutDate()}</Text>부터 가능합니다.
                    </Text>
                </View>               
            )}

            {/* <View style={[{flex:1, alignItems:'center', justifyContent: 'center', backgroundColor:'#291B44', borderRadius:5, marginTop:10, marginBottom:10 }]}>
                <TouchableOpacity onPress={DeleteUsedItemAdmin}>
                    <Text style={{color:'#F7F7F7', fontSize:16}}>파티탈퇴(관리자)</Text>
                </TouchableOpacity>
            </View>                  */}
        </View>      
    );    
};

const SS = StyleSheet.create({
    container: {
      // 컨테이너를 flex 컨테이너로 설정합니다.
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-end', // 자식 요소들을 컨테이너의 오른쪽으로 정렬합니다.
      alignItems: 'center', // 자식 요소들을 컨테이너의 중앙으로 정렬합니다(수직 방향).
    },

    confirmView: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems : 'center'
    }    
});

export default SettingUsedItem;